var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"motos"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 resumos"},[_c('div',{staticClass:"boxResumo"},[_vm._m(0),_c('h1',[_vm._v(_vm._s(_vm.motosInativas.length))])]),_c('div',{staticClass:"boxResumo"},[_vm._m(1),_c('h1',[_vm._v(_vm._s(_vm.motosTrabalhando.length))])]),_c('div',{staticClass:"boxResumo"},[_vm._m(2),_c('h1',[_vm._v(_vm._s(_vm.motosEmRota.length))])]),_c('div',{staticClass:"boxResumo"},[_vm._m(3),_c('h1',[_vm._v(_vm._s(_vm.motosConcluidas.length))])]),_c('div',{staticClass:"boxResumo"},[_vm._m(4),_c('h1',[_vm._v(_vm._s(_vm.motosTrabalhou.length))])])]),_c('div',{staticClass:"col-12 listaMotos"},_vm._l((_vm.controles),function(moto){return _c('div',{key:moto.cod_moto,staticClass:"moto",class:{
          inativo: !moto.cod_entrada,
          ativo: moto.cod_entrada,
          emRota: moto.cod_entrada && moto.emRota && moto.emRota > 0,
          aguardando: (moto.cod_entrada && !moto.emRota) || moto.emRota <= 0
        },on:{"click":function($event){return _vm.openMotoControle(moto)}}},[_c('img',{attrs:{"src":require("@/assets/img/icones/menu/icons8_motorbike_helmet_40px_1.png")}}),_c('div',[_vm._v(_vm._s(moto.info && moto.info.nome ? moto.info.nome: moto.nome))]),_c('div',[_c('small',[_vm._v(_vm._s(moto.info && moto.info.nome ? moto.nome : ""))])]),_c('div',[_c('small',[_vm._v(_vm._s(!moto.cod_entrada ? "Inativo" : "Ativo"))])]),_c('div',{staticClass:"entrega"},[_c('div',[_c('i',{staticClass:"fa fa-route"}),_vm._v(" "+_vm._s(moto.emRota))]),_c('div',[_c('i',{staticClass:"fa fa-check"}),_vm._v(" "+_vm._s(moto.concluidas))])])])}),0)]),_c('b-modal',{attrs:{"id":"modal-moto","title":`Controle ${_vm.motoSelecionada ? _vm.motoSelecionada.nome : ''}`,"hide-footer":"","size":"lg"}},[(_vm.motoSelecionada)?_c('entrega-moto-controle',{attrs:{"moto":_vm.motoSelecionada,"data":_vm.data,"empresa":_vm.empresa},on:{"closeModalOpenMotos":_vm.closeModalOpenMotos}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('i',{staticClass:"fa fa-ban"}),_vm._v(" Inativos")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('i',{staticClass:"fa fa-briefcase"}),_vm._v(" Trabalhando")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('i',{staticClass:"fa fa-road"}),_vm._v(" Em Rota")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('i',{staticClass:"fa fa-hand-holding"}),_vm._v(" Aguardando")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('i',{staticClass:"fa fa-motorcycle"}),_vm._v(" Trabalhou")])
}]

export { render, staticRenderFns }