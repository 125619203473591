<template>
  <div>
    <b-spinner v-if="$store.state.loadingCardapio" />
    <div
      style="position: relative; display: flex; width: 100%; float: left"
      v-if="!mesa || mesa.id <= 0"
    >
      <div
        class="btVolta"
        @click="back()"
      >
        <i class="fa fa-caret-left"></i>
      </div>

      <div class="btCenter">
        <div
          class="grupos"
          ref="center"
          @mousewheel="rolar"
        >
          <div class="cardapio_grupos">
            <div
              v-for="grupo in cardapio"
              :key="grupo.cod_grupo"
              @click="open(grupo)"
            >
              <div class="grupo d-flex flex-column">
                <div v-if="grupo.imagens && grupo.imagens.length > 0">
                  <img
                    :src="grupo.imagens[0].fileURL"
                    width="40"
                  />
                </div>
                <div v-if="!grupo.imagens || grupo.imagens.length <= 0">
                  <img
                    src="@/assets/img/icons8_soda_can_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('bebi') >= 0"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_pizza_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('pizza') >= 0"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_nachos_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('porç') >= 0"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_sashimi_96px.png"
                    width="40"
                    v-if="
                      grupo.Grupo.toLowerCase().indexOf('sushi - combo') >= 0
                    "
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_kawaii_sushi_96px.png"
                    width="40"
                    v-if="
                      grupo.Grupo.toLowerCase().indexOf('sushi - monte') >= 0
                    "
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_melting_ice_cream_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('divers') >= 0"
                    class="mb-2"
                  />

                  <img
                    src="@/assets/img/icons8_school_cafeteria_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('func') >= 0"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_melting_ice_cream_96px.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('sorv') >= 0"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_strawberry_cheesecake_96px_1.png"
                    width="40"
                    v-if="grupo.Grupo.toLowerCase().indexOf('sobre') >= 0"
                    class="mb-2"
                  />

                  <img
                    src="@/assets/img/ingredients_48px.png"
                    v-if="grupo.Grupo === 'Combos'"
                    width="40"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_process_48px_12.png"
                    v-if="grupo.Grupo.trim().toLowerCase().indexOf('rod') >= 0"
                    width="40"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_cookies_96px.png"
                    v-if="
                      grupo.Grupo.trim().toLowerCase().indexOf('esfih') >= 0
                    "
                    width="40"
                    class="mb-2"
                  />
                  <img
                    src="@/assets/img/icons8_hamburger_96px.png"
                    v-if="grupo.Grupo.trim().toLowerCase().indexOf('burg') >= 0"
                    width="40"
                    class="mb-2"
                  />
                </div>

                {{ grupo.Grupo }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="btProx"
        @mousedown="next()"
      >
        <i class="fa fa-caret-right"></i>
      </div>
    </div>
    <div v-if="mesa && mesa.id > 0">
      <div
        v-for="grupo in cardapio"
        :key="grupo.cod_grupo"
        @click="open(grupo)"
        style="width: 50%; float: left"
        class="p-1"
      >
        <div
          class="grupo btn btn-outline btn-block block"
          style="text-transform: capitalize; min-height: 80px; font-size: 10px"
        >
          <div v-if="grupo.imagens && grupo.imagens.length > 0">
            <img
              :src="grupo.imagens[0].fileURL"
              width="40"
            />
          </div>
          <div v-if="!grupo.imagens || grupo.imagens.length <= 0">
            <img
              src="@/assets/img/icons8_soda_can_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('bebi') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_pizza_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('pizza') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_nachos_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('porç') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_sashimi_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('sushi - combo') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_kawaii_sushi_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('sushi - monte') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/sweets_48px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('divers') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_melting_ice_cream_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('sorv') >= 0"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_strawberry_cheesecake_96px_1.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('sobreme') >= 0"
              class="mb-2"
            />

            <img
              src="@/assets/img/icons8_school_cafeteria_96px.png"
              width="40"
              v-if="grupo.Grupo.toLowerCase().indexOf('func') >= 0"
              class="mb-2"
            />

            <img
              src="@/assets/img/ingredients_48px.png"
              v-if="grupo.Grupo === 'Combos'"
              width="40"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_process_48px_12.png"
              v-if="grupo.Grupo.trim().toLowerCase().indexOf('rod') >= 0"
              width="40"
              class="mb-2"
            />

            <img
              src="@/assets/img/icons8_cookies_96px.png"
              v-if="grupo.Grupo.trim().toLowerCase().indexOf('esfih') >= 0"
              width="40"
              class="mb-2"
            />
            <img
              src="@/assets/img/icons8_hamburger_96px.png"
              v-if="grupo.Grupo.trim().toLowerCase().indexOf('burg') >= 0"
              width="40"
              class="mb-2"
            />
          </div>
          {{ grupo.Grupo }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: Function,
    mesa: Object,
    empresaSelecionada: Number,
  },
  data() {
    return {
      cardapio: this.$store.state.cardapio,
    };
  },
  watch: {
    "$store.state.cardapio": {
      deep: true,
      handler() {
        this.loadCards();
      },
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.loadCards();
      },
    },
    // cardapio: {
    //   deep: true,
    //   handler() {
    //     this.loadCards();
    //   },
    // },
  },
  methods: {
    loadCards() {
      this.cardapio = this.$store.state.cardapio;
      // console.log("carregando cardapio");
      for (let it in this.cardapio) {
        if (it.imagens && typeof it.imagens == "string") {
          it.imagens = JSON.parse(it.imagens);
        }
      }
      let fndEmp = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ].find((x) => x.cod_empresa === this.empresaSelecionada);
      if (fndEmp && fndEmp.cardapio) {
        this.cardapio = this.cardapio.filter(
          (x) =>
            x.Grupo == "Combos" ||
            fndEmp.cardapio.some((p) => p.Grupo === x.Grupo && p.ativo === true)
        );
        //console.log("2222222222222222carregando cardapio", this.cardapio);
      }
    },
    rolar(evt) {
      if (evt.wheelDelta < 0) {
        this.next();
      } else {
        this.back();
      }
      evt.preventDefault();
    },
    back() {
      this.$refs.center.scrollLeft -= 100;
    },
    next() {
      this.$refs.center.scrollLeft += 100;
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@/assets/scss/cardapio.scss"
</style>
