import { render, staticRenderFns } from "./MesasSalaoConfig.vue?vue&type=template&id=6cfea4be&scoped=true"
import script from "./MesasSalaoConfig.vue?vue&type=script&lang=js"
export * from "./MesasSalaoConfig.vue?vue&type=script&lang=js"
import style0 from "./MesasSalaoConfig.vue?vue&type=style&index=0&id=6cfea4be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfea4be",
  null
  
)

export default component.exports