<template>
  <div
    :class="{
      'text-left   py-3 pt-1 px-4 ': !$props.noStyle,
    }"
  >
    <label
      class=""
      v-if="!$props.noStyle"
    >
      <i class="fa fa-store mr-3"></i>Estabelecimento
    </label>
    <b-dropdown
      :disabled="disabled"
      id="dropdown-text"
      :text="`${
        this.empresa_selecionada
          ? `${this.empresa_selecionada.nome}`
          : `Escolha o local`
      }`"
      class="m2 combo-empresa"
      variant="outline-primary"
    >
      <b-dropdown-group class="combo-empresa-item">
        <b-dropdown-header>
          <i class="fa fa-store"></i> Selecione o Estabelecimento
        </b-dropdown-header>
        <b-dropdown-item
          v-for="emp in empresas"
          :key="emp.cod_empresa"
          @click="selecionarEmpresa(emp)"
          class="combo-empresa-item"
        >
          {{ emp.nome }}
          <span class="desc">
            {{ emp.telefenes ? emp.telefones[0].telefone : "" }}
          </span>
        </b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    selecionar: Function,
    noStyle: {
      type: Boolean,
      default: true,
    },
    comTodos: {
      type: Boolean,
      default: false,
    },
    selected: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      empresas: [],
      empresa_selecionada: null,
      user: this.$store.state.auth.user,
    };
  },
  mounted() {
    // console.log("EMPRESA");
    this.empresas = [];
    this.empresas = [
      this.$store.state.empresas,
      ...this.$store.state.empresas.empresas,
    ];

    if (this.$props.comTodos) {
      this.empresas.push({ cod_empresa: -1, nome: "TODOS" });
    }
    this.empresas = this.empresas.sort((a, b) => {
      return a.cod_empresa - b.cod_empresa;
    });
    console.log("empresas", this.empresas);
    let buscaEmpresa = this.empresas.filter(
      (x) =>
        x.cod_empresa.toString() ===
        (this.$props.selected
          ? this.$props.selected.toString()
          : this.user.cod_empresa.toString())
    )[0];
    // console.log(
    //   buscaEmpresa.cod_empresa,
    //   "emp start",
    //   buscaEmpresa,
    //   this.$props.selected,
    //   this.user.cod_empresa
    // );

    if (
      this.user &&
      this.user.configuracoes &&
      this.user.configuracoes.empresas &&
      this.user.configuracoes.empresas.length > 0 &&
      this.user.tipo == "Gerente"
    ) {
      this.empresas = this.empresas.filter((x) =>
        this.user.configuracoes.empresas.includes(x.cod_empresa)
      );
    } else if (this.user && this.user.tipo == "Gerente") {
      this.empresas = this.empresas.filter(
        (x) => x.cod_empresa == this.user.cod_empresa
      );
    }
    if (buscaEmpresa) {
      this.selecionarEmpresa(buscaEmpresa);
    }
  },
  methods: {
    selecionarEmpresa(emp) {
      if (this.$props.selecionar) {
        this.$props.selecionar(emp);
      }
      this.empresa_selecionada = emp;
    },
  },
  watch: {
    "$props.selected": function () {
      let buscaEmpresa = this.empresas.find(
        (x) => x.cod_empresa == this.$props.selected
      );
      // console.log("changed selected,", this.$props.selected, buscaEmpresa);

      if (buscaEmpresa) {
        this.selecionarEmpresa(buscaEmpresa);
      }
      // console.log("changed selected,", this.$props.selected, buscaEmpresa);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/empresa.scss"
</style>
