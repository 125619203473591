<template>
  <div>
    <b-modal
      id="modal-add-item"
      dialog-class="modalAddItem"
      body-class="bodyModalItem"
      hide-header
      hide-footer
    >
      <div class="closeDIV">
        <b-icon-x />
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 pl-0 pr-0">
            <div class="row">
              <div class="col-12 col-md-2 col-xl-1 pl-0 pr-0">
                <div class="categorias">
                  <div class="opc selected">
                    <img
                      src="@/assets/img/icons8_cocktail_96px.png"
                      width="40"
                    />
                    Drinks
                  </div>
                  <div class="opc">
                    <img
                      src="@/assets/img/icons8_soda_can_96px.png"
                      width="40"
                    />
                    Bebidas
                  </div>
                  <div class="opc">
                    <img
                      src="@/assets/img/icons8_hamburger_96px.png"
                      width="40"
                    />

                    Lanches
                  </div>
                  <div class="opc">
                    <img src="@/assets/img/icons8_pizza_96px.png" width="40" />

                    Pizzas
                  </div>
                  <div class="opc">
                    <img
                      src="@/assets/img/icons8_sashimi_96px.png"
                      width="40"
                    />

                    Sushi
                  </div>
                  <div class="opc">
                    <img src="@/assets/img/icons8_nachos_96px.png" width="40" />

                    Porções
                  </div>
                  <div class="opc">
                    <img
                      src="@/assets/img/icons8_noodles_96px.png"
                      width="40"
                    />
                    Massas
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-10 col-xl-11">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <b-input placeholder="Pesquisar..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 bg-light d-flex justify-content-between mt-2 pt-3"
            style="border-top:1px solid #ccc"
          >
            <button type="button" class="btn btn-secondary btn-def">
              <i class="fa-solid fa-angle-left mr-2" style="font-size:20px" />
              Voltar
            </button>
            <button type="button" class="btn btn-success btn-def">
              <b-icon-check /> Adicionar Item
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {
    // this.$bvModal.show("modal-add-item");
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" >
.bodyModalItem {
  @media screen and (max-width: 575px) {
    padding: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
.closeDIV {
  font-size: 40px !important;
  color: gray;
  position: absolute;
  right: 10px;
  top: 0px;
  @media screen and (max-width: 575px) {
    display: none;
  }
}
.categorias {
  margin: 0 auto;
  .opc {
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    height: 86px;
    display: flex;
    flex-flow: row ;
    overflow: auto;
    margin-bottom: 15px;
    align-items: center;

    &::-webkit-scrollbar {
      height: 1px;
      
      
    }
    &::-webkit-scrollbar-thumb{
      background: #999 !important;
    }

    .opc {
      min-width: 80px !important;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      border-radius: 15px;
      height: 75px;
      font-size:12px;
      img{
          width:35px;
          margin-bottom: 5px;
      }
    }
  }
  @media screen and (min-width: 575px) {
    min-height: 400px;
    // border-right: 1px solid #dedede;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    .opc {
      width: 80%;
      max-height: 80px;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 15px;
      cursor: pointer;

      &:hover {
        background: #cccccc50;
      }
    }
  }

  .selected {
    background: #cccccc50;
  }
}

.modalAddItem {
  width: 95% !important;
  max-width: 100% !important;
  border-radius: 15px !important;

  .modal-content {
    border-radius: 15px !important;
  }

  @media only screen and (max-width: 575px) {
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    .modal-content {
      border-radius: 0px !important;
    }
  }
}

.btn-def {
  height: 50px;
  min-width: 120px;
  text-align: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  svg {
    font-weight: bolder;
    font-size: 35px !important;
  }
}
</style>