<template>
  <div>
    <b-skeleton v-if="$store.state.loadingCardapio" />
     
    <CardapioGrupos
      :empresaSelecionada="empresaSelecionada"
      :open="open"
      :mesa="mesa"
      :empresa="empresa"
    />

    <b-modal
      id="modal-itens"
      hide-footer
      hide-header
      size="lg"
      content-class="prodModal"
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      :title="`Adicionar ${grupoSelecionado.Grupo}`"
      v-if="!Inside"
      @hidden="atuTot"
      @trigger="fecha"
      @keypress.native="clickGlobal"
    >
      <cardapio-produtos
        :empresaSelecionada="empresaSelecionada"
        :grupoSelecionado="grupoSelecionado"
        :preco_tipo_inicial="preco_tipo_inicial"
        :produtos="produtosShow"
        :adicionar="
          (item) => {
            adicionar(item);
          }
        "
        :opc_selecionada="pedido.itens"
        :selectTipoPreco="selectTipoPreco"
        @closeModal="$bvModal.hide('modal-itens')"
        @closeAndSave="salvar"
        :mesa="mesa"
        ref="cardProds"
      />
      <div class="Selecionados">
        <div v-if="pizza">
          <CardapioPizzaConfig
            :pedido="pedido"
            :atualizaAdicionais="atualizaAdicionais"
            :adicionais="adicionais"
            :key="edIt"
            :item="itemEDITI"
            ref="confPizza"
            :openChooseSabor="
              () => {
                $refs.cardProds.openChooseSabor(1);
              }
            "
            :mesa="mesa"
          />
        </div>

        <div class="row cardapioBts">
          <div class="col-12 col-sm-7">
            <label>Observações</label>
            <input
              type="text"
              class="form-control"
              v-model="pedido.obs"
            />
          </div>
          <div
            class="d-block d-sm-none mt-5 mb-5 pt-5 pb-5"
            v-if="mesa && mesa.id > 0"
          >
            <br />
            <br />
            <br />
          </div>
          <div
            class="col-12 col-sm-5 text-right"
            :class="{ rodapeFixoMobile: mesa && mesa.id > 0 }"
          >
            <label style="font-size: 20px">
              <div v-if="grupoSelecionado.Grupo == 'PIZZA'">
                R$ {{ valorTotal | currency }}
              </div>
              <div v-else>{{ " - " }}</div>
            </label>
            <button
              class="btn btn-primary mr-1"
              @click="salvar()"
            >
              <i class="fa fa-save"></i>
              {{ modoEdit ? "Atualizar" : "Adicionar" }}
            </button>
            <button
              class="btn btn-secondary"
              @click="$bvModal.hide('modal-itens')"
            >
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-combo"
      title="Combo de Produto"
      hide-footer
      size="lg"
    >
      <cardapio-combo
        :empresaSelecionada="empresaSelecionada"
        :grupoSelecionado="grupoSelecionado"
        :preco_tipo_inicial="preco_tipo_inicial"
        :produtos="produtosShow"
        :adicionar="
          (item) => {
            adicionar(item);
          }
        "
        :opc_selecionada="pedido.itens"
        :selectTipoPreco="selectTipoPreco"
        @closeModal="$bvModal.hide('modal-itens')"
        @closeAndSave="salvar"
        :mesa="mesa"
      />
    </b-modal>
  </div>
</template>

<script>
// import axios from "@/utils/axios";

// import { info } from "console";
import CardapioProdutos from "../../components/pedido/CardapioProdutos.vue";
import CardapioCombo from "./CardapioCombo.vue";
import CardapioGrupos from "./CardapioGrupos.vue";
import CardapioPizzaConfig from "./CardapioPizzaConfig.vue";
export default {
  name: "Cardapio",
  props: {
    Inside: Boolean,
    Itens: Array,
    mesa: Object,
    empresaSelecionada: Number,
  },
  components: {
    CardapioProdutos,
    CardapioGrupos,
    CardapioPizzaConfig,
    CardapioCombo,
  },
  data() {
    return {
      combo: false,
      edIt: 0,
      adicionais: [],
      produtos: [],
      produtosShow: [],
      cardapio: Object.assign([], ...this.$store.state.cardapio),
      cardapioShow: Object.assign([], ...this.$store.state.cardapio),
      openItem: true,
      grupoSelecionado: {},
      filtrar: "",
      produtos_selecionados: [],
      pizza: false,
      pedido: {
        itens: [],
        borda: null,
        adicionais: [],
        preco_tipo: null,
        obs: "",
      },
      preco_tipo_inicial: null,
      refBorda: null,
      modoEdit: false,
      itemEDITI: null,
      empresa: this.$store.state.empresas,
      empresas: [this.$store.state.empresas,...this.$store.state.empresas.empresas]
    };
  },
  beforeMount() {
    // this.$store.dispatch("auth/atualizarCardapio");
  },
  async mounted() {
    // console.log("init", this.$store.state.empresas, this.cardapio);
    // console.log("CARD EMP", this.$store.state.empresas);
    if (this.empresaSelecionada) {
      this.empresa = this.empresas.find(
        (x) => x.cod_empresa == this.empresaSelecionada
      );
    }
    this.atuTot();
  },
  computed: {
    valorTotal: function () {
      return this.calcularTotal();
    },
  },
  watch: {
    empresaSelecionada: {
      deep: true,
      handler() {
        if (this.empresaSelecionada) {
          this.empresa = this.empresas.find(
            (x) => x.cod_empresa == this.empresaSelecionada
          );
        }
        this.calcularTotal();
        this.carrCardapio();
      },
    },
    "$store.state.cardapio": {
      deep: true,
      handler() {
        console.log("changed", this.$store.state.cardapio);
        this.carrCardapio();
      },
    },

    "pedido.itens": {
      deep: true,
      handler() {
        const adds = this.adicionais.filter(
          (x) => x.sabor !== false && x.sabor >= 0
        );
        // console.log("aaa", this.pedido.itens.length);
        if (this.pedido.itens.length <= 0) {
          //this.pedido.adicionais = [];
        }
        if (adds && adds.length > 0) {
          for (let add of adds) {
            if (this.pedido.itens.length == 1) {
              add.sabor = 0;
            } else if (this.pedido.itens.length <= 0) {
              //add.sabor = false;
            }
          }

          this.$forceUpdate();
        }
      },
    },
    "pedido.adicionais": {
      deep: true,
      handler() {
        // this.adicionais = Object.assign(
        //   [],
        //   this.$store.state.ingredientes.filter((x) => x.grupo == "ADICIONAIS")
        // );

        // this.adicionais.map((it) => {
        //   const p = this.pedido.adicionais.find(
        //     (s) => s.cod_ingrediente == it.cod_ingrediente
        //   );
        //   if (p) {
        //     it.sabor = p.sabor;
        //   } else {
        //     it.sabor = false;
        //   }
        // });

        this.$forceUpdate();
      },
    },
  },
  methods: {
    carrCardapio() {
      this.cardapio = this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      });
      this.cardapioShow = this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      });
      // console.log("carregando cardapio");
      let fndEmp = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ].find((x) => x.cod_empresa === this.empresaSelecionada);
      if (fndEmp && fndEmp.cardapio) {
        this.cardapio = this.cardapio.filter((x) =>
          fndEmp.cardapio.some((p) => p.Grupo === x.Grupo && p.ativo === true)
        );
        // console.log("carregando cardapio", this.cardapio, fndEmp.cardapio);
      }

      this.$forceUpdate();
    },
    async atuTot() {
      this.cardapio = this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      });
      this.cardapioShow = this.$store.state.cardapio.map((it) => {
        return {
          ...it,
          produtos: it.produtos.filter((x) =>
            this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
          ),
        };
      });
      await this.fillCardapio();
      this.produtos = this.cardapio.reduce((ret, vl) => {
        vl.produtos.map((prd) => {
          ret.push({ grupo: vl.Grupo, ...prd });
        });
        return ret;
      }, []);
      this.produtosShow = this.produtos.filter((x) =>
        this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
      );
      this.$forceUpdate();

      if (this.PedidoEdit != null) {
        console.log("entrou", this.PedidoEdit);
        this.pedido = this.PedidoEdit;
      }

      this.adicionais = Object.assign(
        [],
        this.$store.state.ingredientes
          .filter((x) => x.grupo == "ADICIONAIS")
          .map((item) => {
            return item;
          })
      );
      // console.log("RESETING", this.adicionais);
      this.grupoSelecionado = {};
      this.modoEdit = false;
      this.itemEDITI = null;
      this.pedido = {
        itens: [],
        borda: null,
        adicionais: [],
        preco_tipo: null,
        obs: "",
      };
      this.preco_tipo_inicial = null;
      this.pizza = false;
      this.openItem = false;
      this.$forceUpdate();
      await this.$nextTick();
    },
    async editar(item) {
      this.pedido = item;

      this.itemEDITI = item;

      this.modoEdit = true;
      this.preco_tipo_inicial = this.pedido.preco_tipo;
      this.produtosShow = this.produtos.filter((x) => x.grupo == item.grupo);

      // console.log("edit", item, this.pedido);

      this.grupoSelecionado = Object.assign(
        {},
        this.cardapio.find((x) => x.Grupo == item.grupo)
      );
      // {
      //   Grupo: item.grupo,
      //   cod_grupo: item.itens[0].cod_grupo,
      // };
      this.pizza = item.grupo == "PIZZA";
      this.openItem = true;

      await this.$nextTick();
      this.adicionais = Object.assign(
        [],
        this.$store.state.ingredientes.filter((x) => x.grupo == "ADICIONAIS")
      );
      //this.adicionais[0].sabor = 0;

      this.$forceUpdate();
      await this.$nextTick();
      // this.adicionais.map((itt) => {
      //   let it = item.adicionais.find(
      //     (x) => x.cod_ingrediente == itt.cod_ingrediente
      //   );
      //   if (it) {
      //     itt.sabor = it.sabor;
      //   }
      //   return itt;
      // });

      // this.pedido = this.PedidoEdit;
      //this.$refs.confPizza.fillAdicionais();
      this.$bvModal.show("modal-itens");
      this.calcularTotal();
    },
    calcularTotal(ped) {
      let pedido = ped ? ped : this.pedido;
      // console.log("calculando total", pedido);
      // console.log("calculando its", pedido.itens.length);
      let valor = 0;
      if (pedido.itens && pedido.itens.length > 0) {
        for (let item of pedido.itens) {
          let prec = 0;
          if (pedido.preco_tipo && item.precos) {
            let preco = item.precos.find(
              (x) => x.cod_tipo == pedido.preco_tipo.cod_tipo
            );
            if (
              item.tabela_preco_empresa &&
              item.tabela_preco_empresa.length > 0
            ) {
              let has = item.tabela_preco_empresa.find(
                (x) =>
                  x.cod_empresa == this.empresaSelecionada &&
                  x.tabelaPrecos.find(
                    (u) => u.cod_tipo == pedido.preco_tipo.cod_tipo
                  )
              );
              if (has) {
                has = item.tabela_preco_empresa
                  .find((x) => x.cod_empresa == this.empresaSelecionada)
                  .tabelaPrecos.find(
                    (u) => u.cod_tipo == pedido.preco_tipo.cod_tipo
                  );
                if (has) {
                  preco = has;
                }
              }
            }
            if (preco) {
              prec = parseFloat(
                pedido.itens.length > 1 ? preco.meia : preco.inteira
              );
            } else {
              prec = item.preco_venda;
            }
          } else {
            prec = item.preco_venda;
          }
          valor += prec;
        }
      }
      if (pedido.borda && pedido.borda.preco_venda) {
        valor += parseFloat(pedido.borda.preco_venda);
      }
      // console.log("adds3", this.pedido.adicionais.length);
      if (pedido.adicionais && pedido.adicionais.length > 0) {
        for (let add of pedido.adicionais) {
          if (
            add.sabor !== false &&
            add.sabor >= 0 &&
            add.preco_venda &&
            add.preco_venda > 0
          ) {
            valor += parseFloat(add.preco_venda);
          }
        }
      }
      // console.log('val antes', valor, (valor % 2) % 2)
      /*if (valor - parseInt(valor) > 0) valor += valor - parseInt(valor);
      console.log("val dps", valor);
*/
      pedido.vl_unitario = valor;
      pedido.vl_total = valor * (pedido.qtd || 1);
      return valor;
    },
    fecha(evt) {
      evt.preventDefault();
      // console.log(evt);
      return;
    },
    clickGlobal(evt) {
      // console.log(evt);
      evt;
    },
    atualizaAdicionais(adds) {
      // console.log("atualizou adds");
      this.adicionais = adds;
    },
    selectTipoPreco(tipo) {
      this.pedido.preco_tipo = tipo;
      // console.log(tipo);
    },
    async fillCardapio() {
      await this.$nextTick(() => {
        if (this.$store.state.cardapio.length > 0) {
          this.cardapio = this.$store.state.cardapio.map((it) => {
            return {
              ...it,
              produtos: it.produtos.filter((x) =>
                this.mesa && x.tipo_venda
                  ? x.tipo_venda.includes("MESA")
                  : 1 == 1
              ),
            };
          });
        }
      });
    },
    async open(item) {
      this.modoEdit = false;
      this.pedido = {
        itens: [],
        borda: null,
        adicionais: [],
        preco_tipo: null,
        obs: "",
        combo_id: null,
      };
      this.pedido.borda = { cod_ingrediente: -1 };
      this.pedido.itens = [];
      this.pedido.adicionais = [];
      this.combo = item.Grupo == "Combos";
      item.produtos = item.produtos.filter((x) =>
        this.mesa && x.tipo_venda ? x.tipo_venda.includes("MESA") : 1 == 1
      );

      this.grupoSelecionado = item;
      console.log("item", item, this.cardapioShow);
      this.adicionais
        .filter((x) => x.sabor >= 0)
        .map((item) => {
          item.sabor = false;
        });
      this.$nextTick(() => {
        this.pizza = item.Grupo == "PIZZA";
        this.produtosShow = item.produtos;
        if (!this.Inside) {
          if (this.combo) {
            this.$bvModal.show("modal-combo");
          } else {
            this.$bvModal.show("modal-itens");
          }
          this.$nextTick();
        }
        this.openItem = true;
        this.$forceUpdate();
        this.filtrar = "";
      });
    },
    async adicionar(opc) {
      console.log("ITEM RECEBIDO", opc, this.pedido);
      // let hasCOmbo = this.pedido.itens.find(x => x.combo_id && x.combo_id > 0);
      // this.pedido.combo_id =hasCOmbo?hasCOmbo.combo_id:null;

      let tem = this.pedido.itens.find((x) => x.cod_produto == opc.cod_produto);
      //tem = false;
      if (opc.combo_id) this.pedido.combo_id = opc.combo_id;
      if (!tem) {
        if (
          (this.grupoSelecionado && this.grupoSelecionado.Grupo == "PIZZA") ||
          (opc.ingredientes && opc.ingredientes.find((x) => x.qtd && x.qtd > 0))
        ) {
          if (opc.ingredientes && opc.ingredientes.length > 0) {
            opc.ingredientes
              .filter(
                (x) => this.grupoSelecionado.Grupo == "PIZZA" || x.qtd > 0
              )
              .map((ing) => {
                ing.op = "+";
              });
          }
          if (this.grupoSelecionado.Grupo == "PIZZA") {
            if (this.pedido.itens.length >= 2) {
              this.pedido.itens = [this.pedido.itens[0]];
            }
            if (this.pedido.itens.length < 2) {
              this.pedido.itens.push(opc);
            }
            if (this.pedido.itens.length == 2) {
              //focus na borda
            }
          } else {
            // console.log("adicionou", opc);
            this.pedido.combo_id = opc.combo_id;
            this.pedido.itens.push(Object.assign({}, { ...opc }));
            await this.$nextTick();
            // console.log("itens", this.pedido.itens);
          }
        } else {
          if (opc.combo_id) this.pedido.combo_id = opc.combo_id;
          this.pedido.itens.push(opc);
        }
      } else {
        this.pedido.itens = this.pedido.itens.filter(
          (x) => x.cod_produto != opc.cod_produto
        );
      }

      // console.log("ocp", opc, this.pedido.itens);
    },
    salvar() {
      let error = "";

      if (this.pedido.combo_id && this.pedido.combo_id > 0) {
        let hasPizza = this.pedido.itens.find(
          (x) => x.Tipo && x.Tipo == "PIZZA"
        );
        if (hasPizza && this.pedido.preco_tipo == null) {
          this.$bvModal.show("modal-combo");
          error + "<li>Selecione o TAMANHO da pizza</li>";
        }
      }
      if (this.pedido.itens.length <= 0) {
        error = "<li>Selecione ao menos 1 Item! </li>";
      }
      if (this.pizza && this.pedido.preco_tipo == null) {
        error += "<li>Escolha o TAMANHO da pizza.</li>";
      }

      if (error != "") {
        this.$swal.fire({
          title: "Pedido com erros",
          html: "<ul class='text-left'>" + error + "</ul>",
          icon: "error",
          timer: 3200,
          toast: true,
          position: "top-right",
          allowEscapeKey: true,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      } else {
        if (!this.modoEdit) {
          if (this.grupoSelecionado && this.grupoSelecionado.Grupo == "PIZZA") {
            this.Itens.push({
              ...this.pedido,
              pizza: true,
              grupo: this.grupoSelecionado.Grupo,
              qtd: 1,
              vl_unitario: this.calcularTotal(),
              vl_total: this.calcularTotal(),
            });
          } else {
            this.pedido.itens.map((it) => {
              // console.log("itens", this.Itens.length);
              let findItem = this.Itens.find(
                (x) =>
                  x.itens.find((q) => q.cod_produto == it.cod_produto) != null
              );
              findItem = false;
              // console.log("encontrou", it.cod_produto, findItem);
              if (!findItem) {
                let p = Object.assign({}, this.pedido);
                // console.log("p", p);
                p.itens = [it];
                p.pizza = false;
                p.grupo = this.grupoSelecionado.Grupo;
                if (!p.qtd) {
                  p.qtd = 1;
                }
                p.vl_unitario = this.calcularTotal();
                p.vl_total = p.vl_unitario * p.qtd;

                p.combo_id = it.combo_id;

                this.Itens.push(p);
              } else {
                // console.log("encontrado", findItem.itens[0].cod_produto);
                findItem.qtd++;
                findItem.vl_total =
                  findItem.itens[0].vl_unitario * findItem.qtd;
              }
            });
          }
        } else {
          this.pedido.vl_unitario = this.calcularTotal();
          this.pedido.vl_total = this.calcularTotal() * this.pedido.qtd;
        }
        // console.log(this.Itens);
        this.$swal.fire({
          title: `Pedido ${this.modoEdit ? "atualizado" : "adicionado"}!`,
          icon: "success",
          position: "top-end",
          allowEscapeKey: true,
          toast: true,
          timer: 1700,
          showConfirmButton: false,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-itens");
        this.$emit("closeModalBehind");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cardapio.scss"
</style>
