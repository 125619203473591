<template>
  <div class="row px-3">
    <div class="col-12">
      <b-input placeholder="filtrar" v-model="filter" />
    </div>
    <div class="col-12" v-for="(item, idxIT) in filtred" :key="`item${idxIT}`">
      <div class=" ">
        <div
          class="my-2 py-3 pl-2 pr-4 text-white w-100 d-flex justify-content-between"
          :class="{
            'bg-success': item.tipo == 1,
            'bg-danger': item.tipo !== 1,
          }"
        >
          <b>{{ item.planoGrupo }}</b>
          <div>{{ item.valor | currency }}</div>
        </div>
        <div
          v-for="(sb, idxSB) in item.subgrupos"
          :key="`sb_${idxSB}`"
          class="pl-2 pr-2"
        >
          <div
            class="d-flex justify-content-between click p-2"
            :class="{ 'bg-light': sb.show }"
            @click="sb.show = !sb.show"
          >
            <b>{{ sb.planoSubgrupo }}</b>
            <div>
              {{ sb.valor | currency }}
            </div>
          </div>

          <div :class="{ 'd-none': !sb.show, 'd-block': sb.show }">
            <div
              v-for="(plano, idxPlano) in sb.planos"
              :key="`plano${idxPlano}`"
            >
              <div
                class="d-flex justify-content-between click p-2 pl-5"
                :class="{ 'bg-light': plano.show }"
                @click="plano.show = !plano.show"
              >
                <b>{{ plano.planoContas }}</b>
                <div>
                  {{ plano.valor | currency }}
                </div>
              </div>

              <div class="" v-if="plano.show">
                <table class="table w-100 table-hover" style="font-size: 10px">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Carteira</th>
                      <th>Plano Contas</th>
                      <th>Fornecedor</th>
                      <th>Centro Custo</th>
                      <th>Observação</th>
                      <th class="text-right">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="it in plano.itens"
                      :key="`sbItem${item.planoGrupo}${item.planoSubgrupo}${it.id}`"
                    >
                      <td>
                        {{ it.id }}
                      </td>
                      <td>
                        {{ it.Carteira }}
                      </td>
                      <td>
                        {{ it.PlanoContas }}
                      </td>
                      <td>
                        {{ it.fornecedor }}
                      </td>
                      <td>
                        {{ it.fornecedor }}
                      </td>
                      <td>
                        {{ it.observacao }}
                      </td>
                      <td class="text-right pr-1">
                        {{ it.valor | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    detalheResumo: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  data() {
    return {
      filter: "",
    };
  },
  watch: {},
  computed: {
    filtred() {
      return this.detalheResumo
        ? this.detalheResumo
            .filter((item) => {
              return item.subgrupos.some((sb) =>
                sb.planos.some((plano) =>
                  plano.itens.some(
                    (it) =>
                      (it.observacao
                        ? it.observacao
                            .toLowerCase()
                            .includes(this.filter.toLowerCase())
                        : false) ||
                      (it.Carteira
                        ? it.Carteira.toLowerCase().includes(
                            this.filter.toLowerCase()
                          )
                        : false) ||
                      (it.PlanoContas
                        ? it.PlanoContas.toLowerCase().includes(
                            this.filter.toLowerCase()
                          )
                        : false) ||
                      (it.fornecedor
                        ? it.fornecedor
                            .toLowerCase()
                            .includes(this.filter.toLowerCase())
                        : false)
                  )
                )
              );
            })
            .map((it) => {
              it.subgrupos = it.subgrupos.map((sb) => {
                sb.planos = sb.planos.map((plano) => {
                  plano.itens = plano.itens.map((it) => {
                    it.show = this.filter && this.filter != "" ? true : false;
                    return it;
                  });
                  plano.show = this.filter && this.filter != "" ? true : false;
                  return plano;
                });
                sb.show = this.filter && this.filter != "" ? true : false;
                return sb;
              });
              return it;
            })
        : [];
    },
  },
  methods: {},
};
</script>

<style lang="sass" scoped>

@import "@/assets/scss/home.scss"
</style>
