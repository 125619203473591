import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from "./filters/index.js";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueTheMask from "vue-the-mask";
import money from "v-money";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import vSelect from "vue-select";



// import VueSocketIO from 'vue-socket.io'

import socketio from "socket.io-client";
import VueSocketIO from "vue-socket.io";


// Vue.use(VueSocketIO, SocketInstance)

import "./registerServiceWorker";


import excel from 'vue-excel-export'
Vue.use(excel)

import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css' // needs css-loader

Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
})



// register directive v-money and component <money>

Vue.config.productionTip = false;

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/scss/app.scss";
import "vue-search-select/dist/VueSearchSelect.css";

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.component("VueSlider", VueSlider);
Vue.component("v-select", vSelect);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(filters);
Vue.use(VueSweetalert2);
const moment = require("moment");
require("moment/locale/pt-br");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VueTheMask);
Vue.use(money, { precision: 2 });

Vue.use(VCalendar);

import VueQuagga from 'vue-quaggajs';
Vue.use(VueQuagga);


const getToken = () => {
  return store.state.auth.user.token;
}
Vue.use(
  new VueSocketIO({
    // debug: window.location.href.indexOf("localhost") > 0,
    debug: false,
    // connection: socketio('https://ciapizza.ddns.net:8030', { path: "/socket.io/", query: 'token=' + getToken()  }), //options object is Optional
    connection: socketio(require('./utils/axios').default.defaults.baseURL, { path: "/socket.io/", query: 'token=' + getToken() }), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
store.state.menu = require("./Menu").default;

Vue.mixin({
  data() {
    return {
      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
      percentMask: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: true,
      },
      user: store.state.auth.user
    };
  },
  methods: {
    formatValorReal(vl) {
      if (!vl) return 0;
      if (!vl.toString().includes("R$")) {
        vl = this.formatFloatToReal(vl);
      }
      vl = parseFloat(
        vl.toString().replace("R$", "").replace(".", "").replace(",", ".")
      );
      return !vl || vl == "NaN" ? 0 : vl;
    },
    formatFloatToReal(vl, prefix = "R$ ") {
      if (!vl || typeof vl == "undefined" || vl == null || vl == "") {
        vl = 0;
      }
      return (
        prefix +
        parseFloat(vl)
          .toFixed(2)
          .replace(",", "")
          .replace(".", ",")
      );
    },
    showToast(
      icone = "",
      message = "",
      time = 1500,
      description = "",
      confirmButton = false,
      position = "top-end"
    ) {
      this.$swal.fire({
        title: message,
        icon: icone,
        toast: true,
        position: position,
        timer: time,
        showConfirmButton: confirmButton,
        timerProgressBar: true,
        text: description,

        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
    },
    reducerGroup(lista, key) {
      if (!lista)
        return [];


      return lista.reduce((ret, vl) => {
        let has = ret.find(x => x[key] == vl[key]);
        if (has) {
          has.lista.push(vl);

        } else {
          ret.push({
            [key]: vl[key],
            lista: [vl]
          });
        }

        return ret;
      }, []);
    },
    reducerSum(lista, key) {
      if (!lista) {
        return [];
      }
      return lista.reduce((ret, vl) => {

        ret += vl[key];

        return ret;
      }, 0);
    }
  },
});

Vue.directive("focus", {
  inserted: function (el) {
    el.addEventListener("focus", () => {
      setTimeout(() => {
        el.focus();
        el.select();
      }, 100);
    });
  },
});

//import firebaseMessaging from './firebase'
//Vue.prototype.$msg = firebaseMessaging




// function verificarConnection() {
//   const axios = require("axios");
//   const axiosUtil = require("./utils/axios");
//   setTimeout(async () => {
//     try {
//       let res = await axios.get(axiosUtil.default.defaults.baseURL);
//       console.log("verificado a conexão result", res.data);
//       if (!res || !res.data || !res.data.getting) {
//         store.state.API_BKP = true;
//       } else {
//         store.state.API_BKP = false;
//       }
//     } catch (er) {
//       console.log("erro ao verificar connection()", er);
//       store.state.API_BKP = true;
//     }
//     verificarConnection();

//   }, 3000);
// }
// verificarConnection();



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
