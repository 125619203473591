<template>
  <div
    :class="{ produtoSelecionado: selecionado }"
    class="produto"
    v-if="preco"
  >
    <div class="d-flex w-100">
      <!-- <div v-if="Item && Item.imagem_thumb"
      class="text-center"
      style="width: 50px; max-width: 50px; min-height: 50px;">
        <b-img
          style="max-width:100%; border-radius: 10px;"
          :src="Item && Item.imagem_thumb ? Item.imagem_thumb : ''"
        
          
        />
      </div> -->
      <span style="font-weight: bold">
        <div class="pl-2">
          {{ Item.nome }}
          <div
            class="my-1"
            v-if="Item && Item.ingredientes && Item.ingredientes.length > 0"
          >
            <small
              v-for="ing in Item.ingredientes"
              :key="ing.id"
              class="font-weight-light"
            >
              <span variant="light">{{ ing.ingrediente }}, </span>
            </small>
          </div>
          <small v-if="Item.produtos && Item.produtos.length > 0">
            <span
              v-for="p in Item.produtos"
              :key="`${Item.cod_produto}_${p.id}`"
            >
              {{ p.produto.nome }}

              <span v-if="p.tipo && p.tipo.tipo"> [{{ p.tipo.tipo }}] </span>
              <span v-if="Item.produtos.indexOf(p) < Item.produtos.length - 1"
                >+</span
              >
            </span>
          </small>
        </div>
      </span>
      <div
        v-if="withPrice && Item.preco_venda"
        class="text-right pl-2 pr-2"
        style="font-size: 10px; word-break: keep-all; min-width: 60px; flex: 1"
      >
        {{ Item.preco_venda | currencyMask }}
      </div>

      <div></div>
    </div>

    <div v-if="selecionado" class="mt-2">
      <i class="fa fa-trash"></i> Remover
      <div class="prodQtd"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Item: Object,
    preco_tipo: Object,
    selecionado: Boolean,
    withPrice: {
      type: Boolean,
      default: false,
    },
    mesa: Object,
    empresaSelecionada: Number,
  },
  mounted() {},
  computed: {
    preco: function () {
      let prc = { inteira: this.Item.preco_venda };
      if (this.preco_tipo && this.Item.precos) {
        prc = this.Item.precos.find(
          (x) => x.cod_tipo == this.preco_tipo.cod_tipo
        );
        if (
          this.Item.tabela_preco_empresa &&
          this.Item.tabela_preco_empresa.length > 0
        ) {
          let fnd = this.Item.tabela_preco_empresa.find(
            (x) => x.cod_empresa === this.empresaSelecionada
          );
          // console.log("empfind", fnd);
          if (fnd) {
            prc = fnd.tabelaPrecos.find(
              (x) => x.cod_tipo === this.preco_tipo.cod_tipo
            );
          }
        }
      }

      return prc;
    },
  },
  methods: {
    openIMG(Item) {
      console.log("openIMG", Item);
    }
  }
};
</script>
