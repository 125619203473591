<template>
  <div class="bg-gray">
    <div class="chatHeader">
      <div><i class="fa fa-comments"></i> Chat</div>
      <div class="text-right user">
        <i class="fa fa-user"></i> {{ user.nome }}
      </div>
      <div v-if="withClose" class="btCloseChat" @click="closeChat">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <div class="chatTo">
      <div
        v-for="emp in empresas"
        :key="empresas.indexOf(emp)"
        :class="{ selected: emp == empresaSelecionada }"
        @click="selectEmpresa(emp)"
      >
        {{ emp.nome }}
        <span class="chatToBadge" v-if="emp.qtd && emp.qtd > 0">
          {{ emp.qtd }}
        </span>
      </div>
    </div>
    <div class="chatBody">
      <div class="content scrollPersonalizado" ref="chatContent">
        <div v-if="loading" class="text-center">
          <b-spinner grow variant="primary" />
          Carregando...
        </div>
        <div
          class="msg"
          :class="{ rtl: msg.cod_usuario == user.cod_usuario }"
          v-for="msg in mensagens"
          :key="'_chat' + msg.cod_chat"
        >
          <div class="user">
            {{ msg.usu }}
            <div class="data">
              {{ msg.data | moment("DD/MM/YYYY HH:mm:ss") }}
            </div>
          </div>
          <div class="msg-msg">{{ msg.mensagem | msg }}</div>
        </div>
      </div>
      <div class="input">
        <b-input
          placeholder="digite sua mensagem"
          autocomplete="off"
          v-model="message"
          @keypress.native="
            e => {
              if (e.key == 'Enter') {
                sendMessage();
              }
            }
          "
        />
        <b-button variant="secondary" @click="sendMessage()">
          <i class="fa fa-paper-plane" />
        </b-button>
        <b-button variant="light" @click="sendMessage(':like:')"
          ><i class="far fa-thumbs-up text-success"></i
        ></b-button>
        <b-button variant="light" @click="sendMessage(':deslike:')"
          ><i class="far fa-thumbs-down text-danger"></i
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    withClose: {
      type: Boolean,
      default: false
    }
  },
  sockets: {
    connect() {
      // console.log("conectado CHAT");
    },
    solicitarMensagens() {
      // console.log("solicitado request msg");
      // this.loading = true;
      // this.mensagens = [];
      this.$socket.emit("mensagemSolicitada", {
        cod_empresa: this.empresaSelecionada.cod_empresa,
        cod_usuario: this.user.cod_usuario,
        cod_empresa_pai: this.empresaSelecionada.cod_empresa_pai
          ? this.empresaSelecionada.cod_empresa_pai
          : this.empresaSelecionada.cod_empresa == 0
          ? this.empresas[1].cod_empresa
          : this.empresaSelecionada.cod_empresa,
        cod_chat:
          this.mensagens && this.mensagens.length > 0
            ? this.mensagens[this.mensagens.length - 1].cod_chat
            : null
      });
    },
    async mensagens(data) {
      if (!this.load) {
        this.load = true;
        await this.$nextTick();
        for (let msg of data) {
          let mm = this.mensagens.find(x => x.cod_chat == msg.cod_chat);
          if (!mm) {
            this.mensagens.push(msg);
          }
        }
        // this.mensagens = data;
        // console.log("mensagens", data);
        this.loading = false;
        this.mensagens = this.mensagens.sort((a, b) => {
          return moment(a.data).isBefore(b.data) ? -1 : 1;
        });
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.chatContent.scrollTop = 999999;
          }, 100);
          this.load = false;
        });
      }
    },
    mensagensNaoLidasEmpresa(data) {
      // console.log("POR EMPRESA ---------------", data);
      for (let emp of this.empresas) {
        let fnd = data.find(x => x.cod_empresa == emp.cod_empresa);
        if (fnd) {
          emp.qtd = fnd.qtd;
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      load: false,
      mensagens: [],
      user: this.$store.state.auth.user,
      empresas: [
        { cod_empresa: 0, nome: "Global" },
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas
      ],
      empresaSelecionada: null,
      message: ""
    };
  },
  mounted() {
    this.empresaSelecionada = this.empresas[0];
    this.joinRoom();
  },
  methods: {
    closeChat() {
      this.$emit("closeChat");
    },
    joinRoom() {
      this.$socket.emit(
        "joinChat",
        "chat_" +
          this.empresaSelecionada.cod_empresa +
          "_user_" +
          this.user.cod_usuario
      );
    },
    selectEmpresa(emp) {
      if (this.load || this.loading) {
        this.$swal.fire({
          title: "aguarde...",
          toast: true,
          showConfirmButton: false,
          timer: 1000,
          position: "bottom-start"
        });
      } else {
        this.mensagens = [];
        this.loading = true;
        this.empresaSelecionada = emp;
        this.joinRoom();
      }
    },
    sendMessage(msg) {
      if ((!msg || msg == "") && this.message.toString().trim() == "") {
        this.$swal.fire({
          title: "Informe uma mensagem!",
          icon: "error",
          toast: true,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false
        });
      } else {
        this.$socket.emit("sendChatMessage", {
          cod_usuario: this.user.cod_usuario,
          cod_empresa: this.empresaSelecionada.cod_empresa,
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
          mensagem: !msg || msg == "" ? this.message : msg
        });
        this.message = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chatHeader {
  display: flex;
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 22px;
  text-align: left;
  align-items: baseline;
  transition: 0.3s all ease;

  div {
    flex-grow: 1;
    padding: 3px;
  }
  .user {
    font-size: 13px;
    align-self: baseline;
  }
}
.chatTo {
  display: flex;
  background-color: #333;
  border-bottom: 5px solid #222;
  position: relative;
  color: white;
  transition: 0.3s all ease;

  .chatToBadge {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px !important;
    min-height: 20px !important;
    height: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border-radius: 50%;
    background-color: red;
    color: white;
    text-align: center;
    font-weight: bold;
    flex: none !important;
    padding-top: 2px !important;
    transition: 0.3s all ease;
  }
  div {
    flex-grow: 1;
    font-size: 10px;
    padding: 10px;
    min-height: 45px;
    vertical-align: middle;
    position: relative;

    cursor: pointer;
    transition: 0.2s all ease;
  }
  @mixin toSelected() {
    background-color: #222;
    color: #fff;
    font-weight: bold;
  }
  div:hover {
    @include toSelected();
  }
  .selected {
    @include toSelected();
  }
}
.chatBody {
  background-color: #222;
  position: absolute;
  top: 104px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.3s all ease;

  .content {
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: 0.3s all ease;

    position: absolute;
    top: 10px;
    right: 5px;
    left: 5px;
    bottom: 45px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;

    .msg {
      width: 100%;
      display: block;
      height: 60px;
      display: block;
      padding: 25px;
      font-size: 14px;
      float: left;
      margin-bottom: 5px;
      margin-top: 20px;
      transition: 0.3s all ease;

      .user {
        font-weight: bold;
        padding-right: 15px;
        font-size: 13px;
        width: 100%;

        text-align: left;
        float: left;
      }
      .data {
        font-size: 8px;

        text-align: left;
        display: block;
        width: 100%;
      }
      .msg-msg {
        margin-top: 0px;
        display: block;
        float: left;
        background: #f9f9f9;
        width: 100%;
        min-height: 40px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 10px;
        text-align: left;
        width: 80% !important;
      }
    }
    .rtl {
      .user {
        text-align: right;
        float: right;
      }
      .data {
        float: right;
        text-align: right;
      }
      .msg-msg {
        float: right;
        border-top-right-radius: 0px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        background: rgb(196, 198, 218);
      }
    }
  }
  .input {
    position: absolute;
    right: 5px;
    left: 5px;
    bottom: 1px;
    height: 40px;
    background: #333;
    transition: 0.1s all;

    font-size: 13px !important;
    display: flex;
    input {
      font-size: 17px !important;
    }
    button {
      font-size: 13px !important;
    }
    input:focus {
      .sendComnet {
        opacity: 1 !important;
      }
    }
  }
}
.btCloseChat {
  max-width: 40px;
  max-height: 40px;
  font-size: 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  float: right;
  padding-top: 10px;
  transition: 0.3s all ease;
}
.btCloseChat:hover {
  background: red;
}
</style>