import { render, staticRenderFns } from "./Saidas.vue?vue&type=template&id=ff0c2ba0&scoped=true"
import script from "./Saidas.vue?vue&type=script&lang=js"
export * from "./Saidas.vue?vue&type=script&lang=js"
import style0 from "./Saidas.vue?vue&type=style&index=0&id=ff0c2ba0&prod&lang=css"
import style1 from "./Saidas.vue?vue&type=style&index=1&id=ff0c2ba0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff0c2ba0",
  null
  
)

export default component.exports