<template>
  <div>
    <b-modal
      id="modal-aut"
      size="sm"
      hide-footer
      no
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      @hidden="result(null)"
      no-enforce-focus
    >
      <h4><b-icon-lock /> Login de Autorização</h4>
      <b-form @submit.prevent="login">
        <div class="row">
          <div class="col-12">
            <label>Usuário</label>
            <b-input v-model="usuario" ref="txtUSU" />
          </div>
          <div class="col-12">
            <label>Senha</label>
            <b-input v-model="senha" type="password" />
          </div>
          <div class="col-12 text-center mt-3">
            <b-btn variant="success" block type="submit" :disabled="loading">
              <b-spinner v-if="loading" small />
              <span v-if="!loading">
                <b-icon-lock-fill /> Autorizar</span
              ></b-btn
            >

            <b-btn variant="info" class="mt-2" block @click="solicitar">
              <b-icon-question-circle /> Solicitar Permissão
            </b-btn>

            <b-btn variant="secondary" block class="mt-4" @click="result(null)">
              <i class="fa fa-ban" />
              Cancelar</b-btn
            >
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-solicitar-externo"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      @hidden="clearTimer"
    >
      <div v-if="!permitido">
        <div class="p-3 text-center">
          <h3>
            <b-icon-lock style="font-size:60px" /> <br />
            Aguardando autorização
            <br />
            <b-spinner variant="danger" />
          </h3>
          <small>
            O sistema solicitou para algum administrador/gerente autorizar sua
            solicitação. <br />
            Aguarde a resposta! <br />
            <br />

            -- ou --
          </small>
        </div>
        <div class="mt-2">
          <b-btn variant="danger" block @click="cancelarSolicitacao"
            ><i class="fa fa-ban" /> Cancelar</b-btn
          >
        </div>
        <div class="mt-4">
          <label><b-icon-chat-dots /> Mensagem para autorizador</label>
          <b-textarea v-model="obs_usu" />
        </div>
      </div>
      <div v-if="permitido" class="text-center">
        <div style="font-size: 100px !important">
          <b-icon-check-circle class="text-success" />
        </div>
        <h1>
          Autorizado!
        </h1>
        <hr />
        <small> Autorizado por {{ usuario_autorizou }} </small>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "../utils/axios";
import SolicitaLib from "../libs/SolicitacaoPermissaoLib";
export default {
  components: {},
  props: {
    idExterno: String,
    tipo: String,
    obs: String
  },
  mounted() {
    this.$bvModal.show("modal-aut");
    setTimeout(() => {
      this.$refs.txtUSU.focus();
      this.$refs.txtUSU.select();
    }, 200);
  },
  unmounted() {
    this.clearTimer();
  },
  data() {
    return {
      usuario: "",
      senha: "",
      loading: false,
      timerVerifica: null,
      idPermissao: 0,
      permitido: false,
      usuario_autorizou: "",
      obs_usu: ""
    };
  },
  watch: {
    obs_usu: function() {
      this.updateSolicitacao();
    }
  },
  computed: {},
  methods: {
    async updateSolicitacao() {
      if (this.idExterno && this.obs_usu && this.obs_usu != "") {
        SolicitaLib.updateSolicitacao({
          id: this.idPermissao,
          obs_usuario: this.obs_usu
        });
      }
    },
    async cancelarSolicitacao(del=true) {
      if(del){
        await SolicitaLib.deleteSolicitacao(this.idPermissao);
      }
      this.$bvModal.hide("modal-solicitar-externo");
      this.clearTimer();
    },
    clearTimer() {
      try {
        clearTimeout(this.timerVerifica);
      } catch {
        this.timerVerifica = null;
      }
      this.timerVerifica = null;
      this.idPermissao = null;
      this.permitido = false;
      this.usuario_autorizou = "";
      this.obs_usu = "";
    },
    startTimer() {
      this.timerVerifica = setInterval(async () => {
        let res = (await SolicitaLib.buscarSolicitacao(this.idPermissao)).data;
        // console.log('-->',res);
        if (res.success) {
          if (res.data && res.data.dt_resposta) {
            if (res.data.permitido !== 1) {
              this.$swal(
                "Permissão negada!",
                `${res.data.usuario_aut} negou sua solicitação de permissão`,
                "error"
              );
              
              this.cancelarSolicitacao(false);
            } else {
              this.permitido = true;
              this.usuario_autorizou = res.data.usuario_aut;
              setTimeout(() => {
                this.$emit("result", true);
                this.clearTimer();
                this.$emit("setSolicitacaoID", res.data.id);
              }, 1300);
            }
          }
        }
      }, 1000);
    },
    async solicitar() {
      this.clearTimer();
      this.$bvModal.show("modal-solicitar-externo");
      let res = await SolicitaLib.storeSolicitacao({
        cod_empresa: this.$store.state.empresa_ativa.cod_empresa,
        cod_usuario_solicitou: this.$store.state.auth.user.cod_usuario,
        id_externo: this.idExterno,
        obs: this.obs,
        tipo: this.tipo
      });
      console.log("solicitacao", res);

      if (!res.success) {
        this.$swal(
          "Erro ao solicitar",
          "Houve um erro na sua solicitação. Favor tentar novamente!",
          "error"
        );
        this.$bvModal.hide("modal-solicitar-externo");
      } else {
        this.idPermissao = res.data[0];
        this.startTimer();
      }
    },
    result(result) {
      this.$emit("result", result);
    },
    async login(e) {
      if (e) {
        e.preventDefault();
      }
      if (!this.usuario || this.usuario.trim() == "") {
        this.$swal("Atenção", "Informe um nome de usuário válido!", "error");
        this.$refs.txtUSU.focus();
        this.$refs.txtUSU.select();
        return;
      }
      if (!this.senha || this.senha.trim() == "") {
        this.$swal("Atenção", "Informe uma senha válida!", "error");
        return;
      }
      this.loading = true;
      let result = (
        await axios.post("/auth", {
          tipo: "sistema",
          usuario: this.usuario,
          senha: this.senha,
          solicitacao: true,
          id_externo: this.idExterno,
          obs: this.obs,
          tipo_solicitacao: this.tipo,
          user_solicitacao: this.$store.state.auth.user.cod_usuario,          
        })
      ).data;
      console.log('123',result);
      if (result && result.token && result.token != "") {
        this.result(true);
      } else {
        this.$swal("Erro", "Usuário e/ou senha inválido(s) OU NÃO AUTORIZADO!", "error");
      }

      this.loading = false;
      console.log("result", result);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>