<template>
  <div>
    <div class="menuEntregas text-left">
      <!-- <div class="bg-menu titulo">
        <img src="@/assets/img/icones/menu/motorcycle_delivery_multiple_boxes_40px.png" />
        Entregas
      </div> -->
      <router-link to="/entregas/geral" active-class="selecionado">
        <i class="fa fa-globe" />
        Visão Geral
      </router-link>
      <router-link to="/entregas/motos" active-class="selecionado">
        <i class="fa fa-motorcycle" />
        Motos
      </router-link>
      <router-link to="/entregas/pendentes" active-class="selecionado">
        <i class="far fa-pause-circle" />
        Entregas Pendentes
      </router-link>
      <router-link to="/entregas/rota" active-class="selecionado">
        <i class="fa fa-road" />
        Entregas em Rota
      </router-link>
      <router-link to="/entregas/concluidas" active-class="selecionado">
        <i class="fa fa-map-marked" />
        Entregas Concluídas
      </router-link>
         <router-link to="/entregas/buscar" active-class="selecionado">
        <i class="fa fa-search" />
        Buscar Entregas
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/entregas.scss";
</style>