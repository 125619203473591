import axios from '@/utils/axios';

const getMotos = async (filter) => {
    return (await axios.get('motos/get', { params: filter })).data;
}

const getMotosDisponiveis = async (data) => {
    let res = null;
    try {
        res = (await axios.post('/entregas/motos_disponiveis', data)).data;
    } catch (error) {
        console.log("error", error);
    }
    return res;
}

export default {
    getMotos,
    getMotosDisponiveis
}