<template>
  <div id="btBarraFind" ref="btBarraFind">
    <i class="fa fa-search" id="btSearch" @click="setBusca()"></i>
    <input
      type="text"
      placeholder="digita sua busca...."
      id="txtBuscaG"
      ref="txtBuscaG"
      @keyup="change"
      :class="buscaAberta ? 'opened' : ''"
      v-model="busca"
      @blur="out()"
    />
    <b-spinner small class="loadingBusca" v-if="loading" />
    <i
      class="fa fa-times"
      id="btCloseSearch"
      @click="clear()"
      style="visibility: hidden; font-size: 15px !important"
      :class="{ visible: busca != '' || buscaAberta }"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    onFiltrar: Function,
    buscaAberta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busca: "",
      loading: false,
      interval: null,
    };
  },
  methods: {
    change() {
      if (this.busca != "") {
        this.$refs.txtBuscaG.classList.add("opened");
      } else if (!this.buscaAberta) {
        this.$refs.txtBuscaG.classList.remove("opened");
      }
      //      if (!this.loading) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.loading = true;
      let me = () => {
        this.$forceUpdate();
        this.buscar();
      };
      this.interval = setTimeout(() => {
        // console.log(1, me);
        me();
      }, 200);
      //    }
    },
    out() {
      if (this.busca.trim() == "" && !this.buscaAberta) {
        this.$refs.txtBuscaG.classList.remove("opened");
      }
    },
    async buscar() {
      if (this.$props.onFiltrar) {
        await this.$props.onFiltrar(this.busca);
      }
      this.loading = false;
    },
    clear() {
      this.busca = "";
      this.$refs.txtBuscaG.focus();
      this.buscar();
    },
    setBusca() {
      if (!this.$refs.txtBuscaG.classList.contains("opened"))
        this.$refs.txtBuscaG.classList.add("opened");

      // console.log(this.$refs.txtBuscaG.classList.contains("opened"));
      this.$refs.txtBuscaG.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingBusca {
  position: absolute;
  right: 50px;
  top: 25px;
  opacity: 0.4;
}
.visible {
  visibility: visible;
}
$barraW: 80%;
#txtBuscaG {
  width: 0px;
  transition: all 0.3s ease;
  background: none !important;
  background-color: none !important;
  padding: 10px;
  position: relative !important;
}
#txtBuscaG:focus {
  width: $barraW;
  border-bottom: 1px solid #ccc !important;
  #btBarraFind {
    width: 100%;
    height: 100;
    position: fixed;
    background-color: rgba(255, 255, 255, 10);
    display: block;
    margin: 0 auto;
    float: left;
  }
}

#btBarraFind {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  text-align: right;
  flex-grow: 2;
  padding-right: 20px;
  padding-top: 10px;
  @media only screen and (max-width: 575px) {
    background-color: #f1f1f1;
    position: relative;
    display: block;
    margin: 0 !important;
    text-align: center;
    padding-right: 0px;
    padding-top: 10px;
    flex: 1;
    width: 100%;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    text-align: center !important;
  }
}
#btBarraFind i {
  &:hover {
    //@include open();
  }
}

.opened {
  width: $barraW !important;
  border-bottom: 1px solid #ccc !important;
  #btBarraFind {
    width: 100%;
    height: 100;
    position: fixed;
    background-color: rgba(255, 255, 255, 10);
    display: block;
    margin: 0 auto;
    float: left;
  }
}
@mixin bts {
  font-size: 30px !important;
  text-align: center;
  color: #999;
  cursor: pointer;
  transition: all 0.3s ease;
}
#btSearch {
  @include bts;
  &:hover {
    #txtBuscaG {
      width: $barraW;
    }
  }
}
#btCloseSearch {
  @include bts;
}

@media only screen and (max-width: 900px) {
}
</style>
