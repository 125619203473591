<template>
  <div>
    <b-form @submit.prevent="salvar">
      <div class="row">
        <div class="col-10">
          <b-form-group label="Tipo">
            <b-select
              :options="[
                { value: 1, text: 'Crédito' },
                { value: 0, text: 'Débito' },
              ]"
              v-model="obj.tipo"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Nome">
            <b-input v-model="obj.nome" />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Grupo">
            <v-select :options="gruposD" taggable v-model="obj.grupo" />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Sub-Grupo">
            <v-select :options="subgruposD" taggable v-model="obj.sub_grupo" />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Resumo de Grupo">
            <v-select
              :options="['Vendas Loja', 'Vendas Ifood', 'Despesas','Outras Receitas']"
              
              v-model="obj.resumo_grupo"
            />
          </b-form-group>
        </div>
      </div>
      <div class="mt-3 text-right">
        <hr />
        <div class="text-center" v-if="loading">
          <b-spinner size="sm" /> aguarde...
        </div>
        <b-btn variant="primary" class="mr-1" type="submit"
          ><i class="fa fa-save" /> Salvar</b-btn
        >
        <b-btn type="button" variant="secondary" @click="$emit('close')"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import PlanoContasLib from "../../../../libs/PlanoContasLib";
export default {
  components: {},
  props: {
    editObj: Object,
    grupos: Array,
  },
  mounted() {
    if (this.editObj) {
      this.obj = this.editObj;
      this.$nextTick(() => {
        this.carregar();
      });
    }

    if (this.grupos) {
      this.gruposD = this.grupos.map((gp) => gp.grupo);
    }
  },
  data() {
    return {
      obj: {
        id: null,
        grupo: "",
        sub_grupo: "",
        nome: "",
        tipo: 1,
        resumo_grupo: "",
      },
      loading: false,
      gruposD: [],
      subgruposD: [],
    };
  },
  watch: {
    "obj.grupo": {
      deep: true,
      handler() {
        this.subgruposD = [];
        if (this.grupos && this.obj.grupo && this.obj.grupo != "") {
          let has = this.grupos.find((x) => x.grupo == this.obj.grupo);
          if (has) {
            this.subgruposD = has.sub_grupos.map((sb) => sb.sub_grupo);
          }
        }
      },
    },
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      if (this.obj && this.obj.id) {
        let res = await PlanoContasLib.getPlanoContas({ id: this.obj.id });
        if (res && res.success) {
          this.obj = res.data[0];
        }
      }
      this.loading = false;
    },
    async salvar() {
      let errs = [];
      if (!this.obj.nome || this.obj.nome.trim() == "") {
        errs.push("Informe um nome váido!");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html:
            "<b>Atenção na informação abaixo:</b><hr/>" + errs.join("<hr/>"),
          icon: "error",
        });
      } else {
        this.loading = true;
        let res = await PlanoContasLib.storePlanoContas(this.obj);
        if (res && res.success) {
          this.$swal("Salvo com sucesso", "", "success");
          this.$emit("close");
        } else {
          this.$swal("Erro ao salvar", "", "error");
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
