<template>
  <div class="container-fluid">
    <div class="listaEntregas">
      <div class="lista">
        <div class="row">
          <div class="col-12">
            <empresa-combo
              :selected="empresa.cod_empresa"
              :comTodos="false"
              :selecionar="selectEmpresa"
              :noStyle="false"
            />
          </div>
        </div>

        <div :class="{ 'p-5 font-weight-bold': tipo == 'buscar' && !moto }">
          <b-input
            :class="{ 'p-5 font-weight-bold': tipo == 'buscar' && !moto }"
            placeholder="buscar por nome, moto, bairro, cliente..."
            v-model="filtro"
            autocomplete="off"
            ref="txtBusca"
          />
        </div>
        <div v-if="loading" class="p-4 text-center">
          <b-spinner /><br />
          aguarde...
        </div>
        <b-table
          v-if="!loading"
          :items="lista"
          :fields="fields"
          :filter="filtro"
          selectable
          select-mode="multi"
          hover
          stacked="md"
          ref="tbEntregas"
          @row-selected="onRowSelected"
          small
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(senha)="row">
            #{{ row.item.senha }}
            <small v-if="row.item.sistema == 'IFOOD'">
              - IFOOD: {{ row.item.id_sistema }}
            </small>
            <br />
            <small>{{ row.item.empresa }}</small>
          </template>
          <template #cell(data)="row">
            {{ row.item.data | moment("HH:mm") }}
          </template>
          <template #cell(endereco)="row">
            <div class="text-left" style="line-height: 12px">
              <small>
                {{ row.item.cliente.Nome }} <br />
                <small>
                  {{ row.item.cliente.Logradouro }}, {{ row.item.cliente.Numero
                  }}<br />
                  <span v-if="row.item.cliente.complemento">
                    {{ row.item.cliente.complemento }}
                  </span>
                  <span v-if="row.item.cliente.CEP">
                    | CEP {{ row.item.cliente.CEP }} <br />
                  </span>
                </small>
              </small>
            </div>
          </template>
        </b-table>
      </div>
      <div
        class="acoes"
        v-if="
          loadingEnderecos &&
          (!listaSelecionado || listaSelecionado.length <= 0)
        "
      >
        <b-spinner small /> atualizando mapa...
      </div>
      <div class="acoes" v-if="listaSelecionado && listaSelecionado.length > 0">
        <div class="titulo">
          Entregas Selecionadas {{ listaSelecionado.length }}
        </div>

        <div class="listCorrida">
          <b>Lista de Entregas</b>
          <div
            class="ent"
            v-for="entrega in listaSelecionado"
            :key="entrega.id"
          >
            <div># {{ listaSelecionado.indexOf(entrega) + 1 }}</div>
            <div>{{ entrega.cliente.Bairro }}</div>
          </div>
        </div>

        <div class="" v-if="loadingEnderecos" style="font-size: 10px">
          <b-spinner small /> atualizando mapa...
        </div>
        <div class="p-2">
          <Map :lista="listaSelecionado" />
        </div>

        <div class="pb-2">
          <entrega-moto-select
            v-if="!loadingSalvando"
            :empresa="empresa"
            :data="data"
            @motoSelecionada="selecionarMoto"
          />
          <div class="botoesAcao" v-if="!loadingSalvando">
            <b-button
              variant="primary"
              block
              @click="desvincular"
              v-if="tipo != 'pendentes'"
              ><i class="far fa-pause-circle" /> Desvincular</b-button
            >
            <b-button variant="primary" block @click="confirmar">
              <i class="fa fa-road" /> Em Rota</b-button
            >

            <b-button variant="primary" block @click="marcarConcluido">
              <i class="fa fa-map-marked" /> Concluído</b-button
            >
            <b-button variant="dark" block @click="cancelar">Cancelar</b-button>
          </div>
        </div>
        <div v-if="loadingSalvando">
          <b-spinner variant="primary" /><br />
          aguarde...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "../../components/Maps/Map.vue";
import moment from "moment";
import axios from "../../utils/axios";
import EntregaMotoSelect from "./EntregaMotoSelect.vue";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
export default {
  components: { Map, EntregaMotoSelect, EmpresaCombo },
  props: {
    tipo: {
      type: String,
      default: "",
    },
    data: {
      type: Date,
      default: new Date(),
    },
    empresa: {
      type: Object,
      default: null,
    },
    moto: {
      type: Object,
      default: null,
    },
  },
  sockets: {
    connect() {
      // console.log('conectado motos');
      this.carregar();
    },
    solicitaLista() {
      this.carregar();
    },
    listaEntregas(lista) {
      this.lista = lista.map((item) => {
        if (this.$props.tipo == "buscar") {
          return {
            ...item,
            _rowVariant:
              item.status == 2
                ? "warning"
                : item.status == 3
                ? "info"
                : "success",
          };
        } else {
          return item;
        }
      });
      this.loading = false;
      this.loadingSalvando = false;
    },
  },
  beforeDestory() {
    try {
      clearInterval(this.timer);
    } catch (e) {
      e;
    }
    this.timer = null;
  },
  beforeDestroy() {
    try {
      clearInterval(this.timer);
    } catch (e) {
      e;
    }
    this.timer = null;
  },
  destroyed() {
    try {
      clearInterval(this.timer);
    } catch (e) {
      e;
    }
    this.timer = null;
  },
  mounted() {
    this.empresaSel = this.$props.empresa;

    this.filtro = "";
    this.listaSelecionado = [];

    this.carregar();
    this.fields.find((x) => x.key == "moto").class =
      this.tipo != "pendentes" ? "" : "d-none";
    if (this.tipo == "buscar") this.$refs.txtBusca.focus();

    try {
      clearInterval(this.timer);
    } catch (e) {
      e;
    }
    this.timer = null;
    this.timer = setInterval(() => {
      this.carregar(false);
    }, 3000);
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.cancelar();
        this.carregar();
        this.fields.find((x) => x.key == "moto").class =
          this.tipo != "pendentes" ? "" : "d-none";

        this.filtro = "";
        if (this.tipo == "buscar") this.$refs.txtBusca.focus();
      },
    },
    "$props.empresa": function () {
      this.cancelar();

      this.empresaSel = this.$props.empresa;
    },
    listaSelecionado: function () {},
  },
  data() {
    return {
      loading: false,
      loadingSalvando: false,
      loadingEnderecos: false,
      lista: [],
      listaSelecionado: [],
      motoSelecionada: null,
      filtro: "",
      empresaSel: null,
      timer: null,
      fields: [
        { key: "selected", label: "" },
        { key: "moto", label: "Moto", class: "d-none" },
        { key: "senha", label: "Entrega #", sortable: true },
        { key: "data", label: "Hora", sortable: true },
        { key: "cliente.Bairro", label: "Bairro" },
        { key: "endereco", label: "Cliente / Endereço" },
        // { key: "cliente.Nome", class:'d-nonea' },
        // { key: "cliente.Logradouro", class:'d-nonea' },
        // { key: "cliente.Logradouro", class:'d-nonea' },
      ],
    };
  },
  methods: {
    async carregar(l = true) {
      if (this.listaSelecionado && this.listaSelecionado.length > 0) {
        return;
      }
      // console.log("carregando", this.empresaSel.cod_empresa);
      this.$socket.emit("entregas:joinRoom", {
        tipo: this.tipo,
        cod_empresa: this.empresaSel.cod_empresa,
        data: moment(this.data).format("YYYY-MM-DD"),
        moto: this.moto,
      });
      this.listaSelecionado = [];

      this.loading = l;

      this.$socket.emit("entregas:lista", {
        tipo: this.$props.tipo,
        data: moment(this.data).format("YYYY-MM-DD"),
        cod_empresa: this.empresaSel.cod_empresa,
        moto: this.moto,
      });
    },
    selectEmpresa(emp) {
      this.empresaSel = emp;
      this.cancelar();
      this.carregar();
    },
    selecionarMoto(moto) {
      this.motoSelecionada = moto;
    },
    marcarConcluido() {
      if (
        !this.motoSelecionada &&
        (this.tipo == "pendentes" ||
          (this.tipo == "buscar" &&
            this.tipo == "buscar" &&
            this.listaSelecionado.filter((x) => !x.cod_moto).length > 0))
      ) {
        this.$swal(
          "Atenção",
          "Selecione uma moto antes de alterar para 'EM ROTA'",
          "error"
        );
        return;
      }
      this.$swal({
        title: "Confirmação",
        text: `Deseja concluir as entregas selecionadas?`,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim!",
      }).then((r) => {
        if (r.isConfirmed) {
          this.loadingSalvando = true;
          this.$socket.emit("entregas:atribuirEntregas", {
            status: 4,
            lista: this.listaSelecionado,
            moto: this.motoSelecionada
              ? this.motoSelecionada
              : { cod_moto: -1 },
            tipo: this.$props.tipo,
            data: moment(this.data).format("YYYY-MM-DD"),
            cod_empresa: this.empresaSel.cod_empresa,
            cod_usuario: this.user.cod_usuario,
          });
        }
      });
    },
    desvincular() {
      this.$swal({
        title: "Confirmação",
        text: `Deseja desvincular as entregas selecionadas?`,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim!",
      }).then((r) => {
        if (r.isConfirmed) {
          this.loadingSalvando = true;
          this.$socket.emit("entregas:atribuirEntregas", {
            status: 2,
            lista: this.listaSelecionado,
            moto: { cod_moto: -2 },
            tipo: this.$props.tipo,
            data: moment(this.data).format("YYYY-MM-DD"),
            cod_empresa: this.empresaSel.cod_empresa,
            cod_usuario: this.user.cod_usuario,

          });
        }
      });
    },
    confirmar() {
      if (
        !this.motoSelecionada &&
        (this.tipo == "pendentes" ||
          (this.tipo == "buscar" &&
            this.listaSelecionado.filter((x) => !x.cod_moto).length > 0))
      ) {
        this.$swal(
          "Atenção",
          "Selecione uma moto antes de alterar para 'EM ROTA'",
          "error"
        );
        return;
      }
      this.$swal({
        title: "Confirmação",
        text: `Deseja prosseguir em atribuir as entregas para ${
          this.motoSelecionada ? this.motoSelecionada.nome : ""
        }?`,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim!",
      }).then((r) => {
        if (r.isConfirmed) {
          this.loadingSalvando = true;
          this.$socket.emit("entregas:atribuirEntregas", {
            status: 3,
            lista: this.listaSelecionado,
            moto: this.motoSelecionada
              ? this.motoSelecionada
              : { cod_moto: -1 },
            tipo: this.$props.tipo,
            data: moment(this.data).format("YYYY-MM-DD"),
            cod_empresa: this.empresaSel.cod_empresa,
            cod_usuario: this.user.cod_usuario,

          });
        }
      });
      // if(this.motoSelecionada==null){

      // }
    },
    cancelar() {
      this.$refs.tbEntregas.clearSelected();
      this.listaSelecionado = [];
      // this.motoSelecionada = null;
    },
    async onRowSelected(items) {
      if (this.moto) return;
      this.loadingEnderecos = true;
      this.$nextTick();
      for (let end of items) {
        if (!end.geoLocation && end.cliente) {
          let montaEntedero =
            `${
              end.cliente.Cidade && end.cliente.Cidade.length > 0
                ? end.cliente.Cidade
                : "Franca"
            } ` +
            `${
              end.cliente.UF && end.cliente.UF.length > 0
                ? end.cliente.UF
                : "São Paulo"
            } ` +
            `${
              end.cliente.Logradouro && end.cliente.Logradouro.length > 0
                ? end.cliente.Logradouro
                : end.cliente.Bairro && end.cliente.Bairro.length > 0
                ? end.cliente.Bairro
                : " "
            } `;

          try {
            axios.get(`/getLocation?q=${montaEntedero}`).then((res) => {
              end.geoLocation = res.data;
              this.$forceUpdate();
            });
            // end.geoLocation = (
            //   // await axios.get(`/getLocation?q=${montaEntedero}`)
            // ).data;
          } catch (e) {
            e;
          }
        }
      }
      this.listaSelecionado = items;
      this.loadingEnderecos = false;
    },
  },
};
</script>

<style></style>
