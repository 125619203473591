<template>
  <div class="topo d-block d-sm-none">
    <div class="conteudoMenuOpen" v-if="menuVisivel"></div>

    <div
      class="botaoMenu d-sm-none"
      :class="{
        menuOpen: menuVisivel,
        botaoMenuHover: $store.state.menuVisivel
      }"
      @click="toogleMenu()"
    >
      <i class="fas fa-bars" v-show="!menuVisivel"></i>
      <i class="fas fa-times" v-show="menuVisivel"></i>
    </div>
    <div class="logo d-none d-sm-block">
      <img
        v-if="!empresa || !empresa.logo"
        src="@/assets/restaurant_52px.png"
      />
      <img v-if="empresa && empresa.logo" :src="empresa.logo" />
      <!-- <div style="float:left;" v-if="!empresa">Restaurante</div>
      <div style="float:left;" v-if="empresa">{{empresa.nome}}</div>       -->
    </div>
    <!-- <div class="profile" v-show="!menuVisivel">
      <b-avatar style="width: 41px; float: left" />
      <div class="text">Bem Vindo {{ $store.state.auth.user.nome }}</div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuVisivel: this.$store.state.menuVisivel,
      empresa: this.$store.state.empresas
    };
  },
  watch: {
    "$store.state.menuVisivel": function() {
      this.menuVisivel = this.$store.state.menuVisivel;
    },
    "$store.state.empresas": function() {
      this.empresa = this.$store.state.empresas;
    }
  },
  methods: {
    toogleMenu() {
      this.$store.state.menuVisivel = !this.$store.state.menuVisivel;
    }
  },
  mounted() {
    // console.log(this.$store.state.empresas);
  }
};
</script>