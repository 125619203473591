<template>
  <div>
    <h4 v-if="title">Endereços</h4>
    <b-modal
      id="modal-endereco"
      header-bg-variant="transparent"
      bodyTextVariant=""
      fades
      body-bg-variant=""
      title=""
      size=""
      hide-footer
    >
      <template #modal-header>
        <h3><i class="fa fa-building"></i> Adicionar Endereço</h3>
      </template>
      <EnderecoForm
        ref="EnderecoForm"
        @salvar="addOrUpdateEndereco()"
        :enderecoEdit="enderecoEdit"
      />
    </b-modal>
    <!-- <Collapse :nomeComponente="'Endereços'" :iconeClasse="'fas fa-map-marked-alt text-danger'"> -->
    <EnderecoTable
      :enderecos="enderecos"
      @editarEndereco="editarEndereco($event)"
      @apagarEndereco="apagarEndereco($event)"
    />

    <div class="row">
      <div class="col-12">
        <b-button size="sm" variant="transparent" @click="novoEndereco()">
          <i class="fa fa-plus"></i> Adicionar
        </b-button>
      </div>
    </div>
    <!-- </Collapse> -->
  </div>
</template>

<script>
import EnderecoForm from "../../components/Enderecos/EnderecoForm";
import EnderecoTable from "../../components/Enderecos/EnderecoTable";
// import Collapse from "../common/Collapse";

export default {
  components: {
    EnderecoForm,
    EnderecoTable,
    // Collapse,
  },
  props: {
    enderecos: Array,
    title: {type:Boolean, default:false}
  },
  data() {
    return {
      enderecoEdit: {
        cep: "",
        cidade: "",
        uf: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
      },
    };
  },
  methods: {
    limpaEndereco() {
      for (let keys in this.enderecoEdit) {
        this.enderecoEdit[keys] = "";
      }
    },
    novoEndereco() {
      this.limpaEndereco();

      this.$bvModal.show("modal-endereco");
    },
    async editarEndereco(indexEndereco) {
      this.enderecoEdit = Object.assign(
        {},
        { ...this.enderecos[indexEndereco], index: indexEndereco }
      );
      await this.$nextTick();

      this.$bvModal.show("modal-endereco");
    },
    apagarEndereco(index) {
      this.$swal
        .fire({
          title: "Você tem creteza que deseja excluir este endereço?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não.",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (typeof index == "number") {
              this.enderecos.splice(index, 1);
              // console.log(this.enderecos);
               this.$swal.fire({
                title: "Endereço excluído com sucesso!",
                icon: "success",
                toast: true,
                timer: 1500,
                position: "top-end",
                showConfirmButton: false,
              });
            }
          }
        });
    },
    async addOrUpdateEndereco() {
      await this.$nextTick();

      if (this.enderecoEdit.index != null) {
        this.$set(this.enderecos, this.enderecoEdit.index, this.enderecoEdit);
        this.enderecoEdit = {};
      } else {
        this.enderecos.push(this.enderecoEdit);
      }

      this.$bvModal.hide("modal-endereco");
    },
  },
};
</script>

<style></style>
