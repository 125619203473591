
export default {
    formatValorReal: (vl) => {
        if (!vl || typeof vl == "undefined" || vl == null || vl == "") {
            vl = "0";
        }
        return parseFloat(
            vl
                .toString()
                .replace("R$", "")
                .replace(".", "")
                .replace(",", ".")
                .trim()
        );
    },
    formatFloatToReal: (vl, prefix = "R$ ") => {
        if (!vl || typeof vl == "undefined" || vl == null || vl == "") {
            vl = 0;
        }
        return (
            prefix +
            parseFloat(vl)
                .toFixed(2)
                .replace(",", "")
                .replace(".", ",")
        );
    }
};


 