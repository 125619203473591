<template>
  <div>
    <BarraCrud :showTitle="showTitle" :botaoNovo="true" nomeCustomizado="Fornecedores" :onNovo="novo" :onFiltrar="filtrar" />
    <FornecedoresTable ref="fornecedorTable" :filtro="filtro"  />
  </div>
</template>

<script>
import FornecedoresTable from "@/components/Fornecedores/FornecedoresTable.vue";
import BarraCrud from "@/components/common/BarraCrud";
export default {
  components: {
    BarraCrud,
    FornecedoresTable,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filtro: "",
    };
  },
  methods: {
    novo() {
      this.$refs.fornecedorTable.novo();
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
  },
};
</script>

<style></style>
