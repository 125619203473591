import axios from "@/utils/axios";
// import moment from 'moment';

const getCareteiras = async (filters) => {
    try {
        return (await axios.get('bancario/carteiras', { params: filters })).data;
    } catch (e) {
        console.log("ERRO AO BUCSAR CARTEIRAS", e);
        return null;
    }
}
const storeCarteiras = async (data) => {
    try {
        return (await axios.post('bancario/carteiras', data)).data;
    } catch (err) {
        console.log("ERRO AO STORE CARTEIRA", err);
    }
}

const delCarteiras = async (id) => {
    try {
        return (await axios.post('bancario/carteiras/delete', { id })).data;
    } catch (err) {
        console.log("ERRO AO STORE CARTEIRA", err);
    }
}


export default {
    getCareteiras,
    storeCarteiras,
    delCarteiras
}