var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.enableTitle)?_c('div',[_c('b-icon-upload'),_vm._v(" Anexar")],1):_vm._e(),_c('div',{staticClass:"files",class:{ freeStyle: _vm.freeStyle }},[_vm._l((_vm.lista),function(file){return _c('div',{key:`file_${_vm.lista.indexOf(file)}`,staticClass:"file",class:`file ${_vm.freeStyle ? 'freeStyleFile' : ''}`},[(file.status < 2)?_c('div',{staticClass:"p-4 text-center"},[_c('b-spinner'),_vm._v(" "),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(file.type == 'img' && file.status == 2 && file.fileURL)?_c('img',{staticStyle:{"height":"100%"},attrs:{"src":file.fileURL}}):_vm._e(),(
          file.type == 'doc' && file.name.includes('.pfx') && file.status == 2
        )?_c('i',{staticClass:"fa fa-file-contract"}):_vm._e(),(
          file.type == 'doc' &&
          !file.name.includes('.pfx') &&
          file.status == 2
        )?_c('i',{staticClass:"fa fa-file-pdf"}):_vm._e(),(file.isDefault)?_c('div',{staticClass:"default"},[_c('b-icon-check-circle-fill')],1):_vm._e(),_c('div',{staticClass:"opcs"},[(_vm.setDefault)?_c('div',{staticClass:"opc",on:{"click":function($event){return _vm.checkDefault(file)}}},[_c('b-icon-check-circle')],1):_vm._e(),_c('div',{staticClass:"opc",on:{"click":function($event){return _vm.view(file.fileURL)}}},[_c('b-icon-eye')],1),(_vm.enableEdit)?_c('div',{staticClass:"opc",on:{"click":function($event){return _vm.remove(file)}}},[_c('b-icon-trash-fill')],1):_vm._e()])])}),(_vm.enableEdit && (!_vm.lista || _vm.lista.length < _vm.maxFiles))?_c('div',{class:` ${_vm.freeStyle ? 'freeStyleFile' : ''} file plus`,on:{"click":_vm.openFile}},[(!_vm.photoIcon)?_c('b-icon-plus-circle'):_vm._e(),(_vm.photoIcon)?_c('b-icon-camera'):_vm._e()],1):_vm._e()],2),_c('input',{ref:_vm.refFile,staticClass:"fl",staticStyle:{"width":"0px","height":"0px","visibility":"hidden","diplay":"none"},attrs:{"type":"file","id":_vm.refFile},on:{"change":_vm.changeFile}}),_c('b-modal',{attrs:{"id":"modal-view","hide-footer":"","size":"lg"}},[_c('b-btn',{staticClass:"mb-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.girar(0)}}},[_c('b-icon-arrow-clockwise'),_vm._v(" Girar")],1),_c('img',{staticStyle:{"width":"100%"},style:(`transform:${_vm.rotateStyle}; height: calc(height+200)`),attrs:{"src":_vm.imgView}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }