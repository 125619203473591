import axios from "@/utils/axios";

const getFornecedores = async (filter, id) => {
  try {
    return (await axios.get("fornecedores/get", { params: { ...filter, id } }))
      .data.data;
  } catch {
    return null;
  }
};

const storeFornecedores = async (data) => {
  let result;
  await axios
    .post("/fornecedores/store", data)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      throw err.response;
    });

  return result;
};

export default {
  getFornecedores,
  storeFornecedores,
};
