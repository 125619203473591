import axios from "@/utils/axios";

const emitirNota = async (cod_pedido, cliente, mesa_controle_id) => {
  try {
    return (
      await axios.post("fiscal/emitir", {
        cod_pedido,
        cliente,
        mesa_controle_id,
      })
    ).data;
  } catch (e) {
    console.log("ERRO NO ");
  }
};

const emitirNotaLocal = async (cod_pedido, cliente, mesa_controle_id) => {
  try {
    return (
      await axios.post("fiscal/local", {
        cod_pedido,
        cliente,
        mesa_controle_id,
      })
    ).data;
  } catch (e) {
    console.log("ERRO NO ");
  }
};

const buscarNota = async (filters) => {
  try {
    console.log("fil", filters);
    return (await axios.post("fiscal/buscar", filters)).data;
  } catch (e) {
    console.log("ERRO NO buscarNota", e);
  }
};

const buscarNotaEmitidas = async (filters) => {
  try {
    console.log("fil", filters);
    return (await axios.post("fiscal/buscarEmitidas", filters)).data;
  } catch (e) {
    console.log("ERRO NO buscarNota", e);
  }
};

const desvincularVenda = async (id) => {
  try {
    // console.log("fil", filters)
    return (await axios.get("fiscal/desvincularVenda/" + id)).data;
  } catch (e) {
    console.log("ERRO NO desvincularVenda", e);
  }
};

const solicitarXML = async (obj) => {
  try {
    // console.log("fil", filters)
    return (await axios.post("fiscal/gerarXML/", obj)).data;
  } catch (e) {
    console.log("ERRO NO solicitarXML", e);
  }
};

const getDownloadXML = async (filters) => {
  try {
    // console.log("fil", filters)
    return (await axios.post("fiscal/getDownloadXML/", filters)).data;
  } catch (e) {
    console.log("ERRO NO solicitarXML", e);
  }
};

const baixarXML = async (id) => {
  const url = `${axios.defaults.baseURL.substring(
    0,
    axios.defaults.baseURL.length - 1
  )}/fiscal/baixarLoteXML/${id}/?token=${axios.defaults.headers["common"][
    "Authorization"
  ].replace("Bearer ", "")}`;
  console.log("URL", url);
  window.open(url);
};

const baixarXMLFile = async (id) => {
  const url = `${axios.defaults.baseURL.substring(
    0,
    axios.defaults.baseURL.length - 1
  )}/fiscal/downloadXMLFile/${id}/?token=${axios.defaults.headers["common"][
    "Authorization"
  ].replace("Bearer ", "")}`;
  console.log("URL", url);
  window.open(url);
};


const storeInutilizacao = async (obj) => {
  try {
    // console.log("fil", filters)
    return (await axios.post("fiscal/inutilizacao/store", obj)).data;
  } catch (e) {
    console.log("ERRO NO storeInutilizacao", e);
  }
};

const execIntuilizacao = async (obj) => {
  try {
    // console.log("fil", filters)
    return (await axios.post("fiscal/inutilizacao/nota", obj)).data;
  } catch (e) {
    console.log("ERRO NO execIntuilizacao", e);
  }
};


const getInutilizacao = async (obj) => {
  try {
    // console.log("fil", filters)
    return (await axios.post("fiscal/inutilizacao/get", obj)).data;
  } catch (e) {
    console.log("ERRO NO execIntuilizacao", e);
  }
};

export default {
  emitirNota,
  buscarNota,
  buscarNotaEmitidas,
  emitirNotaLocal,
  desvincularVenda,
  solicitarXML,
  baixarXML,
  getDownloadXML,
  baixarXMLFile,
  storeInutilizacao,
  execIntuilizacao,
  getInutilizacao

};
