import { render, staticRenderFns } from "./CardapioCombo.vue?vue&type=template&id=bff8792c&scoped=true"
import script from "./CardapioCombo.vue?vue&type=script&lang=js"
export * from "./CardapioCombo.vue?vue&type=script&lang=js"
import style0 from "./CardapioCombo.vue?vue&type=style&index=0&id=bff8792c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff8792c",
  null
  
)

export default component.exports