var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerSal"},[(_vm.layout)?_c('div',{staticClass:"salao"},_vm._l((_vm.layout),function(row){return _c('div',{key:`row_${row.id}_${Math.random()}`,staticClass:"fila",style:(`width:${Math.floor(Math.abs(100 / _vm.layout.length))}%`)},_vm._l((row.items),function(it){return _c('div',{key:`mesa_${row.index}_${it.cod_mesa}`,staticClass:"mesa d-flex flex-column justify-content-center",class:`${
          (_vm.somenteDisponiveis && it && it.obj && it.obj.id
            ? ' indisponivel '
            : '') +
          (_vm.mesaSelected(it.cod_mesa) ? ' selected ' : '') +
          (_vm.selectable ? ' selectable ' : '') +
          (_vm.VerificaMesaReunida(it) ? ' mesaJaUnida  ' : '') +
          (it.uniao
            ? 'mesaUnida '
            : _vm.VerificaMesaReunida(it)
            ? ' mesaReunida '
            : '')
        }`,on:{"click":function($event){return _vm.selectMesa(it)}}},[_vm._v(" "+_vm._s(it.title)+" "),(_vm.verificaReserva(it.cod_mesa))?_c('div',{staticClass:"reserva"},[_c('b-icon-calendar-check')],1):_vm._e()])}),0)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }