<template>
  <div>
    <div v-if="status == 'ok'" class="text-success">
      <i class="fa fa-check"></i> OK
    </div>
    <div v-if="status == 'loading'" class="">
      <b-spinner small /> buscando...
    </div>
    <div v-if="status == 'not'">
      <i class="fa fa-asterisk"></i> Novo
    </div>
    <div v-if="status == 'type'" class="text-">
      <i class="fa fa-keyboard"></i>
    </div>
    <div v-if="status == 'error'" class="text-danger">
      <i class="fa fa-times"></i> algo errado!
    </div>
  </div>
</template>

<script>
export default {
    props:{
        status
    },

};
</script>

<style>
</style>