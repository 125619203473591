<template>
  <div class="row">
    <div class="col-12 pb-0 mb-0">
      <div class="pb-0" :class="{ scrollBorda:!mesa || mesa.id <= 0 }">
        <label>Borda <i class="fa fa-arrows-alt-h" /></label>
        <b-form-group
          v-slot="{ ariaDescribedby }"
          class="pb-0"
          style="margin: 0px"
        >
          <b-form-radio-group
            id="btn-radios-5"
            v-model="pedido.borda"
            :options="bordas"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-secondary"
            @input="verificarStatus"
            
            name="radio-btn-outline"
            :buttons="true || !mesa || mesa.id <= 0"
            :inline="!isMobile && (!mesa || mesa.id <= 0)"
            :style="
              !mesa || mesa.id <= 0
                ? `min-width: 895px;
              height: 50px;
              margin: 0px;
              font-size: 11px !important;`
                : ''
            "
            :stacked="isMobile && mesa && mesa.id >= 0"
            ref="cmbBordas"
            class="pb-0"
          />
        </b-form-group>
      </div>
    </div>
    <!-- <div class="col-12 p-0">
      <div v-if="mesa && mesa.id > 0">
        <div class="OpcsSabor">
          <div
            @click="
              () => {
                openChooseSabor(1);
              }
            "
          >
            Selecionar Sabor
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-12 col-sm-6 scrollPizzaOPC pt-0 mt-2">
      <hr />
      <table class="tbPizza" style="height: 140px">
        <tr v-if="!isMobile">
          <td
            rowspan="3"
            style="vertical-align: middle; padding: 0px; "
          >
            <img
              
              v-if="pedido.itens && pedido.itens.length <= 1"
              src="@/assets/img/icones/pizza_five_eighths_96px1_sabor.fw.png"
            />
            <img
              
              v-if="pedido.itens && pedido.itens.length > 1"
              src="@/assets/img/icones/pizza_five_eighths_96px2_sabor.fw.png"
            />
          </td>
        </tr> 
        <tr v-if="isMobile && pedido && pedido.itens && pedido.itens.length <= 0">
          <td class="">
            <b-btn block size="lg" @click="openChooseSabor(1)"
              >Selecionar Sabor
            </b-btn>
          </td>
        </tr>
        <tr v-if="pedido && pedido.itens && pedido.itens.length > 0">
          <td style="position: relative">
            <label>Sabor <span v-if="pedido.itens.length > 1"> 1 </span></label>
            <small>{{ pedido.itens[0].nome }}</small>
            <div
              class="alerta-ing"
              v-if="
                pedido &&
                pedido.itens &&
                pedido.itens.length > 0 &&
                ingredientesAlterados(pedido.itens[0].ingredientes).length > 0
              "
            >
              ALTERADO
            </div>
            <div
              v-for="ing in ingredientesAlterados(pedido.itens[0].ingredientes)"
              :key="`ing_alt${ing.cod_ingrediente}_1`"
              class="item-ingrediente-alterado-sabor"
            >
              [{{ ing.op }}] {{ ing.ingrediente }}
            </div>
          </td>
          <!-- <td
            class="btRemoveSabor text-secondary"
            @click="openChooseSabor(1)"
          >
            <i class="fa fa-recycle" />
            <span>Alterar</span>
          </td> -->
          <td
            class="btRemoveSabor text-secondary"
            @click="editarProduto(pedido.itens[0])"
          >
            <i class="fa fa-cheese" />
            <span>Ingredientes</span>
          </td>
          <td class="btRemoveSabor" @click="remover(pedido.itens[0])">
            <i class="fa fa-trash" />
            <span>Remover</span>
          </td>
        </tr>
        <tr v-if="isMobile && pedido && pedido.itens && pedido.itens.length == 1">
          <td class="" colspan="999">
            <b-btn block size="lg" @click="openChooseSabor(1)"
              >Adicionar Sabor
            </b-btn>
          </td>
        </tr>
        <tr
          v-if="
            pedido &&
            pedido.itens &&
            pedido.itens.length > 1 &&
            pedido.adicionais
          "
          style="border-top: 2px dotted #ccc"
        >
          <td>
            <label>Sabor <span v-if="pedido.itens.length > 1"> 2 </span></label>
            <small>{{ pedido.itens[1].nome }}</small>
            <div
              class="alerta-ing"
              v-if="
                ingredientesAlterados(pedido.itens[1].ingredientes).length > 0
              "
            >
              ALTERADO
            </div>
            <div
              v-for="ing in ingredientesAlterados(pedido.itens[1].ingredientes)"
              :key="`ing_alt${ing.cod_ingrediente}_2`"
              class="item-ingrediente-alterado-sabor"
            >
              [{{ ing.op }}] {{ ing.ingrediente }}
            </div>
          </td>
          <!-- <td
            class="btRemoveSabor text-secondary"
            @click="openChooseSabor(1)"
          >
            <i class="fa fa-recycle" />
            <span>Alterar</span>
          </td> -->
          <td
            class="btRemoveSabor text-secondary"
            @click="editarProduto(pedido.itens[1])"
          >
            <i class="fa fa-cheese" />
            <span>Ingredientes</span>
          </td>
          <td class="btRemoveSabor" @click="remover(pedido.itens[1])">
            <i class="fa fa-trash" />
            <span>Remover</span>
          </td>
        </tr>
      </table>
      <b-btn block size="lg" v-if="isMobile && pedido && pedido.itens && pedido.itens.length>1" @click="openChooseSabor(1)"
        >Alterar Sabor
      </b-btn>
      <hr />
      <div
        class="alerta-ing"
        v-if="
          pedido &&
          pedido.itens &&
          pedido.itens.length > 0 &&
          pedido.adicionais &&
          ingredientesAlterados(pedido.adicionais.filter((x) => x.sabor == 0))
            .length > 0
        "
      >
        INGREDIENTES PIZZA TODA
      </div>
      <div v-if="pedido.adicionais">
        <div
          v-for="ing in ingredientesAlterados(
            pedido.adicionais.filter((x) => x.sabor == 0)
          )"
          :key="`ing_alt${ing.cod_ingrediente}_2`"
          class="item-ingrediente-alterado-sabor"
        >
          [{{ ing.op }}] {{ ing.ingrediente }}
          <span class="float-right text-right" v-if="ing.preco_venda > 0"
            >R$ {{ ing.preco_venda | currency }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <h6 v-if="!$store.state.isMobile">Adicionais</h6>
      <button
        class="btn btn-primary btn-block"
        @click="showAdds = !showAdds"
        v-if="$store.state.isMobile"
      >
        <i class="fa fa-plus" v-if="!showAdds" />
        <i class="fa fa-sort-up" v-if="showAdds" />

        Adicionais
      </button>
      <div
        class="listaAdicionais"
        v-show="showAdds"
        v-if="pedido && pedido.itens"
      >
        <table class="table">
          <thead>
            <tr>
              <th>Ingrediente</th>
              <th v-if="pedido.itens.length > 1">
                Sabor <span v-if="pedido.itens.length > 1"> 1 </span>
              </th>
              <th v-if="pedido.itens.length > 1">
                Sabor <span v-if="pedido.itens.length > 1"> 2 </span>
              </th>
              <th>Inteira</th>
            </tr>
          </thead>
          <tbody v-if="adicionais">
            <tr v-for="ing in adicionais" :key="`ing_${ing.cod_ingrediente}`">
              <td>
                {{
                  `${ing.ingrediente} ${
                    ing.preco_venda > 0 ? "+R$ " + ing.preco_venda : "[grátis]"
                  }`
                }}
              </td>
              <td v-if="pedido.itens.length > 1">
                <b-form-checkbox
                  switch
                  @input="verificarStatus(ing, ing.ingrediente)"
                  :value="1"
                  v-model="ing.sabor"
                />
              </td>

              <td v-if="pedido.itens.length > 1">
                <b-form-checkbox
                  switch
                  @input="verificarStatus(ing, ing.ingrediente)"
                  :value="2"
                  v-model="ing.sabor"
                />
              </td>
              <td>
                <b-form-checkbox
                  @input="verificarStatus(ing, ing.ingrediente)"
                  switch
                  :value="0"
                  v-model="ing.sabor"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      :id="`modal-editar-pizza${idMdl}`"
      title="Editar Ingredientes"
      hide-footer
    >
      <div v-if="prodEdit">
        <h5 class="text-left">
          <i class="fa fa-pizza-slice" /> {{ prodEdit.nome }}
        </h5>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Ingrediente</th>
              <th>Incluso</th>
              <th>+R$</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ing in prodEdit.ingredientes"
              :key="`ing_${ing.cod_ingrediente}`"
              :class="{ 'bg-danger opaco': ing.op == '-' }"
              @click="
                (evt) => {
                  evt.preventDefault();
                  changeIngrediente(ing.op == '-' ? '+' : '-', ing);
                }
              "
              style="cursor: pointer"
            >
              <td>
                {{ `${ing.ingrediente}` }}
              </td>
              <td>
                <b-form-checkbox
                  switch
                  value="+"
                  unchecked-value="-"
                  v-model="ing.op"
                  @input="(evt) => changeIngrediente(evt, ing)"
                  @change="$forceUpdate()"
                />
              </td>
              <td>
                <span v-if="ing.preco_venda > 0">{{
                  ing.preco_venda | currency
                }}</span>
                <span v-if="ing.preco_venda <= 0">[grátis]</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-button
        block
        variant="secondary"
        @click="$bvModal.hide(`modal-editar-pizza${idMdl}`)"
      >
        Pronto!
      </b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    pedido: Object,
    atualizaAdicionais: Function,
    adicionais: Array,
    item: Object,
    mesa: Object,
    openChooseSabor: Function,
  },
  data() {
    return {
      idMdl: Math.random(),
      loaded: false,
      bordas: this.$store.state.ingredientes
        .filter((x) => x.grupo == "BORDAS")
        .map((item) => {
          return {
            value: item,
            text:
              item.ingrediente +
              (item.preco_venda > 0
                ? "  +R$ " + item.preco_venda
                : " [grátis]"),
          };
        }),
      prodEdit: null,
      showAdds: !this.$store.state.isMobile,
      isMobile: this.$store.state.isMobile,
    };
  },
  watch: {
    adicionais: {
      deep: true,
      handler() {
        //if (this.loaded) {
        // console.log(1111111111111111111);
        this.fillAdicionais();
        if (this.atualizaAdicionais) {
          this.atualizaAdicionais(this.adicionais);
        }
        //}
      },
    },
    "$store.state.isMobile": function () {
      this.showAdds = this.$store.state.isMobile;
    },
    "$props.item": {
      deep: true,
      handler() {
        // console.log("autalizadoooooooooooooooooooooo", this.pedido, this.item);
        //this.fillAdicionais();
        // this.adicionais.map((item) => {
        //   let pt = this.item.adicionais.find(
        //     (x) => x.cod_ingrediente == item.cod_ingrediente
        //   );
        //   if (pt) {
        //     item.sabor = 0;
        //   }
        // });
        this.$forceUpdate();
        this.$nextTick();
      },
    },
  },
  mounted() {
    this.bordas = [
      { value: { cod_ingrediente: -1 }, text: "NENHUMA" },
      ...this.bordas,
    ];

    if (this.pedido && this.pedido.adicionais) {
      // this.adicionais.map((item) => {
      //   const t = this.pedido.adicionais.find(
      //     (x) => x.cod_ingrediente == item.cod_ingrediente
      //   );
      //   if (t) {
      //     item.sabor = t.sabor;
      //   } else {
      //     item.sabor = null;
      //   }
      // });
      // console.log("ads", this.adicionais, this.pedido.adicionais);
      this.loaded = true;

      this.$nextTick(() => {
        // this.fillAdicionais();
        this.$forceUpdate();
      });

      if (!this.adicionais || this.adicionais.lenght <= 0) {
        this.adicionais = Object.assign(
          [],
          this.$store.state.ingredientes
            .filter((x) => x.grupo == "ADICIONAIS")
            .map((item) => {
              return item;
            })
        );
      }
      // console.log("ads", this.adicionais);

      // this.atualizaAdicionais(this.adicionais);
    }
  },
  methods: {
    setBordaRef() {
      return this.$refs;
    },
    ingredientesAlterados(ingredientes) {
      if (!ingredientes) return [];

      const its = ingredientes.filter(
        (x) => x.op && (x.op == "-" || (x.op == "+" && x.add))
      );
      return its;
    },
    changeIngrediente(evt, ing) {
      if (!this.adicionais) {
        return;
      }
      ing.op = evt;

      if (evt == "-") {
        const addc = this.adicionais.find(
          (x) => x.cod_ingrediente == ing.cod_ingrediente
        );
        if (addc) {
          addc.sabor = false;
        }
      }
      this.$forceUpdate();
    },
    editarProduto(item) {
      // console.log("EDIT ENTER", item);
      this.prodEdit = item;
      this.$bvModal.show(`modal-editar-pizza${this.idMdl}`);
      // this.atualizaAdicionais(this.adicionais);
    },
    verificarStatus({ status }, nome = "") {
      status, nome;
      // console.log(status);
      // let inativos = status.filter((stts) => !stts.status);
      // if (inativos.length > 0) {
      //   this.$swal({
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: true,
      //     confirmButton: "OK",
      //     title: `Atenção, ${nome?nome:'produto'} está esgotado(a) em algumas empresas: `,
      //     html: `<b>${inativos.map(ina=>ina.empresa).toString()}</b>`,
      //     icon:'warning'
      //   });
      // }
    },
    fillAdicionais() {
      // console.log("fill");
      const addsS1 = this.adicionais.filter((x) => x.sabor == 1);
      const addsS2 = this.adicionais.filter((x) => x.sabor == 2);
      if (this.pedido.itens.length >= 1) {
        this.pedido.itens[0].ingredientes =
          this.pedido.itens[0].ingredientes.filter((x) => x.preco_venda <= 0);
        addsS1.map((itad) => {
          if (
            this.pedido.itens[0].ingredientes.filter(
              (x) => x.cod_ingrediente == itad.cod_ingrediente
            ).length <= 0
          ) {
            itad.op = "+";
            itad.add = true;
            this.pedido.itens[0].ingredientes.push(itad);
          }
        });

        if (this.pedido.itens.length > 1) {
          this.pedido.itens[1].ingredientes =
            this.pedido.itens[1].ingredientes.filter((x) => x.preco_venda <= 0);

          addsS2.map((itad) => {
            if (
              this.pedido.itens[1].ingredientes.filter(
                (x) => x.cod_ingrediente == itad.cod_ingrediente
              ).length <= 0
            ) {
              itad.op = "+";
              itad.add = true;
              this.pedido.itens[1].ingredientes.push(itad);
            }
          });
        }
      }
      this.pedido.adicionais = this.adicionais.filter(
        (x) => x.sabor !== false && x.sabor >= 0
      );
      this.pedido.adicionais
        .filter((x) => typeof x.op === "undefined" || !x.op)
        .map((it) => {
          it.op = "+";
          it.add = true;
        });
    },
    remover(item) {
      this.pedido.itens = this.pedido.itens.filter((x) => x != item);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/cardapio.scss"
</style>
<style scoped></style>
