<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>CEP</th>
                <th>Cidade</th>
                <th>UF</th>
                <th>Logradouro</th>
                <th>N°</th>
                <th>Bairro</th>
                <th>Complemento</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="end in enderecos"
                :key="`endereco_${enderecos.indexOf(end)}`"
              >
                <td class="d-block text-truncate">{{ end.cep }}</td>
                <td>{{ end.cidade }}</td>
                <td>{{ end.uf }}</td>
                <td>{{ end.logradouro }}</td>
                <td>{{ end.numero }}</td>
                <td>{{ end.bairro }}</td>
                <td>{{ end.complemento }}</td>
                <td>
                  <b-button
                    size="sm"
                    variant="primary"
                    class="mt-1 mb-1"
                    @click="$emit('editarEndereco', enderecos.indexOf(end))"
                    ><i class="fa fa-edit"></i> Editar</b-button
                  >

                  <b-button
                    size="sm"
                    variant="danger"
                    class="mt-1 mb-1"
                    @click="$emit('apagarEndereco', enderecos.indexOf(end))"
                    ><i class="fa fa-trash"></i> Excluir</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import EnderecoForm from './EnderecoForm'
export default {
  props: {
    enderecos: {
      Type: Array,
      required: true,
    },
  },
 
};
</script>

<style></style>
