<template>
  <div>
    <div class="row">
      <div
        class="col"
        v-if="!hideEmpresa"
      >
        <b-form-group :disabled="disabled">
          <label> <i class="fas fa-store" /> Empresa</label>

          <v-select
            :options="empresas"
            label="nome"
            :reduce="(emp) => emp.cod_empresa"
            :value="empresa"
            @input="handleInputEmpresa"
            :loading="loadingEmpresa"
            :disabled="disabled"
          >
            <template #search="{ attributes, events }">
              <input
                :class="['vs__search rounded', empresaState ? 'isInvalid' : '']"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nenhum resultado para <em>{{ search }}</em
                >.
              </template>
              <em
                style="opacity: 0.5"
                v-else
                >Não encontramos nenhuma empresa 😕.</em
              >
            </template>
          </v-select>
        </b-form-group>
      </div>
      <div
        class="col"
        v-if="!hideCaixa"
      >
        <b-form-group :disabled="disabled">
          <label>Caixa</label>

          <v-select
            :options="caixas"
            label="nome"
            :value="caixa"
            :reduce="(cxa) => cxa.cod_caixa"
            @input="handleInputCaixa"
            :loading="loadingCaixa"
            :disabled="disabled"
          >
            <template #search="{ attributes, events }">
              <input
                :class="['vs__search rounded', clinicaState ? 'isInvalid' : '']"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nenhum resultado para <em>{{ search }}</em
                >.
              </template>
              <em
                style="opacity: 0.5"
                v-else
                >Nenhum caixa para a empresa selecionada 😕.</em
              >
            </template>
          </v-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import Caixa from "@/libs/Caixas";
import EmpresasLib from "@/libs/EmpresasLib";
export default {
  props: {
    caixa: Number,
    empresa: Number,
    empresaState: { type: Boolean, deafult: null },
    clinicaState: { type: Boolean, deafult: null },
    hideEmpresa: { type: Boolean, deafult: false },
    hideCaixa: { type: Boolean, deafult: false },
    disabled: { type: Boolean, deafult: false },
  },
  data() {
    return {
      empresas: [],
      caixas: [],
      loadingCaixa: false,
      loadingEmpresa: false,
    };
  },
  methods: {
    async handleInputEmpresa(empresa) {
      console.log("handleInputEmpresa", empresa)
      this.$emit("empresa", empresa);
      this.$emit("caixa", null);
      this.empresa = empresa;
      if (!this.hideCaixa) {
        await this.carregaCaixas();
      }
    },
    handleInputCaixa(caixa) {
      this.$emit("caixa", caixa);
    },
    async carregaCaixas() {
      this.$forceUpdate();
      await this.$nextTick();
      this.loadingCaixa = true;
      this.caixas = [];
      if (this.empresa) {
        console.log("carregaCaixas", this.empresa)
        this.caixas = await Caixa.getAll(this.empresa);
      }
      this.loadingCaixa = false;
    },
    async carregaEmpresas() {
      this.loadingEmpresa = true;
      this.empresas = await EmpresasLib.getResumo();
      this.loadingEmpresa = false;
    },
  },
  async mounted() {
    await this.carregaEmpresas();
    if (!this.hideCaixa) {
      await this.carregaCaixas();
    }
  },
};
</script>

<style></style>
