<template>
  <div>
    <!-- {{fornecedor}} -->
    <b-form @submit.stop.prevent="salvar()">
      <div class="row">
        <div class="col-12 col-sm-6">
          <b-form-group>
            <label>Razão Social</label>
            <b-input
              type="text"
              v-model="$v.fornecedor.razao_social.$model"
              placeholder="Razão Social"
              :state="validateState('razao_social')"
            />
            <b-form-invalid-feedback>
              Informe uma razão social válida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12 col-sm-6">
          <b-form-group>
            <label>Fantasia</label>
            <b-input
              type="text"
              v-model="fornecedor.fantasia"
              placeholder="Fantasia"
            />
            <b-form-invalid-feedback>
              Informe uma fantasia válida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>CNPJ</label>
            <b-input
              type="text"
              ref="cnpjInput"
              v-model="$v.fornecedor.cnpj.$model"
              v-mask="'##.###.###/####-##'"
              placeholder="CNPJ"
              :state="validateState('cnpj')"
            />
            <b-form-invalid-feedback>
              Informe uma CNPJ válido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label>Telefone</label>
            <b-input
              type="text"
              ref="telefoneInput"
              v-model="$v.fornecedor.telefone.$model"
              v-mask="['(##)#####-####', '(##)####-####']"
              placeholder="Telefone"
              :state="validateState('telefone')"
            />
            <b-form-invalid-feedback>
              Informe uma CNPJ válido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label>E-mail</label>
            <b-input
              type="text"
              v-model="fornecedor.email"
              placeholder="E-mail"
            ></b-input>
            <b-form-invalid-feedback>
              Informe um e-mail válido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <endereco :enderecos="fornecedor.endereco"  title />

      <div class="bottom-actions">
        <hr class="mb-2" />
        <b-btn variant="primary" class="mr-3" @click="salvar()">
          <i class="fas fa-save" v-if="!loading" />
          <b-spinner small v-if="loading" /> Salvar
        </b-btn>
        <b-btn variant="danger" @click="fecharModal()">
          <i class="fas fa-ban" v-if="!loading" />
          <b-spinner small v-if="loading" /> Cancelar
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import Endereco from "../Enderecos/Endereco.vue";
import { validationMixin } from "vuelidate";
import {
  required,
  // minLength,
  // maxValue,
  // minValue,
} from "vuelidate/lib/validators";
import FornecedoresLib from '../../libs/FornecedoresLib';
export default {
  components: {
    Endereco,
  },
  mixins: [validationMixin],
  props: {
    id: [String, Number],
  },
  data() {
    return {
      fornecedor: {
        razao_social: "",
        fantasia: "",

        cnpj: "",
        endereco: [],
        telefone: "",
        email: "",
      },
      loading: false,
    };
  },
  validations: {
    fornecedor: {
      razao_social: { required },

      cnpj: {
        // required,
        // minLength: minLength(18),
      },
      endereco: {
        // minLength: minLength(1),
      },
      telefone: {
        // required,
      },
    },
  },
  async mounted(){
    if (this.id) {
      await this.carregar()
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.fornecedor[name];

      return $dirty ? !$error : null;
    },
    async carregar(){
      this.fornecedor = (await FornecedoresLib.getFornecedores(null, this.id))[0]
    },
    async salvar() {
      this.$v.fornecedor.$touch();
      this.hasErrors = false;
      // console.log("validou?", this.$v.fornecedor.$params);
      if (this.$v.fornecedor.$anyError) {
        // this.showErrorSave(this.hasErrors[0])
        for (const key in this.fornecedor.configuracoes) {
          if (this.fornecedor.configuracoes[key] === "") {
            this.showErrorSave("Configurações inválidas!");
            this.hasErrors = true;
            break;
          } else {
            this.showErrorSave("Informações inválidas!");
          }
        }
        return;
      }
      await this.$nextTick();
      try {
        this.loading = true;
       
        const res = await FornecedoresLib.storeFornecedores(this.fornecedor);
        // console.log(res);
        if (res.success) {
          this.$swal.fire({
            title: "Concluído!",
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });

        this.$emit('close')

          this.$emit("salvou");
        }
      } catch (e) {
        this.showErrorSave(e);
      } finally {
        this.loading = false;
      }
    },
    fecharModal() {
      this.$emit("close");
    },
    showErrorSave(error = "") {
      this.$swal.fire({
        title: `Erro ao salvar! \n${error}`,
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
  },
};
</script>

<style></style>
