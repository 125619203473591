import axios from "@/utils/axios";

const getAllClientes = async (filters) => {
  let result = null;
  try {
    result = (await axios.post("/clientes/getAll", filters)).data;
  } catch (error) {
    console.log("error on getAllClientes", error);
  }
  return result;
};

const buscaPorTelefone = async (telefone) => {
  let result = null;
  try {
    result = (
      await axios.post("/clientes/buscarPorTel", {
        telefone: telefone
          .trim()
          .replace(" ", "")
          .replace("-", "")
          .match(/\d+/g)
          .join(""),
      })
    ).data;
  } catch (error) {
    console.log("error on buscaPorTelefone", error);
  }
  return result;
};

const salvarCliente = async(data)=>{
  try {
    return (await axios.post("/clientes/store", data)).data;
  }
  catch(err){
    console.log("error on salvarCliente", err);
  }
}

export default {
  getAllClientes,
  buscaPorTelefone,
  salvarCliente
};
