
const buscarCEP = async(cep)=>{
    const axios = require('axios');
    const result = await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
    if(result && result.data){
        return result.data;
    }else{
        return null;
    }
}

const buscarUFs = async()=>{
    const axios = require('axios');
    const result = await axios.get(`https://brasilapi.com.br/api/ibge/uf/v1`);
    if(result && result.data){
        return result.data;
    }else{
        return null;
    }
}


module.exports = {
    buscarCEP,
    buscarUFs
}