import axios from "../utils/axios";


const storeSolicitacao = async (data) => {
    try {
        return (await (axios.post("/solicitacoes/store", data))).data;
    } catch (err) {
        console.log("Erro ao criar solicitacao permissao", err);
        return null;
    }
}

const buscarSolicitacao = async (id) => {
    try {
        return (await axios.get(`/solicitacoes/${id}`));
    } catch (err){
        console.log('erro ao buscar solic', err);
        return null
    }
}
const deleteSolicitacao = async (id) => {
    try {
        return (await axios.delete(`/solicitacoes/${id}`));
    } catch (err) {
        console.log('erro ao delete solic', err);
        return null
    }
}

const updateSolicitacao = async (data) => {
    try {
        return (await axios.put(`/solicitacoes/update`, data));
    } catch (err) {
        console.log('erro ao delete solic', err);
        return null
    }
}





export default {
    storeSolicitacao,
    buscarSolicitacao,
    deleteSolicitacao,
    updateSolicitacao
    
}