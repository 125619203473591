<template>
  <div class="motos">
    <div class="row m-0">
      <div class="col-12 resumos">
        <div class="boxResumo">
          <h6><i class="fa fa-ban" /> Inativos</h6>
          <h1>{{ motosInativas.length }}</h1>
        </div>
        <div class="boxResumo">
          <h6><i class="fa fa-briefcase" /> Trabalhando</h6>
          <h1>{{ motosTrabalhando.length }}</h1>
        </div>
        <div class="boxResumo">
          <h6><i class="fa fa-road" /> Em Rota</h6>
          <h1>{{ motosEmRota.length }}</h1>
        </div>
        <div class="boxResumo">
          <h6><i class="fa fa-hand-holding" /> Aguardando</h6>
          <h1>{{ motosConcluidas.length }}</h1>
        </div>
           <div class="boxResumo">
          <h6><i class="fa fa-motorcycle" /> Trabalhou</h6>
          <h1>{{ motosTrabalhou.length }}</h1>
        </div>
      </div>
      <div class="col-12 listaMotos">
        <div
          class="moto"
          :class="{
            inativo: !moto.cod_entrada,
            ativo: moto.cod_entrada,
            emRota: moto.cod_entrada && moto.emRota && moto.emRota > 0,
            aguardando: (moto.cod_entrada && !moto.emRota) || moto.emRota <= 0
          }"
          v-for="moto in controles"
          :key="moto.cod_moto"
          @click="openMotoControle(moto)"
        >
          <img
            src="@/assets/img/icones/menu/icons8_motorbike_helmet_40px_1.png"
          />
          <div>{{ moto.info && moto.info.nome ? moto.info.nome: moto.nome }}</div>
          <div>
            <small>{{ moto.info && moto.info.nome ? moto.nome : "" }}</small>
          </div>
          <div>
            <small>{{ !moto.cod_entrada ? "Inativo" : "Ativo" }}</small>
          </div>
          <div class="entrega">
            <div><i class="fa fa-route" /> {{ moto.emRota }}</div>
            <div><i class="fa fa-check" /> {{ moto.concluidas }}</div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-moto"
      :title="`Controle ${motoSelecionada ? motoSelecionada.nome : ''}`"
      hide-footer
      size="lg"
    >
      <entrega-moto-controle
        v-if="motoSelecionada"
        :moto="motoSelecionada"
        :data="data"
        :empresa="empresa"
        
        @closeModalOpenMotos="closeModalOpenMotos"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import EntregaMotoControle from "./EntregaMotoControle.vue";
export default {
  components: {
    EntregaMotoControle
  },
  props: {
    empresa: {
      type: Object,
      default: null
    },
    data: {
      type: Date,
      default: new Date()
    }
  },
  sockets: {
    connect() {
      // console.log('conectado motos');
      this.getControles();
    },
    motoControle(result) {
      this.controles = result;
      console.log('const',this.controles);
    },
    solicitaMotoControle() {
      this.getControles();
    }
  },
  mounted() {
    // console.log("sock", this.$socket)
    this.getControles();
  },
  data() {
    return {
      controles: [],
      motoSelecionada: null
    };
  },
  watch: {
    data: function() {
      this.getControles();
    },
    empresa: function() {
      this.getControles();
    }
  },
  computed: {
    motosInativas: function() {
      return this.controles.filter(x => !x.cod_entrada);
    },
    motosTrabalhando: function() {
      return this.controles.filter(x => x.cod_entrada != null);
    },
    motosEmRota: function() {
      return this.controles.filter(x => x.emRota && x.emRota > 0);
    },
    motosConcluidas: function() {
      return this.controles.filter(x => x.cod_entrada && (!x.emRota || x.emRota <=0 ));
    },
    motosTrabalhou: function(){
      return this.controles.filter(x => x.concluidas && x.concluidas > 0);
    }
  },
  methods: {
    openMotoControle(moto) {
      this.motoSelecionada = moto;
      this.$bvModal.show("modal-moto");
    },
    closeModalOpenMotos() {
      this.$bvModal.hide("modal-moto");
      this.motoSelecionada = null;
      setTimeout(() => {
        this.getControles();
      }, 2000);
    },
    getControles() {
      console.log('send',"entregas:motoControle",{empresa:this.empresa})
      this.$socket.emit("entregas:motoControle", {
        data: moment(this.data).add((moment(this.data).format("HH")<0?-1:0),'d').format("YYYY-MM-DD"),
        cod_empresa: this.empresa.cod_empresa
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/entregas.scss";
</style>