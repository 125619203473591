import axios from "@/utils/axios";

const get = async (cod_custo="", cod_empresa="") => {
 try {
  const result = (await axios.get("/centros_custo/get", {params:{cod_custo, cod_empresa}})).data;
  // console.log(result);
  return result;
 } catch (error) {
   console.warn(error);
 }
};


const store = async (data) => {
  let result;
  await axios
    .post("/centros_custo/store", data)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
     throw err.response
    });

  return result;
};

export default {
  get,
  store,
};
