import { Pie, mixins } from 'vue-chartjs'

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],

  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    if (this.$props.chartData)
      this.renderChart(this.$props.chartData, this.$props.options)

  },
  watch: {
    "$props.chartData": function () {
      if (this.$props.chartData) {
        this.$data._chart.update();
        this.renderChart(this.$props.chartData, this.$props.options)
      }

    },
  },
}