<template>
  <div>
    <div class="text-center py-4 px-4" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <h3>
        Log de Alterações do Pedido {{ pedido.senha }} -
        {{ pedido.data | moment("DD/MM/YYYY") }} - {{ pedido.tipo_entrega }}
      </h3>
      <hr />
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Data</th>
            <th>Usuário</th>
            <th>Alteração</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in log">
            <tr
              style="cursor: pointer"
              @click="item.showDetail = !item.showDetail"
              :key="'log1' + item.id"
            >
              <td>{{ item.created_at | moment("DD/MM/YYYY HH:mm") }}</td>
              <td>{{ item.usuario }}</td>
              <td>{{ item.log && item.log.action ? item.log.action : "" }}</td>
              <td style="font-size:20px">
                <b-icon-caret-down v-if="!item.showDetail"></b-icon-caret-down>
                <b-icon-x-circle v-if="item.showDetail"></b-icon-x-circle>
              </td>
            </tr>
            <tr :key="'log12' + item.id">
              <td colspan="2000" v-show="item.showDetail" class="p-4">
                <b>Lista de Valores Alterados</b>
                <table class="table table-sm" style="font-size: 11px">
                  <thead>
                    <tr>
                      <th>Campo</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="key in Object.keys(item.log)"
                      :key="'log21' + key + item.id"
                    >
                      <td>{{ key }}</td>
                      <td>{{ item.log[key] }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PedidoLib from "@/libs/Pedido.js";
export default {
  components: {},
  props: {
    pedido: {
      type: Object,
    },
  },
  mounted() {
    this.loadLog();
  },
  data() {
    return {
      loading: false,
      log: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async loadLog() {
      this.loading = true;
      try {
        const response = await PedidoLib.logPedido(this.pedido);
        console.log("response", response);
        this.log = response.map((it) => ({ ...it, showDetail: false }));
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
