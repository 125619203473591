import axios from "@/utils/axios.js";

const createPix = async (devedor_nome, devedor_cpf, valor, observacao, externalRef) => {
    try {
        console.log('sending', {
            devedor_nome,
            devedor_cpf,
            valor,
            observacao,
            externalRef
        })
        return (await axios.post("/pix/create",
            {
                devedor_nome,
                devedor_cpf,
                valor,
                observacao,
                externalRef
            })).data
    } catch (Err) {
        console.log("ERROR ON GERAR PIX", Err, { devedor_nome, devedor_cpf, valor, observacao });
    }
}


const consultarPix = async (id) => {
    try {

        // const source = CancelToken.source();
        // const timeout = setTimeout(() => {
        //   source.cancel();
        //   // Timeout Logic
        // }, 10000);
        return (await axios.post("/pix/refresh",
            {
                id
            }, { timeout: 20000 })).data
    } catch (Err) {
        console.log("ERROR ON REFRESH PIX", Err, id);
    }
}


const cancelarPix = async (id, valor) => {
    try {
        return (await axios.post("/pix/cancel",
            {
                id,
                valor
            })).data
    } catch (Err) {
        console.log("ERROR ON CANCEL PIX", Err, id);
    }
}

const listagemPix = async (filtros) => {
    try {
        console.log('filtros', filtros);
        return (await axios.post("/pix/list",
            filtros)).data
    } catch (Err) {
        console.log("ERROR ON LISTAGEM PIX", Err, filtros);
    }
}


export default {
    consultarPix,
    createPix,
    cancelarPix,
    listagemPix
}