<template>
  <div class="container">
    <barra-crud
      :botaoNovo="true"
    :showTitle="!noTitle"
      :onNovo="
        () => {
          abrirForm(null);
        }
      "
    />
    <div class="text-center p-4" v-if="loading">
      <b-spinner />
      <br />
      carregando...
    </div>
    <table class="table">
      <tbody v-for="gp in lista" :key="`pl_gp_${gp.grupo}`">
        <tr>
          <th class="text-left bg-primary text-white">{{ gp.grupo }}</th>
        </tr>
        <template v-for="sb in gp.sub_grupos">
          <tr :key="`pl_sb_${gp.grupo}_${sb.sub_grupo}`">
            <th class="pl-5 text-left bg-secondary text-white">{{ sb.sub_grupo }}</th>
          </tr>
          <tr :key="`pl_sb_${gp.grupo}_${sb.sub_grupo}2`">
            <td class="p-2">
              <b-table
                small
                style="font-size: 10px"
                :fields="[
                  { key: 'id', label: 'Código' },
                  { key: 'nome', label: 'Nome', class: 'text-left' },
                  { key: 'tipo', label: 'Tipo', class: 'text-left' },
            
                  { key: 'opc', label: 'Opções' },
                ]"
                hover
                :items="sb.lista"
              >
                <template #cell(tipo)="row">
                  <span
                    :class="row.item.tipo == 0 ? 'text-danger' : 'text-success'"
                  >
                    <i
                      :class="
                        row.item.tipo == 0
                          ? 'fa fa-caret-down'
                          : 'fa fa-caret-up'
                      "
                    />
                    {{ row.item.tipo == 0 ? "Débito" : "Crédito" }}
                  </span>
                </template>
                <template #cell(grupo)="row">
                  {{ row.item.grupo }} / {{ row.item.sub_grupo }}
                </template>
                <template #cell(opc)="row">
                  <b-btn
                    size="sm"
                    variant="warning"
                    @click="abrirForm(row.item)"
                    ><i class="fa fa-edit" /> Editar</b-btn
                  >
                  <b-btn size="sm" variant="danger" @click="del(row.item.id)"
                    ><i class="fa fa-trash" /> Excluir</b-btn
                  >
                </template>
              </b-table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <b-modal
      id="modal-add-plano"
      hide-footer
      title="Adicionar/Editar Plano de Contas"
    >
      <PlanoContasForm :editObj="editObj" @close="closeForm" :grupos="lista" />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../../../components/common/BarraCrud.vue";
import PlanoContasLib from "../../../../libs/PlanoContasLib";
import PlanoContasForm from "./PlanoContasForm.vue";
export default {
  components: {
    BarraCrud,
    PlanoContasForm,
  },
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      lista: [],
      editObj: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    del(id) {
      this.$swal({
        title: "Tem certeza?",
        text: "Você confirma a exclusão deste plano de conta?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Excluir!",
      }).then(async (x) => {
        if (x.isConfirmed) {
          try {
            this.$store.state.loading = true;
            let res = await PlanoContasLib.deletePlanoContas(id);
            console.log(res);
            if (res && res.success) {
              this.$swal({
                title: "Plano excluido com sucesso!",
                icon: "success",
                toast: true,
                timer: 1000,
                showConfirmButton: false,
              });
              this.carregar();
            } else {
              this.$swal({
                title: "Eroo ao excluir!",
                icon: "error",
                toast: true,
                timer: 1000,
                showConfirmButton: false,
              });
            }
            this.$store.state.loading = false;
          } catch (error) {
            console.log("ERROR ON DELETE", error);
            this.$swal({
              title: "Eroo ao excluir!",
              icon: "error",
              toast: true,
              timer: 1000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
    async abrirForm(edit) {
      this.editObj = edit;
      this.$bvModal.show("modal-add-plano");
    },
    async closeForm() {
      this.$bvModal.hide("modal-add-plano");
      this.carregar();
    },
    async carregar() {
      this.loading = true;
      this.lista = [];
      try {
        let res = await PlanoContasLib.getPlanoContas({ agrupado: true });
        console.log(res);
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.log("error on get", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
