<template>
  <div>
    <div class="text-center" v-if="loading"><b-spinner /> carregando...</div>
    <div
      v-else
      
    >
      <!-- <pedido-card
        :item="item"
        v-for="item of historico"
        :key="item.cod_pedido_item"
      /> -->

      <b-table
      :items="historico"
      :fields="[{key:'data',label:'Data'}, {key:'empresa',label:'Estabelecimento'}, {key:'prod',label:'Produto'}]"
      small
      hover
      
      style="font-size:10px"
      >
      <template #cell(data)="row">
        <b-icon-clock /> {{row.item.data | moment("DD/MM/YYYY  HH:mm")}}
      </template>

      <template #cell(prod)="row">
        {{row.item.prod}} 
        <span v-if="row.item.prod2">
          | {{row.item.prod2}}
        </span>
        <span v-if="row.item.borda">
          <br/> 
          <b>Borda: </b> {{row.item.borda}}
        </span>
      </template>
      </b-table>

      
      <div v-if="!historico || historico == null || historico.length <= 0">
        <i class="fa fa-times"></i> Cliente sem histórico ainda :(
      </div>
    </div>
  </div>
</template>

<script>
import PedidoLib from "../../libs/Pedido";
// import PedidoCard from "../pedido/PedidoCard.vue";
export default {
  props: {
    cod_cliente: {
      type: Number,
      default: 0
    }
  },
  components: {
    // PedidoCard
  },
  mounted() {
    this.carregar();
  },
  watch: {
    "$props.cod_cliente": function() {
      this.carregar();
    }
  },
  data() {
    return {
      historico: [],
      loading: false
    };
  },
  methods: {
    async carregar() {
      // console.log("carregando");
      this.loading = true;
      this.historico = [];

      try {
        this.historico = (
          await PedidoLib.historicoCliente(this.cod_cliente)
        ).data;
        // console.log("historico", this.historico);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    }
  }
};
</script>

<style>
</style>