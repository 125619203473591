import axios from "@/utils/axios";

const getDisputas = async (filters) => {
  let ret = null;
  try {
    ret = (await axios.post("/disputa_cancelamento/get", filters)).data;
  } catch (err) {
    console.log("error on get contas a pagar", err);
  }

  return ret;
};

const responderDisputa = async (disputa, resposta, reason, obs)=>{
  let ret = null;
  try {
    console.log(
      "disputa, resposta, reason, obs",
      disputa,
      resposta,
      reason,
      obs
    )
    ret = (await axios.post("/disputa_cancelamento/responder", {disputa, resposta, reason, obs})).data;
    
  } catch (err) {
    console.log("error on get contas a pagar", err);
  }

  return ret;

}

const tipos = [
  { type: "AFTER_DELIVERY", label: "Após entrega" },
  { type: "DELAY", label: "Atraso" },
  { type: "PREPARATION_TIME", label: "Tempo de preparo" },
  { type: "AFTER_DELIVERY_PARTIALLY", label: "Após entrega parcial" },
];

export default {
  getDisputas,
  tipos,
  responderDisputa
};
