var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.loadingCardapio)?_c('b-skeleton'):_vm._e(),_c('CardapioGrupos',{attrs:{"empresaSelecionada":_vm.empresaSelecionada,"open":_vm.open,"mesa":_vm.mesa,"empresa":_vm.empresa}}),(!_vm.Inside)?_c('b-modal',{attrs:{"id":"modal-itens","hide-footer":"","hide-header":"","size":"lg","content-class":"prodModal","no-fade":"","no-close-on-backdrop":"","no-close-on-esc":"","title":`Adicionar ${_vm.grupoSelecionado.Grupo}`},on:{"hidden":_vm.atuTot,"trigger":_vm.fecha},nativeOn:{"keypress":function($event){return _vm.clickGlobal.apply(null, arguments)}}},[_c('cardapio-produtos',{ref:"cardProds",attrs:{"empresaSelecionada":_vm.empresaSelecionada,"grupoSelecionado":_vm.grupoSelecionado,"preco_tipo_inicial":_vm.preco_tipo_inicial,"produtos":_vm.produtosShow,"adicionar":(item) => {
          _vm.adicionar(item);
        },"opc_selecionada":_vm.pedido.itens,"selectTipoPreco":_vm.selectTipoPreco,"mesa":_vm.mesa},on:{"closeModal":function($event){return _vm.$bvModal.hide('modal-itens')},"closeAndSave":_vm.salvar}}),_c('div',{staticClass:"Selecionados"},[(_vm.pizza)?_c('div',[_c('CardapioPizzaConfig',{key:_vm.edIt,ref:"confPizza",attrs:{"pedido":_vm.pedido,"atualizaAdicionais":_vm.atualizaAdicionais,"adicionais":_vm.adicionais,"item":_vm.itemEDITI,"openChooseSabor":() => {
              _vm.$refs.cardProds.openChooseSabor(1);
            },"mesa":_vm.mesa}})],1):_vm._e(),_c('div',{staticClass:"row cardapioBts"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('label',[_vm._v("Observações")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pedido.obs),expression:"pedido.obs"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.pedido.obs)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pedido, "obs", $event.target.value)}}})]),(_vm.mesa && _vm.mesa.id > 0)?_c('div',{staticClass:"d-block d-sm-none mt-5 mb-5 pt-5 pb-5"},[_c('br'),_c('br'),_c('br')]):_vm._e(),_c('div',{staticClass:"col-12 col-sm-5 text-right",class:{ rodapeFixoMobile: _vm.mesa && _vm.mesa.id > 0 }},[_c('label',{staticStyle:{"font-size":"20px"}},[(_vm.grupoSelecionado.Grupo == 'PIZZA')?_c('div',[_vm._v(" R$ "+_vm._s(_vm._f("currency")(_vm.valorTotal))+" ")]):_c('div',[_vm._v(_vm._s(" - "))])]),_c('button',{staticClass:"btn btn-primary mr-1",on:{"click":function($event){return _vm.salvar()}}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(_vm.modoEdit ? "Atualizar" : "Adicionar")+" ")]),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.$bvModal.hide('modal-itens')}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Fechar ")])])])])],1):_vm._e(),_c('b-modal',{attrs:{"id":"modal-combo","title":"Combo de Produto","hide-footer":"","size":"lg"}},[_c('cardapio-combo',{attrs:{"empresaSelecionada":_vm.empresaSelecionada,"grupoSelecionado":_vm.grupoSelecionado,"preco_tipo_inicial":_vm.preco_tipo_inicial,"produtos":_vm.produtosShow,"adicionar":(item) => {
          _vm.adicionar(item);
        },"opc_selecionada":_vm.pedido.itens,"selectTipoPreco":_vm.selectTipoPreco,"mesa":_vm.mesa},on:{"closeModal":function($event){return _vm.$bvModal.hide('modal-itens')},"closeAndSave":_vm.salvar}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }