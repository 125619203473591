<template>
  <div class="container">
    <div class="d-flex justify-content-between">
      <h2>Configurações de Layout de Mesas</h2>
      <b-btn variant="success" @click="addLayout"
        ><b-icon-plus /> Novo Layout</b-btn
      >
    </div>
    <hr />
    <div>
      <div v-for="layout in lista" :key="`l_${layout.id}_${Math.random()}`">
        <b-card>
          <b-card-title class="d-flex justify-content-between">
            <div>Configuração {{ layout.nome }}</div>
            <div v-if="layout.ativo" class="text-success">
              <b-icon-check-circle /> PADRÃO
            </div>
          </b-card-title>
          <b-card-body class="p-0 p-sm-2">
            <small>Mapa apenas para visualização</small>
            <mesa-salao-mapa :layout="layout.layout" />
          </b-card-body>
          <b-card-footer>
            <b-btn variant="info" :disabled="layout.ativo" class="mr-1" @click="tornarPadrao(layout)"
              ><b-icon-check-circle /> Tornar Padrão</b-btn
            >
            <b-btn variant="warning" class="mr-1" @click="editConfig(layout)"
              ><i class="fa fa-edit" /> Editar</b-btn
            >
            <b-btn variant="danger" class="mr-1" @click="excluirConfig(layout)"
              ><b-icon-trash /> Excluir</b-btn
            >
          </b-card-footer>
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-add-layout-mesa"
      hide-footer
      title="Adicionar/Editar Layout"
      size="xl"
      modal-class="modal-full-width"
    >
      <div class="row">
        <div class="col-12">
          <label>Código</label>
          <b-input v-model="obj.id" readonly />
        </div>
        <div class="col-12 col-sm-9"></div>
        <div class="col-12 col-sm-10 mt-4">
          <label>Nome</label>
          <b-input v-model="obj.nome" />
        </div>
        <div class="col-12 col-sm-2 text-center mt-4">
          <label></label>
          <b-checkbox switch v-model="obj.ativo">Padrão</b-checkbox>
        </div>
        <div class="col-12">
          <hr />
        </div>
        <div class="col-12">
          <mesas-salao-config-form
            :mesas="mesas"
            :empresaSel="empresaSel"
            @change="(e) => (obj.layout = e)"
            :layout="obj.layout"
          />
        </div>
        <div class="col-12 mt-3 text-right">
          <hr />
          <b-btn variant="success" class="mr-1" @click="salvarLayout"
            >Salvar</b-btn
          >
          <b-btn variant="secondary" @click="cancelarConfig">Cancelar</b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EmpresasLib from "../../libs/EmpresasLib";
import MesaSalaoMapa from "./MesaSalaoMapa.vue";
import MesasSalaoConfigForm from "./MesasSalaoConfigForm.vue";

// import draggable from "vuedraggable";
const resetMesaLayoutObj = {
  id: 0,
  nome: "",
  ativo: false,
  layout: [],
};
export default {
  name: "functional",
  display: "Functional third party",
  order: 17,
  props: {
    mesas: Array,
    empresaSel: [Object, Array],
  },
  components: {
    MesasSalaoConfigForm,
    MesaSalaoMapa,
    // draggable,
  },
  mounted() {
    // console.log("mesas", this.empresaSel);
    if (
      this.$props.empresaSel &&
      this.$props.empresaSel.configs &&
      this.$props.empresaSel.configs.mesasLayout
    ) {
      this.lista = this.$props.empresaSel.configs.mesasLayout;
    }
  },
  data() {
    return {
      lista: [],
      obj: resetMesaLayoutObj,
      editing: false,
    };
  },
  methods: {
    async tornarPadrao(layout) {
      this.lista.forEach((l) => {
        l.ativo = false;
      });

      console.log("turn off all defaults");

      layout.ativo = true;
      this.$props.empresaSel.configs.mesasLayout = this.lista;
      await EmpresasLib.store(this.$props.empresaSel);
      this.$forceUpdate();
      // this.$emit("refresh");

    },
    cancelarConfig() {
      this.$bvModal.hide("modal-add-layout-mesa");
    },
    async excluirConfig(layout) {
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja excluir este layout de configuração?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Excluir!",
        cancelButtonText: "Não",
      }).then(async (x) => {
        if (x.isConfirmed) {
          this.lista = this.lista.filter((x) => x.id != layout.id);
          this.$props.empresaSel.configs.mesasLayout = this.lista;
          await EmpresasLib.store(this.$props.empresaSel);
        }
      });
    },
    editConfig(layout) {
      this.editing = true;
      this.obj = Object.assign({}, { ...layout });
      this.$bvModal.show("modal-add-layout-mesa");
    },
    addLayout() {
      this.editing = false;

      this.obj = Object.assign({}, { ...resetMesaLayoutObj });
      if (this.$props.empresaSel && !this.$props.empresaSel.configs) {
        this.$props.empresaSel.configs = {
          mesasLayout: [],
        };
      }
      if (
        this.$props.empresaSel &&
        this.$props.empresaSel.configs &&
        !this.$props.empresaSel.configs.mesasLayout
      ) {
        this.$props.empresaSel.configs.mesasLayout = [];
      }
      if (
        this.$props.empresaSel &&
        this.$props.empresaSel.configs &&
        this.$props.empresaSel.configs.mesasLayout
      ) {
        this.obj.id =
          !this.$props.empresaSel.configs.mesasLayout ||
          this.$props.empresaSel.configs.mesasLayout.length <= 0
            ? 1
            : this.$props.empresaSel.configs.mesasLayout[
                this.$props.empresaSel.configs.mesasLayout.length - 1
              ].id + 1;
      }
      this.$bvModal.show("modal-add-layout-mesa");
    },
    async salvarLayout() {
      console.log("empresa", this.$props.empresaSel);
      if (!this.obj.nome) {
        this.$swal(
          "Erro",
          "Informe um nome para a configuração de mesa!",
          "error"
        );
        return;
      }
      if (this.$props.empresaSel && !this.$props.empresaSel.configs) {
        this.$props.empresaSel.configs = {
          mesasLayout: [],
        };
      }
      if (
        this.$props.empresaSel &&
        this.$props.empresaSel.configs &&
        !this.$props.empresaSel.configs.mesasLayout
      ) {
        this.$props.empresaSel.configs.mesasLayout = [];
      }
      if (!this.editing) {
        this.lista.push(Object.assign({}, { ...this.obj }));
      } else {
        let has = this.lista.findIndex((x) => x.id == this.obj.id);
        if (has >= 0) {
          this.lista[has] = Object.assign({}, { ...this.obj });
          this.$forceUpdate();
        }
      }
      this.$bvModal.hide("modal-add-layout-mesa");

      this.$props.empresaSel.configs.mesasLayout = this.lista;
      let result = await EmpresasLib.store(this.$props.empresaSel);
      this.$nextTick(() => {
        this.$forceUpdate();

        console.log("verify default", this.obj);
        let has = this.lista.findIndex((x) => x.id == this.obj.id);
        console.log("verify default has", has);

        if (has >= 0 && this.lista[has].ativo === true) {
          setTimeout(() => {
            this.tornarPadrao(this.lista[has]);
          }, 1000);
        }
      });
      // this.$emit("refresh");
      console.log("result store empresa", result);
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.row-v {
  height: 150px;
  width: 200px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
