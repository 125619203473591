<template>
  <div class="text-center">
    <div v-if="loading">
      <b-spinner small />
    </div>
    <div v-else>
      <b-btn
        variant="warning"
        small
        size="sm"
        @click="addMov"
        v-b-popover.hover.top="referencia"
        v-if="!obj.id || obj.id === 0"
        :class="buttonClass"
      >
        <b-icon-plus /> {{ buttonText }}</b-btn
      >
      <b-btn
        variant="success"
        small
        size="sm"
        @click="addMov"
        v-if="obj.id && obj.id > 0"
        ><b-icon-check-circle /> {{ buttonText }}</b-btn
      >
    </div>
    <b-modal :id="idModal" hide-footer title="Lançamento Bancário / Caixa">
      <movimento-form
        :startObj="obj"
        @cancelar="closeBancario"
        :itemEdit="obj"
        :tipo="tipo"
      />
    </b-modal>
  </div>
</template>

<script>
import BancoMovimentoLib from "../../libs/BancoMovimentoLib";
import moment from "moment";
import MovimentoForm from "../../components/Financeiro/Bancario/MovimentoForm.vue";

export default {
  components: { MovimentoForm },
  props: {
    data: [String, Date],
    cod_caixa: { type: [String, Number], default: "" },
    cod_moto: { type: [String, Number], default: "" },
    valor: { type: [String, Number], default: 0 },
    referencia: String,
    cod_empresa: Number,
    lojaNome: String,
    carteira_id: Number,
    buttonClass: String,
    buttonText: String,
    tipo: {
      type: String,
      default: "CAIXA",
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      idModal: `modal-add-mov_${this.data}${this.cod_caixa}${this.cod_moto}${this.referencia}${this.cod_empresa}`,
      loading: true,
      obj: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    closeBancario() {
      this.$bvModal.hide(this.idModal);
      this.carregar();
    },
    addMov() {
      this.$bvModal.show(this.idModal);
    },
    async carregar() {
      this.loading = true;
      await this.$nextTick();

      this.obj = {
        valor: this.valor,
        carteira_id: this.carteira_id,
        historico_id:
          this.referencia &&
          (this.referencia.toLowerCase().includes("troco") ||
            this.referencia.toLowerCase().includes("devolução de fundo"))
            ? 29
            : 1,
        consolidado: "S",
        data: moment(this.data).format("YYYY-MM-DD 00:00:00"),
        dt_consolidado: moment(this.data).add(1, "day").format("YYYY-MM-DD"),
        obs: `Lançamento Via Conf. Caixa - Data: ${moment(this.data).format(
          "DD/MM/YYYY"
        )}${this.cod_caixa != "" ? " - Caixa: " + this.cod_caixa : ""}${
          this.cod_moto != "" ? " - Moto: " + this.cod_moto : ""
        } - Ref.: ${this.referencia} - Empr/ Loja: ${this.lojaNome}`,
        cx_data: moment(this.data).format("YYYY-MM-DD"),
        cx_cod_empresa: this.cod_empresa,
        cx_cod_caixa: this.cod_caixa,
        cx_cod_moto: this.cod_moto,
        cx_ref: this.referencia,
        cx_valor: this.valor,
      };

      try {
        let res = await BancoMovimentoLib.getMovimento({
          cx_data: moment(this.data).format("YYYY-MM-DD"),
          cx_cod_empresa: this.cod_empresa,
          cx_cod_caixa: this.cod_caixa,
          cx_cod_moto: this.cod_moto,
          cx_ref: this.referencia,
        });
        if (res && res.success && res.data && res.data.length > 0) {
          this.obj = res.data[0];
        }
        // console.log("busca", res);
      } catch (error) {
        console.log("ERROR");
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
