import axios from "@/utils/axios";
// import moment from 'moment';

const getResumoFinanceiro = async (data) => {
    const result = (await axios.post('/financeiro/resumo', data)).data;
    return result;
}

const getResumoSemana = async ({ start, end, cod_empresa }) => {
    try {
        const result = (
            await axios.post(`/financeiro/resumoSemana`, {
            start, end, cod_empresa
            })
        ).data;
        return result;
    } catch (e) {
        console.log(e);
        return false;
    }
}
const getResumoFinanceiroNew = async ({ start, end }) => {
    try {
        const result = (
            await axios.post(`/financeiro/resumoFinanceiro`, {
                start, end
            })
        ).data;
        return result;
    } catch (e) {
        console.log(e);
        return false;
    }
}



export default {
    getResumoFinanceiro,
    getResumoSemana,
    getResumoFinanceiroNew,
}