<template>
  <div class="container">
    <Autorizador />

    <Menu />
    <div class="conteudo" ref="conteudo">
      <Topo />
      <transition name="fadeTransition">
        <router-view></router-view>
      </transition>
      <chat-widget />
    </div>
  </div>
</template>
<script>
import "@/assets/scss/layout.scss";
import Topo from "@/components/Layout/topo";
import Menu from "@/components/Layout/menu";
import ChatWidget from "../components/ChatWidget.vue";
import Autorizador from "../components/Autorizador.vue";

export default {
  components: {
    Topo,
    Menu,
    ChatWidget,
    Autorizador,
  },

  data() {
    return {};
  },
  mounted() {
    this.$store.state.scrollConteudoTop = this.scrollToTop;

    this.$store.state.scrollConteudoTop();
  },
  methods: {
    scrollToTop() {
      this.$refs.conteudo.scrollTo = 0;
    },
  },
};
</script>
