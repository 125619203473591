<template>
  <div>
    <div
      class="motoBox"
      @click="openModal"
      :class="{
        correndo: motoSelecionada && motoSelecionada.emRota > 0,
        disponivel:
          motoSelecionada &&
          (motoSelecionada.emRota == 0 ||
            motoSelecionada.emRota == motoSelecionada.concluidas),
        selecionada: motoSelecionada
      }"
    >
      <div v-if="!motoSelecionada">
        Selecionar / trocar moto
      </div>
      <div v-else>
        <b>{{ motoSelecionada.info && motoSelecionada.info.nome ? motoSelecionada.info.nome : motoSelecionada.nome }}</b> <br/>
        <small>
              {{
                motoSelecionada.info && motoSelecionada.info.nome
                  ? `(${motoSelecionada.info.nome})`
                  : ""
              }}
            </small>
      </div>
    </div>
    <b-modal
      id="modal-moto-select"
      title="Selecione uma moto"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      hide-footer
      hide-header
    >
      <h4>Selecione uma moto</h4>
      <div class="motoSelecionada">
        <div>
          <div v-if="motoSelecionada">
            <b>{{ motoSelecionada.nome }}</b>
            <br />
            <small>
              {{
                motoSelecionada.info && motoSelecionada.info.nome
                  ? `(${motoSelecionada.info.nome})`
                  : ""
              }}
            </small>
          </div>
        </div>
        <div>
          <b-button variant="primary" v-if="motoSelecionada" @click="selecionarOK">
              <i class="fa fa-check"/>
            
            Selecionar
            </b-button>
          <b-button variant="secondary" @click="cancelar">
              <i class="fa fa-times"/>
            Cancelar
          </b-button>
        </div>
      </div>
      <div class="listaMotosSelect">
        <div
          class="moto"
          v-for="moto in controles"
          :key="controles.indexOf(moto)"
          :class="{
            correndo: moto.emRota > 0,
            disponivel: moto.emRota == 0 || moto.emRota == moto.concluidas,
            selecionada:
              motoSelecionada && motoSelecionada.cod_moto == moto.cod_moto
          }"
          @click="selecionar(moto)"
        >
          <div>
            <b>{{ moto.info && moto.info.nome? moto.info.nome : moto.nome }}</b>
            <small>
              {{ moto.info && moto.info.nome ? `(${moto.nome})` : "" }}
            </small>
          </div>
          <div class="status">
            <div>
              <b>Em Rota</b> <br />
              {{ moto.emRota }}
            </div>
            <div>
              <b>Concluídas</b><br />
              {{ moto.concluidas }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    data: {
      type: Date,
      default: new Date()
    },
    empresa: Object
  },
  sockets: {
    motoControle(result) {
      this.controles = result.filter(x => x.cod_entrada && x.cod_entrada > 0);
    },
    solicitaMotoControle() {
      this.getControles();
    }
  },
  mounted() {
    this.solicitaMotos();
  },
  data() {
    return {
      controles: [],
      motoSelecionada: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    openModal() {
      this.$bvModal.show("modal-moto-select");
    },
    solicitaMotos() {
      this.$socket.emit("entregas:motoControle", {
        data: moment(this.data).format("YYYY-MM-DD"),
        cod_empresa: this.empresa.cod_empresa
      });
    },
    selecionar(moto) {
      this.motoSelecionada = moto;
       this.$bvModal.hide("modal-moto-select");
      this.$emit("motoSelecionada",this.motoSelecionada);
    },
    selecionarOK(){
     

    },
    cancelar() {
      this.motoSelecionada = null;
      this.$bvModal.hide("modal-moto-select");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/entregas.scss";
</style>