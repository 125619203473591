<template>
  <div>
    <vl-map
      :load-tiles-while-animating="false"
      :load-tiles-while-interacting="false"
      data-projection="EPSG:4326"
      style="height: 200px"
    >
      <vl-view
        :zoom.sync="mapOptions.zoom"
        :center.sync="mapOptions.center"
        :rotation.sync="mapOptions.rotation"
      ></vl-view>

      <!-- <vl-geoloc @update:position="geolocPosition = $event">
        <template slot-scope="geoloc">
          <vl-feature v-if="geoloc.position" id="position-feature">
            <vl-geom-point :coordinates="geoloc.position"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon
                src="_media/marker.png"
                :scale="0.4"
                :anchor="[0.5, 1]"
              ></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </template>
      </vl-geoloc> -->

      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
      <vl-feature v-if="coordinatesLine[0].length > 1">
        <vl-geom-multi-line-string :coordinates="coordinatesLine">
        </vl-geom-multi-line-string>
        <vl-style-box>
          <vl-style-stroke color="red" :width="3"></vl-style-stroke>
        </vl-style-box>
      </vl-feature>
      <!-- <vl-layer-vector>
        <vl-feature>
          <vl-geom-multi-point
            :coordinates="[
              [-47.3913512, -20.5401164],
              [-47.3852546, -20.5398724],
              [-47.3913512, -20.5401164]
            ]"
          >
            <vl-style-box>
              <vl-style-circle :radius="40">
                <vl-style-fill color="black"></vl-style-fill>
                <vl-style-stroke color="red"></vl-style-stroke>
              </vl-style-circle>
            </vl-style-box>
          </vl-geom-multi-point>
        </vl-feature>
      </vl-layer-vector> -->

      <vl-layer-vector
        v-for="ent in coordinates"
        :key="coordinates.indexOf(ent)"
      >
        <vl-feature>
          <vl-geom-point :coordinates="ent"></vl-geom-point>

          <vl-style-box>
            <vl-style-circle :radius="5">
              <vl-style-fill color="red"></vl-style-fill>
              <vl-style-stroke color="red"></vl-style-stroke>
            </vl-style-circle>
          </vl-style-box>
        </vl-feature>
      </vl-layer-vector>
    </vl-map>
    <!-- <div style="padding: 20px">
      Zoom: {{ mapOptions.zoom }}<br />
      Center: {{ mapOptions.center }}<br />
      Rotation: {{ mapOptions.rotation }}<br />
      My geolocation: {{ mapOptions.geolocPosition }}
    </div> -->
  </div>
</template>

<script>
// import axios from 'axios';
export default {
  components: {},
  props: {
    lista: Array
  },
  mounted() {
    this.fiilMap();
  },
  beforeDestroy() {},
  watch: {
    "$props.lista": {
      deep: true,
      handler() {
        this.fiilMap();
      }
    }
  },
  data() {
    return {
      invertal: null,
      coordinates: [],
      coordinatesLine: [[]],
      mapOptions: {
        zoom: 11,
        center: [-47.38276105237041, -20.539868053543955],
        rotation: 0,
        geolocPosition: undefined,
        //mapTypeControl:false,
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [[]]
            }
          }
        ]
      }
    };
  },
  methods: {
    startUpdate() {
      this.invertal = setTimeout(() => {
        this.fiilMap();
      }, 500);
    },
    stopUpdate() {
      try {
        clearInterval(this.invertal);
      } catch (e) {
        e;
      }
      this.invertal = null;
    },
    fiilMap() {
      // console.log("lista change", this.lista);

      this.coordinates = [];
      this.coordinatesLine = [[]];
      for (let ent of this.lista) {
        if (ent.geoLocation && ent.geoLocation.lat && ent.geoLocation.lon) {
          this.coordinatesLine[0].push([
            ent.geoLocation.lat,
            ent.geoLocation.lon
          ]);
          this.coordinates.push([ent.geoLocation.lat, ent.geoLocation.lon]);
        }
      }

      this.$forceUpdate();
      this.$nextTick();

    }
  }
};
</script>

<style lang="scss" scoped>
</style>