var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('barra-crud',{attrs:{"nomeCustomizado":'Carteiras / Contas',"botaoNovo":true,"onNovo":() => {
        this.openCarteira(null);
      }}}),_c('b-table',{attrs:{"items":_vm.lista,"fields":[
      { key: 'id', label: 'Código' },
      { key: 'nome', label: 'Nome' },
      { key: 'tipo', label: 'Tipo' },
      { key: 'ordem', label: 'Ordem' },
      { key: 'bot', label: '' }
    ],"hover":""},scopedSlots:_vm._u([{key:"cell(bot)",fn:function(row){return [_c('b-btn',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openCarteira(row.item.id)}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Editar")]),_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.excluir(row.item.id)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Excluir")])]}},{key:"cell(ordem)",fn:function(row){return [_c('b-input',{attrs:{"type":"number"},on:{"change":function($event){return _vm.salvarOrdem(row.item)}},model:{value:(row.item.ordem),callback:function ($$v) {_vm.$set(row.item, "ordem", $$v)},expression:"row.item.ordem"}})]}}])}),_c('b-modal',{attrs:{"title":"Adicionar/Editar Carteira/Conta","no-enforce-focus":"","hide-footer":"","id":"modal-carteira"}},[_c('carteiras-form',{attrs:{"editID":_vm.editID},on:{"close":_vm.fecharCarteira}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }