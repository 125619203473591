import axios from "@/utils/axios";

const get = async () => {
  const result = (await axios.get("/empresas/get")).data;
  console.log('emp', result);
  return result;
};

const getResumo = async (filters) => {
  const result = (await axios.get("/empresas/resumo", {params: {filters}})).data;
  return result;
};

const store = async (data) => {
  let result;
  await axios
    .post("/empresas/store", data)
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      result = err;
    });

  return result;
};

export default {
  get,
  getResumo,
  store,
};
