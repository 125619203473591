<template>
  <div class="container-fluid financeiro text-center text-sm-left bg-white">
    <div class="row mt-2 pr-0 pl-0 pl-sm-3 pr-sm-3">
      <div class="col-12 col-sm-4">
        <h3>
          <img
            :src="
              require('@/assets/img/icones/menu/sales_performance_40px.png')
            "
            style="filter: invert(1)"
          />
          Financeiro
        </h3>
      </div>
      <div class="col-12 col-sm-4">
        <label>Periodo</label>
        <v-date-picker
          :themeStyles="themeStyles"
          v-model="dateRange"
          is-range
          class="text-center"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input
                :value="`${inputValue.start} - ${inputValue.end}`"
                v-on="inputEvents.start"
                class="form-control"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="col-12 col-sm-4">
        <label>Empresas</label>
        <v-select
          style="max-width: 500px; display: block"
          multiple
          v-model="empresaSelecionada"
          :options="empresas"
          label="nome"
        />
      </div>
    </div>
    <hr class="mb-2" />
    <div class="row pr-0 pl-0">
      <div class="col-12 col-lg-1 pr-0 bg-white">
        <div class="menuLateral">
          <router-link
            to="/financeiro/"
            active-class="selecionado"
          >
            <b-icon-calculator />
            Resumo
          </router-link>
          <router-link
            to="/financeiro/bancario/movimentacao"
            active-class="selecionado"
            v-if="isAdmin"
          >
            <i class="fa fa-university" />
            Bancário
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/financeiro/saidas"
            active-class="selecionado"
          >
            <b-icon-basket />
            Saídas
          </router-link>
          <router-link
            to="/financeiro/cp"
            active-class="selecionado"
          >
            <b-icon-calendar-check />
            Contas á Pagar
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/financeiro/vales"
            active-class="selecionado"
          >
            <b-icon-briefcase />
            Vales
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/financeiro/estoque"
            active-class="selecionado"
          >
            <b-icon-box />
            Estoque
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/financeiro/fiscal"
            active-class="selecionado"
          >
            <i class="fa fa-dollar" />
            Fiscal
          </router-link>
          <router-link
            to="/financeiro/movimentacao-financeira/"
            active-class="selecionado"
          >
            <b-icon-cash-coin />
            Mov. Financeira
          </router-link>
        </div>
      </div>
      <div class="col-12 col-lg-11 bg-white p-0 pl-sm-2 pr-sm-2">
        <router-view
          :empresas="empresaSelecionada"
          :data="dateRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {
    console.log("user", this.user);
    this.isAdmin = this.user.tipo == "Administrador";
    console.log("empresas", this.$store.state.empresas);
    this.empresas = [
      this.$store.state.empresas,
      ...this.$store.state.empresas.empresas,
    ];
    if (
      this.user &&
      this.user.configuracoes &&
      this.user.configuracoes.empresas &&
      this.user.configuracoes.empresas.length > 0 &&
      this.user.tipo == "Gerente"
    ) {
      this.empresas = this.empresas.filter((x) =>
        this.user.configuracoes.empresas.includes(x.cod_empresa)
      );
    } else if (this.user && this.user.tipo == "Gerente") {
      this.empresas = this.empresas.filter(
        (x) => x.cod_empresa == this.user.cod_empresa
      );
    }
    if (!this.isAdmin) {
      this.empresaSelecionada = this.empresas.filter(
        (x) => x.cod_empresa == this.user.cod_empresa
      );
    }
  },
  data() {
    return {
      themeStyles: {
        dayNotInMonth: "not-in-month",
      },
      isAdmin: false,
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      empresas: [],
      empresaSelecionada: [],
    };
  },
  watch: {
    empresaSelecionada() {
      console.log("empresaSelecionada", this.empresaSelecionada);
      if (this.user && this.user.tipo !== "Administrador") {
        if (!this.empresaSelecionada || this.empresaSelecionada.length == 0) {
          this.empresaSelecionada = this.empresas.filter(
            (x) => x.cod_empresa == this.user.cod_empresa
          );
        }
      }
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/financeiro.scss";
</style>
