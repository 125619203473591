import axios from '@/utils/axios';

const getPlanoContas = async (filters) => {
    try {
        return (await axios.post("/plano_contas/get", filters)).data;
    } catch (error) {
        console.log("ERror on get plano contas", error, filters);
        return false;
    }
}


const storePlanoContas = async (data) => {
    try {
        return (await axios.post("/plano_contas/store", data)).data;
    } catch (error) {
        console.log("ERror on store plano contas", error, data);
        return false;
    }
}

const deletePlanoContas = async (id) => {
    try {
        return (await axios.post("/plano_contas/delete", {id})).data;
    } catch (error) {
        console.log("ERror on delete plano contas", error, id);
        return false;
    }
}




export default {
    getPlanoContas,
    storePlanoContas,
    deletePlanoContas
}