var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3"},[(_vm.obj)?_c('div',{staticClass:"text-center pt-0 border border-grey rounded-pill mb-4",staticStyle:{"width":"200px","height":"200px","margin":"0 auto","overflow":"hidden"}},[_c('file-upload',{attrs:{"enable-title":false,"max-files":1,"value":_vm.obj.foto,"freeStyle":true,"photoIcon":true,"enableEdit":_vm.obj.cod_cliente ? true : false},on:{"input":_vm.updateFoto}})],1):_vm._e(),(_vm.fotoAlterado)?_c('b-btn',{attrs:{"variant":"success","block":""},on:{"click":_vm.saveFoto}},[_vm._v(" Salvar Foto ")]):_vm._e(),_c('div',{staticClass:"text-center bg-light textCadastro py-2"},[_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-4"},[_vm._v(" Código "),_c('br'),_vm._v(" "+_vm._s(_vm.obj?.cod_cliente)+" ")]),_c('div',{staticClass:"col-4"},[_vm._v(" Cadastro "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.obj?.dt_cadastro,"DD/MM/YYYY"))+" ")]),_c('div',{staticClass:"col-4"},[_vm._v(" Saldo "),_c('br'),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.obj?.saldo))+" ")])])]),_c('div',{staticClass:"menu-lateral"},_vm._l((_vm.menuLista),function(mn,idx){return _c('div',{key:idx,class:{ selected: mn.label == _vm.menuSelected },on:{"click":function($event){_vm.menuSelected = mn.label}}},[_c('i',{class:mn.icon}),_vm._v(" "+_vm._s(mn.label)+" ")])}),0)],1),_c('div',{staticClass:"col-12 col-lg-9"},[(_vm.obj && _vm.menuSelected == 'Resumo')?_c('div',[_c('cliente-form-completo-dash',{attrs:{"obj":_vm.obj}})],1):_vm._e(),(_vm.obj && _vm.menuSelected == 'Cadastro')?_c('div',[_c('cliente-form-completo-form',{attrs:{"id":_vm.obj.cod_cliente},on:{"openCadastro":(id) => {
              this.id = id;
              this.carregar();
              if (this.onModal) {
                this.$emit('closeAndOpen', id);
              }
            },"close":_vm.close}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }