<template>
  <div>
    <div class="row">
      <div class="col-12">
        <label>Motivo</label>
        <b-input v-model="saida.motivo" :state="hasErrors('motivo')" />
        <b-form-invalid-feedback
          >Informe um motivo válido</b-form-invalid-feedback
        >
      </div>
      <div class="col-12">
        <label>Valor (R$)</label>
        <b-input
          v-model="saida.valor"
          :state="hasErrors('valor')"
          v-money="money"
        ></b-input>
        <b-form-invalid-feedback
          >Informe um Valor válido</b-form-invalid-feedback
        >
      </div>
    </div>
    <div class="row">
      <div class="col-4 pt-4">
        <b-form-checkbox switch v-model="print">Imprimir</b-form-checkbox>
      </div>
      <div class="col-8 text-right p-4 ">
        <button
          class="btn btn-outline-primary mr-2"
          @click="salvar()"
          :disabled="loading"
        >
          <div v-if="loading"><b-spinner small /><br />aguarde....</div>
          <div v-else>
            <i class="fa fa-save"></i><br />
            Salvar
          </div>
        </button>
        <button class="btn btn-outline-secondary" @click="$emit('close')">
          <i class="fa fa-times"></i><br />
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CaixaLib from "../../libs/Caixas";
import moment from "moment";
export default {
  props: {
    model: Object,
    data: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    caixaTipo: Object,
    CodEmpresa: Number
  },
  data() {
    return {
      loadingSave:false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true
      },
      saida: {
        motivo: "",
        valor: 0
      },
      hasError: [],
      loading: false,
      print: true
    };
  },
  mounted() {
    if (this.$props.model) {
      // console.log("opne", this.$props.model);

      if (this.$props.model) {
        this.saida.motivo = this.$props.model.motivo;
        this.saida.valor = this.$props.model.valor + 10;
        this.saida.cod_saida = this.$props.model.cod_saida;
        // console.log("edit", this.saida);
      }
      // this.$forceUpdate();
    }
    if (this.$props.data) {
      this.data = moment(this.$props.data).format(
        "YYYY-MM-DD " + moment().format("HH:mm:ss")
      );
    }
  },
  methods: {
    hasErrors(campo) {
      let p = this.hasError.find(x => x.key === campo);
      return p ? p.value : null;
    },
    getError(campo, value) {
      let t = this.hasError.find(x => x.key == campo);
      if (t) {
        t.value = value;
      } else {
        this.hasError.push({ key: campo, value });
        t = this.hasError.find(x => x.key == campo);
      }
      return t;
    },
    validar() {
      this.getError(
        "motivo",
        !(!this.saida.motivo || this.saida.motivo.trim() == "")
      );

      this.getError(
        "valor",
        !(
          !this.saida.valor ||
          this.saida.valor.trim() == "" ||
          this.saida.valor
            .toString()
            .replace("R$", "")
            .trim() == "" ||
          this.saida.valor
            .toString()
            .replace("R$", "")
            .trim() == "0,00" ||
          this.saida.valor
            .toString()
            .replace("R$", "")
            .trim() == "0"
        )
      );

      return this.hasError.filter(x => x.value == false).length <= 0;
    },

    async salvar() {
      const errorLog = () => {
        this.$swal.fire({
          title: "Erro",
          text: "Erro ao salvar!",
          icon: "error",
          timer: 3000
        });
      };
      if (this.validar()) {
        this.loading = true;
        await this.$nextTick();
        this.saida.data = moment(this.$props.data).format(
          "YYYY-MM-DD " + moment().format("HH:mm:ss")
        );
        this.saida.cod_empresa = this.$props.CodEmpresa;
        if (this.$props.caixaTipo.tipo == "caixa") {
          this.saida.cod_caixa = this.$props.caixaTipo.cod;
        } else {
          this.saida.cod_moto = this.$props.caixaTipo.cod;
        }
        this.saida.print = this.print;
        const rs = await CaixaLib.storeSaida(this.saida);
        if (rs && rs.success) {
          this.$swal
            .fire({
              title: "Concluído",
              text: "Saída salva com sucesso!",
              icon: "success",
              timer: 3000
            })
            .then(() => {
              this.$emit("close");
            });
        } else {
          errorLog();
        }
      } else {
        errorLog();
      }
      this.loading = false;
    }
  }
};
</script>

<style>
</style>