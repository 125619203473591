import axios from "@/utils/axios";


const getContasPagar = async (filters) => {
    let ret = null;
    try {
        ret = (await axios.get('/contas_pagar/lancamentos', { params: filters })).data;
    } catch (err) {
        console.log('error on get contas a pagar', err);
    }

    return ret;
}

const getCPById = async (id) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/getById', { id })).data;
    } catch (err) {
        console.log('error on get contas a pagar by id', err);
    }

    return ret;
}


const storeContasPagar = async (data) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/store', data)).data;
    } catch (err) {
        console.log('error on store contas a pagar', err);
    }

    return ret;
}


const baixarContasPagar = async (data) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/baixa', data)).data;
    } catch (err) {
        console.log('error on baixa contas a pagar', err);
    }

    return ret;
}


const getBaixasContasPagar = async (data) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/baixas', data)).data;
    } catch (err) {
        console.log('error on baixa contas a pagar', err);
    }

    return ret;
}


const excluirBaixaContasPagar = async (data) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/excluirBaixa', data)).data;
    } catch (err) {
        console.log('error on excluirBaixa contas a pagar', err);
    }

    return ret;
}

const delCP = async (id) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/excluirCP', { id })).data;
    } catch (err) {
        console.log('error on excluirBaixa contas a pagar', err);
    }

    return ret;
}

const importar = async (data) => {
    let ret = null;
    try {
        ret = (await axios.post('/contas_pagar/importar', data)).data;
    } catch (err) {
        console.log('error on importar contas a pagar', err);
    }

    return ret;
}



export default {
    getContasPagar,
    storeContasPagar,
    getCPById,
    baixarContasPagar,
    getBaixasContasPagar,
    excluirBaixaContasPagar,
    delCP,
    importar
}