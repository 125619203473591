const validarCPF = cpf => !cpf ? false : checkAll(prepare(cpf))


const notDig = i => !['.', '-', ' '].includes(i)
const prepare = cpf => !cpf ? false : cpf.trim().split('').filter(notDig).map(Number)
const is11Len = cpf => cpf.length === 11
const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
const onlyNum = cpf => cpf.every(i => !isNaN(i))

const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
const resto11 = somaDig => 11 - (somaDig % 11)
const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)



const validarCNPJ = (value) => {
    if (!value) return false

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string'
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

    // Elimina valor em formato inválido
    if (!validTypes) return false

    // Filtro inicial para entradas do tipo string
    if (isString) {
        // Limita ao máximo de 18 caracteres, para CNPJ formatado
        if (value.length > 18) return false

        // Teste Regex para veificar se é uma string apenas dígitos válida
        const digitsOnly = /^\d{14}$/.test(value)
        // Teste Regex para verificar se é uma string formatada válida
        const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

        // Se o formato é válido, usa um truque para seguir o fluxo da validação
        if (digitsOnly || validFormat) true
        // Se não, retorna inválido
        else return false
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g)
    const numbers = Array.isArray(match) ? match.map(Number) : []

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)]
    if (items.length === 1) return false

    // Cálculo validador
    const calc = (x) => {
        const slice = numbers.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
            const n = slice[x - i]
            sum += n * factor--
            if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
    }

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12)

    // Valida 1o. dígito verificador
    const digit0 = calc(12)
    if (digit0 !== digits[0]) return false

    // Valida 2o. dígito verificador
    const digit1 = calc(13)
    return digit1 === digits[1]
}

const string = {
    isNullOrEmpty: (vl) => {

        return (!vl || typeof vl == 'undefined' || vl == null || vl.toString().trim() == '');

    },
    validarCPF, 
    validarCNPJ
}

export default {
    string
}