<template>
  <div>
    <div class="titulo">
      <div class="row">
        <div class="col-12 mb-4">
          <b-dropdown
            block
            variant="primary"
            :text="$route.name"
            v-if="$store.state.isMobile"
            menu-class="w-100"
            size="lg"
          >
            <b-dropdown-item
              :to="mn.link"
              v-for="mn in menuBancario"
              :key="`menuest2_${mn.name}`"
              style="height: 50px; width: 100%; text-align: center"
            >
              <i :class="`${mn.icon} mr-1`"></i>
              {{ mn.name }}
              <hr />
            </b-dropdown-item>
          </b-dropdown>
          <div
            class="menuP"
            v-if="
              (!$store.state.isMobile &&
                $store.state.auth.user.tipo == 'Administrador') ||
              $store.state.auth.user.tipo == 'Gerente'
            "
          >
            <router-link
              v-for="mn in menuBancario"
              :key="`menuest_${mn.name}`"
              class="m cursor"
              :to="mn.link"
              active-class="active"
            >
              <i :class="`${mn.icon} mr-1`"></i>
              {{ mn.name }}
            </router-link>
          </div>
        </div>
      </div>
     
    </div>
    <router-view :empresas="empresas" :data="data" ref="cont" />
  </div>
</template>

<script>
// import BarraCrud from "../../components/common/BarraCrud.vue";
// import ListaCarteiras from "../../components/Financeiro/Carteiras/ListaCarteiras.vue";
// import MovimentacaoLista from "../../components/Financeiro/Bancario/MovimentacaoLista.vue";
// import moment from "moment";
// import CarteiraLib from "../../libs/Carteiras";
export default {
  components: {
    // ListaCarteiras,
    // MovimentacaoLista
  },
  props: {
    // BarraCrud
    data: Object,
    empresas:Array
  },
  mounted() {},
  data() {
    return {
      menuBancario: [
        // {
        //   name: "Carteiras",
        //   icon: "fas fa-list",
        //   link: "/financeiro/bancario/movimentacao#reset",
        // },
        {
          name: "Movimentação",
          icon: "fas fa-list",
          link: "/financeiro/bancario/movimentacao",
        },
        // {
        //   name: "Resumo Geral",
        //   icon: "fa fa-calculator",
        //   link: "/financeiro/bancario/resumo",
        // },
        {
          name: "Extrato",
          icon: "fa fa-clipboard-list",
          link: "/financeiro/bancario/extrato",
        },
      ],

  
    };
  },
  watch: {},
  computed: {
   
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped></style>
