<template>
  <div>
    <label v-if="showTitle">
      <i class="fas fa-truck-loading mr-1" /> Fornecedor
    </label>
    <div class="d-flex justify-content-around">
      <v-select
        label="razao_social"
        :style="showManager?'min-width: 80%':'min-width: 100%'"
        :options="fornecedores"
        :isRequired="true"
        :clearable="true"
        :isClearable="false"
        :multiple="multiple"
        :reduce="
          (v) => {
            return v.id;
          }
        "
        v-model="fornecedor_id"
        :disabled="disabled"
      >
        <template v-slot:option="option">
          <div
            style="
              border-bottom: 1px solid #ccc;
              font-size: 13px;
              padding: 10px;
            "
          >
            {{ option.razao_social }}<br />
            <small>
              {{ option.fantasia }}
              <small> - ({{ option.cnpj }}) </small>
            </small>
          </div>
        </template>
      </v-select>
      <b-btn size="sm" @click="$bvModal.show('modal-fornecedor-select')" v-if="showManager"
        ><i class="fa fa-cogs" />
      </b-btn>
      <b-modal
        id="modal-fornecedor-select"
        size="lg"
        @hidden="carrega"
        title="Gerenciar Fornecedores"
        hide-footer
      >
        <fornecedores :showTitle="false" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import FornecedoresLib from "../../libs/FornecedoresLib";
import Fornecedores from "../../views/Configuracoes/Cadastros/Fornecedores.vue";
// import TipoHistorico from '../../../views/Configuracoes/Financeiro/TipoHistorico.vue';
// import PlanoContas from '../../views/Configuracoes/Cadastros/PlanoContas/PlanoContas.vue';

export default {
  name: `SelectFornecedor_${Math.random()}`,
  components: {
    Fornecedores,
  },
  props: {
    fornecedorId: [String, Number],
    showTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showManager: {
      type: Boolean,
      default: true,
    }
  },
  mounted() {
    this.carrega();
    setTimeout(() => {
      this.fornecedor_id = this.fornecedorId;
    }, 1000);
    // this.fornecedor_id = this.fornecedorId;
  },
  data() {
    return {
      fornecedor_id: null,
      fornecedores: [],
    };
  },
  watch: {
    fornecedorId: function () {
      // console.log("recebeu tipoHistoricoID", this.tipoHistoricoID)
      this.fornecedor_id = this.fornecedorId;
    },
    fornecedor_id: function () {
      this.$emit("change", this.fornecedor_id);
    },
  },
  computed: {},
  methods: {
    async carrega() {
      this.fornecedores = await FornecedoresLib.getFornecedores();
    },
  },
};
</script>

<style lang="scss" scoped></style>
