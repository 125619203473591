var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'text-left   py-3 pt-1 px-4 ': !_vm.$props.noStyle,
  }},[(!_vm.$props.noStyle)?_c('label',{},[_c('i',{staticClass:"fa fa-store mr-3"}),_vm._v("Estabelecimento ")]):_vm._e(),_c('b-dropdown',{staticClass:"m2 combo-empresa",attrs:{"disabled":_vm.disabled,"id":"dropdown-text","text":`${
      this.empresa_selecionada
        ? `${this.empresa_selecionada.nome}`
        : `Escolha o local`
    }`,"variant":"outline-primary"}},[_c('b-dropdown-group',{staticClass:"combo-empresa-item"},[_c('b-dropdown-header',[_c('i',{staticClass:"fa fa-store"}),_vm._v(" Selecione o Estabelecimento ")]),_vm._l((_vm.empresas),function(emp){return _c('b-dropdown-item',{key:emp.cod_empresa,staticClass:"combo-empresa-item",on:{"click":function($event){return _vm.selecionarEmpresa(emp)}}},[_vm._v(" "+_vm._s(emp.nome)+" "),_c('span',{staticClass:"desc"},[_vm._v(" "+_vm._s(emp.telefenes ? emp.telefones[0].telefone : "")+" ")])])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }