<template>
  <div class="">
    <b-btn variant="success" @click="addFileira" class="mr-1"
      ><b-icon-plus /> Adicionar Fileira</b-btn
    >
    <b-btn variant="info" @click="dividir"
      ><b-icon-code-slash /> Dividir mesas por fileira</b-btn
    >
    <div class="row pl-0 pr-0 pl-sm-1 pr-sm-1">
      <div class="col-12 pl-0 pr-0 pl-sm-1 pr-sm-1">
        <h4>Layout de Mesas</h4>
        <hr />
        <draggable
          v-model="rows"
          tag="div"
          class="wrap align-center sortable-list pl-0 pr-0 dragRow"
          style=""
          :move="checkMove"
        >
          <div
            class="d-flex sortable m-1 flex-column"
            v-for="row in rows"
            :key="row.index"
            :style="` background-color: #f9f9f9; min-height: 100% !important; padding: 5px;  flex-grow: 1`"
          >
            <div style="" class="d-flex justify-content-between">
              <h6>Fila {{ row.index }} <b-icon-x @click="removeFila(row)"/></h6>
              <!-- <b-btn
                variant="danger"
                v-if="row.index > 1"
                @click="removeFila(row)"
                ><b-icon-x
              /></b-btn> -->
            </div>
            <hr />
            <draggable
              :list="row.items"
              tag="div"
              :group="{ name: 'row' }"
              class=""
              @end="(e) => checkMove('end', e)"
              @choose="(e) => checkMove('choose', e)"
              @update="(e) => checkMove('update', e)"
              @change="(e) => checkMove('change', e)"
              :move="(e) => checkMove('change', e)"
              style="padding: 0px; height: 100% !important"
            >
              <div
                v-for="item in row.items"
                :key="item.title + Math.random()"
                class="d-flex flex-column justify-center pl-0 pr-0"
                :class="
                  (VerificaMesaReunida(item) ? ' mesaJaUnida  ' : '') +
                  (item.uniao
                    ? 'mesaUnida '
                    : VerificaMesaReunida(item)
                    ? ' mesaReunida '
                    : '')
                "
                :style="' align-items: center'"
              >
                <b-card
                  class="cardRedondo"
                  style=""
                  :move="checkMove"
                  body-class="d-flex flex-column justify-content-center align-items-center"
                  >{{ item.title }}</b-card
                >
                <div
                  class="text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="btUnir"
                    v-if="row.items.indexOf(item) + 2 <= row.items.length"
                    :id="item.title + row.items.indexOf(item)"
                    @click="
                      unirMesa(item, row.items[row.items.indexOf(item) + 1])
                    "
                  >
                    <b-icon-chevron-bar-contract v-if="!item.uniao" />
                    <b-icon-code-slash v-if="item.uniao" />
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "functional",
  display: "Functional third party",
  order: 17,
  props: {
    mesas: Array,
    empresaSel: [Object, Array],
    layout: Array,
  },
  components: {
    draggable,
  },
  mounted() {
    // console.log("mesas", this.empresaSel);
    this.carregarFromConfig();
  },
  data() {
    return {
      enabled: true,
      rows: [],
    };
  },
  watch: {
    rows: {
      deep: true,
      handler() {
        this.$emit("change", this.rows);
      },
    },
  },
  methods: {
    dividir() {
      let mesasPorFila = Math.ceil(
        Math.abs(this.mesas.length / this.rows.length)
      );
      // console.log("mesasPorFila: " + mesasPorFila);

      for (let row of this.rows) {
        console.log("montando fila", row.index, mesasPorFila * (row.index - 1));
        row.items = this.mesas
          .slice(mesasPorFila * (row.index - 1), mesasPorFila * row.index)
          .map((it) => ({
            title: it.mesa,
            ...it,
          }));
      }
    },
    removeFila(row) {
      this.rows[0].items.push(...row.items);
      console.log("removido", row.items, this.rows[0].items);
      this.rows = this.rows.filter((x) => x.index !== row.index);
    },
    carregarFromConfig() {
      console.log("emp", this.empresaSel);
      if (this.layout && this.layout.length > 0) {
        this.rows = this.layout;
      } else {
        this.rows = [
          {
            index: 1,
            items: this.mesas.map((it) => ({
              title: it.mesa,
              ...it,
            })),
          },
          {
            index: 2,
            items: [],
          },
        ];
        // console.log(1)
      }
    },
    VerificaMesaReunida(item) {
      let has =
        this.rows.filter((r) =>
          r.items.some((x) => x.uniao && x.uniao.cod_mesa == item.cod_mesa)
        ).length > 0;

      return has;
    },
    async unirMesa(item, item2) {
      if (item.uniao) {
        item.uniao = null;
      } else {
        item.uniao = { cod_mesa: item2.cod_mesa };
      }
      console.log(item);
      this.$forceUpdate();
    },
    checkMove(tp, evt) {
      console.log("move", tp, evt);
      if (
        (tp == "change") &&
        evt &&
        evt.removed &&
        evt.removed.element        
      ) {
        console.log('removed 111111111111111111111111111')
        let hasOthers = this.rows.filter(x => x.items.some(u => u.uniao && u.uniao.cod_mesa == evt.removed.element.cod_mesa));
        console.log("has others", hasOthers);
        if (hasOthers) {
          hasOthers.forEach(r => {
            r.items.forEach(i => { 
              i.uniao = null
            })
          })
        }
        
        evt.removed.element.uniao = null;
        this.$forceUpdate();
      }
      console.log(this.rows);
    },
    addFileira() {
      this.rows.push({
        index:
          this.rows && this.rows.length > 0
            ? this.rows[this.rows.length - 1].index + 1
            : 1,
        items: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dragRow {
  background: white;
  display: flex !important;
  flex-direction: row !important;
}
.buttons {
  margin-top: 35px;
}
.row-v {
  height: 150px;
  width: 200px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.btUnir {
  transition: all 0.4s ease;

  width: 35px;
  height: 35px;

  border-radius: 50%;
  transition: all 0.4s ease;
  cursor: pointer !important;
  
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1;
  &:hover {
    background-color: #f8f8f8;
    font-size: 15px;
    font-weight: bolder;
    height: 35px;
  }
}
.cardRedondo {
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.4s ease;
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  cursor: grabbing;
  font-size: 12px;
  text-align: center;
  justify-content: center !important;
  align-items: center !important;
  &:hover{
   
    box-shadow: 0px 0 0 7px rgba(0,0,0,0.2);
  }
}

.mesaUnida {
  transition: all 0.4s ease;

  border-right: 0px !important;
  border: 0px solid #666;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .cardRedondo {
    border-radius: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
    border-bottom-width: 0px;
  }

  .btUnir {
    font-size: 10px !important;
    color: red;
    font-weight: bold;
    height: auto;
    transition: all 0.4s ease;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top:0px;
    border-bottom: 0px;
    border-radius: 0px;
    background-color:white;
    width: 50px;
    margin: 0;

    &:hover {
      transition: all 0.4s ease;
      background-color: rgb(255, 0, 0);
      color: white;
      font-size: 20px !important;
      height: 25px;
    }
  }
}

.mesaJaUnida {
  border-left: 0px !important;
  border-right: 0px !important;
  transition: all 0.4s ease;
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  .cardRedondo {
    border-bottom-width: 0px;
    border-top-width: 0px;
    border-radius: 0px;
  }
}
.mesaReunida {
  transition: all 0.4s ease;
  margin-left: 0px;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  .cardRedondo {
    border-width: 1px;
    border-top: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-start-end-radius: 0px;
    border-start-start-radius: 0px;
    border-end-end-radius: 15px;
    border-end-start-radius: 15px;
  }
  margin-bottom: 5px;
}
</style>
