<template>
  <div>
    <b-tabs pills vertical>
      <b-tab title="Abertura" class="p-3">
        <template #title> <i class="fa fa-cash-register" /> Abertura </template>
        <div class="row">
          <div class="col-12 d-flex flex-column">
            <h4>Data</h4>
            {{ data | moment("DD/MM/YYYY") }}
          </div>
          <div class="col-6 mt-3">
            <h4>Valor Abertura</h4>
            <b-input
              class="text-center"
              v-money="moneyMask"
              v-model="motoObj.valor_entrada"
            />
          </div>
          <div class="col-6 mt-3 pt-2"></div>
          <div class="col-6 mt-3 pt-2">
            <h6>Valor Entrega</h6>
            <b-input
              class="text-center"
              v-money="moneyMask"
              v-model="motoObj.vl_entrega"
            />
          </div>
          <div class="col-6 mt-3 pt-2">
            <h6>Valor Entrega Online</h6>
            <b-input
              class="text-center"
              v-money="moneyMask"
              v-model="motoObj.vl_entrega_online"
            />
          </div>
          <div class="col-6 mt-3">
            <h6>Maquininha</h6>
            <b-input class="text-center" v-model="motoObj.maquina" />
          </div>
          <div class="col-6 mt-3">
            <h6>Mochila</h6>
            <b-input class="text-center" v-model="motoObj.mochila" />
          </div>
          <div class="col-12 mt-5 text-right"></div>
        </div>
      </b-tab>
      <b-tab title="Informações" class="p-3">
        <template #title>
          <i class="fa fa-address-card" /> Informações
        </template>
        <div class="row">
          <div class="col-12">
            <label>Nome</label>
            <b-input title="Nome" v-model="motoObj.info.nome" />
          </div>
          <div class="col-6">
            <label>CPF</label>
            <b-input
              title="Nome"
              v-model="motoObj.info.cpf"
              v-mask="'###.###.###-##'"
            />
          </div>
          <div class="col-6">
            <label>Telefone</label>
            <b-input
              title="Telefone"
              v-model="motoObj.info.telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
          </div>
          <div class="col-12 pt-3">
            <b-check
              switch
              title="Alerta"
              v-model="motoObj.info.alerta"
              :value="true"
              :unchecked-value="false"
              >Alerta</b-check
            >
            <div v-if="motoObj.info.alerta">
              <label>Motivo Alerta</label>
              <b-input v-model="motoObj.info.alerta_motivo" />
            </div>
          </div>
          <div class="col-6"></div>
          <div class="col-12 pt-3">
            <label>Observações</label>
            <b-textarea v-model="motoObj.info.obs" />
          </div>
        </div>
      </b-tab>

      <b-tab title="Entregas">
        <template #title> <i class="fa fa-motorcycle" /> Entregas </template>
        <entregas-lista
          :data="data"
          :moto="moto"
          :tipo="'buscar'"
          :empresa="empresa"
          ref="compEntregasLista"
        />
      </b-tab>

      <b-tab title="Saidas">
        <template #title>
          <i class="fa fa-hand-holding-usd" /> Saídas
        </template>
        <saidas
          :caixaTipo="{ tipo: 'moto', cod: moto.cod_moto }"
          :EmpresaAtiva="{ cod_empresa: moto.cod_empresa }"
          :dataInicial="dataString"
          :withData="true"
        />
      </b-tab>

      <b-tab title="Resumo">
        <template #title> <i class="far fa-handshake" /> Resumo </template>
        <caixa-resumo
          v-if="motoControle"
          :caixaProp="motoControle"
          :dataSel="dataString"
          :isMoto="true"
          :showOnly="true"
          :empresaSelecionada="motoControle ? motoControle.cod_empresa : 0"
        />
      </b-tab>

      <b-tab title="Informado">
        <template #title> <i class="far fa-thumbs-up" /> Informado </template>
        <caixa-fechamento
          :dataInicial="dataString"
          :EmpresaAtiva="{ cod_empresa: moto.cod_empresa }"
          :withData="true"
          :caixaTipo="{ tipo: 'moto', cod: moto.cod_moto }"
        />
      </b-tab>
    </b-tabs>
    <div class="mt-3 text-right">
      <b-button
        variant="danger"
        v-if="user.nivel < 2"
        class="float-left mt-3"
        @click="excluir"
        ><i class="fa fa-trash" /> Excluir</b-button
      >
      <hr />
      <b-button variant="primary" @click="abrir"
        ><i class="fa fa-save" /> Salvar</b-button
      >
      <b-button variant="secondary" @click="$emit('closeModalOpenMotos')"
        ><i class="fa fa-ban" /> Cancelar</b-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Saidas from "../../components/Caixas/Saidas.vue";

import CaixaLib from "../../libs/Caixas";
import CaixaFechamento from "../../components/Caixas/CaixaFechamento.vue";
import EntregasLista from "./EntregasLista.vue";
import CaixaResumo from "../Caixa/CaixaResumo.vue";
export default {
  components: { Saidas, CaixaFechamento, EntregasLista, CaixaResumo },
  props: {
    moto: Object,
    data: Date,
    empresa: Object,
  },
  beforeMount() {
    this.motoObj = Object.assign({}, this.$props.moto);
    this.motoObj.vl_entrega = this.formatFloatToReal(this.moto.vl_entrega);
    this.motoObj.vl_entrega_online = this.formatFloatToReal(this.moto.vl_entrega_online);
    this.motoObj.valor_entrada = this.formatFloatToReal(
      this.moto.valor_entrada
    );
    if (!this.motoObj.info) {
      this.motoObj.info = {};
    }

    this.motoObj.informados = [];

    // console.log("------>", this.moto);
  },
  mounted() {
    this.carregar();
  },
  watch: {
    "$props.moto": function () {
      this.carregar();
    },
    $props: function () {
      this.carregar();
    },
  },
  data() {
    return {
      motoObj: null,
      motoControle: null,
      dataString: moment(this.$props.data).format("YYYY-MM-DD"),
      user: this.$store.state.auth.user,
    };
  },
  methods: {
    async excluir() {
      if (
        this.$refs.compEntregasLista.lista &&
        this.$refs.compEntregasLista.lista.length > 0
      ) {
        this.$swal(
          "Atenção",
          "Moto com entregas vinculadas já. Não é possível excluir",
          "error"
        );
        return;
      } else {
        this.$swal({
          title: "Tem certeza?",
          text: "Tem certeza que deseja excluir este controle de moto de hoje?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        }).then(async (r) => {
          if (r.isConfirmed) {
            await this.$socket.emit("entregas:fecharMoto", { ...this.motoObj });
            this.$emit("closeModalOpenMotos");
          }
        });
      }
    },
    async carregar() {
      this.dataString = moment(this.$props.data).format("YYYY-MM-DD");
      // console.log(
      //   111111111111,
      //   this.moto.cod_empresa,
      //   this.moto.data,
      //   this.moto.cod_moto
      // );
      try {
        this.motoControle = (
          await CaixaLib.getMotosControle(
            this.moto.cod_empresa,
            this.moto.data,
            this.moto.cod_moto
          )
        )[0];
        this.motoControle.informados = [];
        this.motoControle.informados = await CaixaLib.getFechamentos(
          this.moto.cod_empresa,
          moment(this.moto.data).format("YYYY-MM-DD"),
          null,
          this.moto.cod_moto,
          null
        );

        // console.log("controle,", this.motoControle);
      } catch (e) {
        console.log("error", e);
      }
    },
    abrir() {
      this.motoObj.valor_entrada = this.formatValorReal(
        this.motoObj.valor_entrada
      );
      this.motoObj.vl_entrega = this.formatValorReal(this.motoObj.vl_entrega);
      this.motoObj.vl_entrega_online = this.formatValorReal(this.motoObj.vl_entrega_online);
      if (!this.motoObj.cod_entrada) {
        this.motoObj.data = moment(this.$props.data).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.motoObj.data = moment(this.motoObj.data).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      // console.log(this.motoObj);
      this.$socket.emit("entregas:abrirMoto", {
        ...this.motoObj,
        cod_usuario: this.$store.state.auth.user.cod_usuario,
      });
      this.$emit("closeModalOpenMotos");
    },
  },
};
</script>

<style></style>
