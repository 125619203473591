<template>
  <div>
    <b-modal
      id="modal-autorizar"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      no-stacking
      hide-footer
      hide-header
    >
      <div class="text-center">
        <span style="font-size:90px !important">
          <b-icon-lock />
        </span>
        <h3>Solicitação de Autorização</h3>
        <hr />
        <table class="table text-left" v-if="autorizarObj">
          <tr>
            <th class="bg-light">Data/Hora</th>
            <td>
              {{ autorizarObj.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
            </td>
          </tr>
          <tr>
            <th class="bg-light">Tipo</th>
            <td>{{ autorizarObj.tipo.toUpperCase() }}</td>
          </tr>
          <tr>
            <th class="bg-light">Observação</th>
            <td>{{ autorizarObj.obs }}</td>
          </tr>
          <tr>
            <th class="bg-light">Usuário</th>
            <td>{{ autorizarObj.usuario_sol }}</td>
          </tr>
          <tr>
            <th class="bg-light">Mensagem</th>
            <td>{{ autorizarObj.obs_usuario }}</td>
          </tr>
        </table>
      </div>
      <hr />
      <div class="text-center p-3" v-if="loading"><b-spinner /> aguarde...</div>
      <div class="row" v-if="!loading">
        <div class="col-6 pr-1">
          <b-btn size="lg" variant="success" block @click="permitir"
            ><b-icon-check /> Permitir</b-btn
          >
        </div>
        <div class="col-6 pl-1">
          <b-btn size="lg" variant="danger" block @click="negar"
            ><b-icon-x /> Negar</b-btn
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  sockets: {
    connect() {
      console.log("reconect");
      this.verificarAutorizador();
    },
    solicitarListaAutorizacao(a) {
      console.log("solicitar lista autorização11111111111111", a);
      this.$socket.emit("solicitarListaAutorizacao");
    },
    autorizar(lista) {
      if (
        this.user &&
        this.user.configuracoes &&
        this.user.configuracoes.autorizador &&
        this.user.configuracoes.autorizador.tipo > 0
      ) {
        console.log("autorizar", lista);
        if (lista) {
          this.autorizarObj = lista[0];
          if (lista.length > 0) {
            this.$bvModal.show("modal-autorizar");
          } else {
            this.autorizarObj = null;
            this.$bvModal.hide("modal-autorizar");
          }
        }
      }
    },
    autorizacaoRespondida(r) {
      console.log("resp", r);
      if (r && r.status === false) {
        this.$swal(
          "Erro",
          "Solicitação já respondida por " + r.usu + "!",
          "error"
        );
      }
      this.autorizarObj = null;
      this.loading = false;
      this.$bvModal.hide("modal-autorizar");
      this.$socket.emit("solicitarListaAutorizacao");
      this.verificarAutorizador();
    }
  },
  components: {},
  props: {},
  mounted() {
    this.verificarAutorizador();
  },
  data() {
    return {
      loading: false,
      user: this.$store.state.auth.user,
      autorizarObj: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    verificarAutorizador() {
      if (
        this.user &&
        this.user.configuracoes &&
        this.user.configuracoes.autorizador &&
        this.user.configuracoes.autorizador.tipo > 0
      ) {
        console.log("ativando autorizador");
        this.$socket.emit("ativarAutorizador", this.user);
      }
    },
    permitir() {
      this.loading = true;
      this.$socket.emit("autorizacao:permitir", this.autorizarObj);
      this.verificarAutorizador();
    },
    negar() {
      this.loading = true;

      this.$socket.emit("autorizacao:negar", this.autorizarObj);
      this.verificarAutorizador();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>