<template>
  <div>
    <div
      class="row"
      v-if="obj"
    >
      <div class="col-12 col-lg-7">
        <b-form-group label="Nome">
          <b-form-input
            v-model="obj.Nome"
            :state="null"
            placeholder="Nome"
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 col-lg-5">
        <b-form-group>
          <label>Telefone</label>
          <small v-if="verificandoTelefone">
            <b-spinner
              variant="primary"
              small
            />
            verificando...
          </small>
          <b-form-input
            v-model="obj.Telefone"
            placeholder="Telefone"
            required
            v-mask="['(##) ####-####', '(##) #####-####']"
            :state="telefoneValido"
            @change="varificarTelefone"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 col-lg-4">
        <b-form-group label="CPF">
          <b-form-input
            v-model="obj.CGC"
            :state="null"
            placeholder="CPF"
            required
            v-mask="'###.###.###-##'"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 col-lg-3">
        <b-form-group label="Sexo">
          <b-select
            item-value="value"
            item-label="label"
            text-field="label"
            value-field="value"
            :options="[
              { value: 'M', label: 'Masculino' },
              { value: 'F', label: 'Feminino' },
            ]"
            v-model="obj.sexo"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-lg-5">
        <b-form-group label="Data de Nascimento">
          <b-form-input
            v-model="obj.dt_nascimento"
            :state="null"
            placeholder="Data de Nascimento"
            required
            type="date"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12 col-md-5">
        <label
          >CEP
          <small v-if="loadingCEP">
            <b-spinner
              variant="primary"
              small
            />
            buscando...
          </small>
        </label>
        <b-input
          type="text"
          pattern="\d*"
          v-model="obj.CEP"
          v-mask="'#####-###'"
          class="inputMenor"
          placeholder="CEP"
          @blur="buscarCEP()"
          :disabled="!enabled"
        />
      </div>
      <div class="col-12 col-md-7">
        <label>Bairro</label>

        <vue-simple-suggest
          v-model="obj.Bairro"
          placeholder="Bairro"
          class="inputMenor"
          :list="listBairros"
          :styles="autoCompleteStyle"
          :destyled="false"
          :filter-by-query="true"
          :max-suggestions="5"
          @keypress.native="(e) => {}"
          :disabled="!enabled"
        >
        </vue-simple-suggest>
      </div>

      <div class="col-12 col-md-8 pr-md-1">
        <label>Logradouro</label>

        <vue-simple-suggest
          v-model="obj.Logradouro"
          placeholder="Logradouro"
          class="inputMenor"
          :list="listLogradouros"
          :styles="autoCompleteStyle"
          :destyled="false"
          :filter-by-query="true"
          :max-suggestions="5"
          @keypress.native="(e) => {}"
          :disabled="!enabled"
        >
        </vue-simple-suggest>
      </div>
      <div class="col-12 col-md-4 pl-md-0">
        <label>Número</label>
        <b-input
          type="number"
          v-model="obj.Numero"
          placeholder="Número"
          class="inputMenor"
          pattern="\d*"
          :disabled="!enabled"
        />
      </div>

      <div class="col-md-6 d-none">
        <div>
          <label>Cidade / UF</label>
          <b-select
            placeholder="Cidade/UF"
            v-model="obj.Cidade"
            class="inputMenor"
            :options="options_cidades"
            :disabled="!enabled"
          />
        </div>
      </div>
      <div class="col-12">
        <label>Complemento</label>
        <b-input
          type="text"
          class="inputMenor"
          v-model="obj.complemento"
          placeholder="Complemento"
          :disabled="!enabled"
        />
      </div>
    </div>
    <hr />
    <b-card>
      <b>Tipo de Cadastro</b>
      <div class="row">
        <div class="col-12 col-lg-4">
          <b-form-checkbox
            v-model="obj.cliente"
            switch
            :disabled="!enabled"
            class="mt-2"
            :value="1"
            :unchecked-value="0"
          >
            Cliente
          </b-form-checkbox>
        </div>
        <div class="col-12 col-lg-4">
          <b-form-checkbox
            v-model="obj.funcionario"
            switch
            :disabled="!enabled"
            class="mt-2"
            :value="1"
            :unchecked-value="0"
          >
            Funcionário
          </b-form-checkbox>
        </div>
        <div class="col-12 col-lg-4">
          <b-form-checkbox
            v-model="obj.fornecedor"
            switch
            :disabled="!enabled"
            class="mt-2"
            :value="1"
            :unchecked-value="0"
          >
            Fornecedor
          </b-form-checkbox>
        </div>
      </div>
    </b-card>
    <hr />

    <div class="text-right">
      <b-btn
        size="lg"
        variant="success"
        class="mr-2"
        @click="salvar"
        :enabled="!salvando"
        ><i
          class="fa fa-save mr-1"
          v-if="!salvando"
        ></i
        ><b-spinner
          small
          v-if="salvando"
          class="mr-1"
        ></b-spinner
        >Salvar</b-btn
      >
      <b-btn
        size="lg"
        variant="danger"
        @click="$emit('close')"
        ><i class="fa fa-ban mr-1"></i>Cancelar</b-btn
      >
    </div>
  </div>
</template>
<script>
import validador from "../../utils/validador";
import cep from "@/utils/cep";
import VueSimpleSuggest from "vue-simple-suggest/lib";
import "vue-simple-suggest/dist/styles.css";
import ClientesLib from "@/libs/ClientesLib";
import moment from "moment";
export default {
  components: {
    VueSimpleSuggest,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: null,
      cpfValido: null,
      lista_ufs: [],
      lista_cidades: [],
      options_cidades: [],
      loadingCEP: false,
      listBairros: this.$store.state.enderecos.bairros.map((b) => {
        return b.bairro;
      }),
      listLogradouros: this.$store.state.enderecos.logradouros.map((b) => {
        return b.logradouro;
      }),
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control inputMenor",
        suggestItem: "list-group-item",
        suggestions: "list-group",
        maxSuggestions: 2,
      },
      telefoneValido: null,
      verificandoTelefone: false,
      salvando: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregar() {
      if (this.id) {
        // load cliente
        let res = await ClientesLib.getAllClientes({
          cod_cliente: this.id,
        });
        console.log("res clientes", res);
        if (res && res.success) {
          this.obj = res.data[0];
        }
      } else {
        this.obj = {
          cod_cliente: null,
          Nome: "",
          Telefone: "",
          UF: "",

          Cidade: "",
          Logradouro: "",
          Numero: "",
          Bairro: "",
          Email: "",
          CGC: "",
          complemento: "",
          CEP: "",
          negativado: false,
          motivo_negativado: "",
          cod_empresa: null,
          dt_cadastro: moment().format("YYYY-MM-DD"),
          foto: null,
          situacao: "Ativo",
          situacao_acesso: "Pendente",
          dt_nascimento: ``,
          sexo: "M",
          saldo: 0,
          cliente: 1,
          funcionario: 0,
          fornecedor: 0,
        };
      }
    },
    validaCPF() {
      this.cpfValido = null;
      if (this.obj.CGC && this.obj.CGC != "") {
        if (
          !validador.string.validarCPF(this.obj.CGC) &&
          !validador.string.validarCNPJ(this.obj.CGC)
        ) {
          this.$swal(
            "CPF/CNPJ INVÁLIDO!",
            "CPF informado está incorreto!",
            "error"
          );
          this.cpfValido = false;
        } else {
          this.cpfValido = true;
        }
      }
    },
    async buscarCEP() {
      this.loadingCEP = true;
      try {
        const result = await cep.buscarCEP(this.obj.CEP);
        if (result && result.street) {
          this.obj.Bairro = result.neighborhood;
          this.obj.Logradouro = result.street;

          this.$forceUpdate();
          // console.log(this.cliente);
        }
      } catch (err) {
        console.log("ERROR ON BUSCAR CEP");
      }
      this.loadingCEP = false;

      // console.log(result);
    },
    async varificarTelefone() {
      if (this.obj.Telefone && this.obj.Telefone.length > 10) {
        this.verificandoTelefone = true;
        let result = await ClientesLib.buscaPorTelefone(this.obj.Telefone);
        console.log("result", result);
        if (
          result &&
          result.success &&
          result.data &&
          result.data.cod_cliente &&
          (!this.obj.cod_cliente ||
            result.data.cod_cliente != this.obj.cod_cliente)
        ) {
          this.$swal(
            "Telefone já cadastrado",
            "Telefone já cadastrado para outro cliente",
            "warning"
          );
          this.telefoneValido = false;
          await this.$swal({
            title: `Deseja abrir o cadastro de ${result.data.Nome}`,
            text: `O telefone ${this.obj.Telefone} já está cadastrado para ${result.data.Nome}`,
            icon: "warning",
            confirmButtonText: "Abrir Cadastro",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
          }).then((x) => {
            if (x.isConfirmed) {
              console.log("open cad");
              this.$emit("openCadastro", result.data.cod_cliente);
            }
          });
          this.obj.Telefone = "";
        } else {
          this.telefoneValido = true;
        }
      } else {
        this.telefoneValido = false;
      }
      this.verificandoTelefone = false;
    },
    async salvar() {
      await this.varificarTelefone();
      if (!this.telefoneValido) {
        this.$swal("Telefone inválido", "Telefone inválido", "error");
        return;
      }
      if (!this.obj.Nome || this.obj.Nome.trim() == "") {
        this.$swal("Nome inválido", "Nome inválido", "error");
        return;
      }

      this.salvando = true;
      try {
        this.obj.cod_empresa = this.$store.state.empresa_ativa.cod_empresa;
        let result = await ClientesLib.salvarCliente(this.obj);
        console.log("result save", result);
        if (result && result.success) {
          this.$swal({
            title: "Cadastro salvo com sucesso",
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 1000,
            interval: 1000,
            showConfirmButton: false,
          });
          this.$emit("openCadastro", result.data);
        }
      } catch (error) {
        console.log("errior", error);
      }
      this.salvando = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.textCadastro {
  font-size: 0.7em;
  font-weight: bold;
}
.menu-lateral {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 4px;
  //   background-color: #f9f9f9;
  color: #333;
  width: 100%;

  div {
    font-size: 1.2em;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    // border-top-left-radius: 15px;
    // border-bottom-left-radius: 15px;
    border-radius: 3px;
    &:hover,
    &.selected {
      color: #88d63b;
      background-color: #f9f9f9;
    }
  }
}
</style>
