<template>
  <div>
    <div class="row mb-3">
      <div class="col-6 col-md-2">
        <div v-if="caixa">
          <label>Tipo Entrega</label>
          <b-select
            :options="['Todos', 'BALCÃO', 'BUSCAR', 'ENTREGA', 'MESA']"
            v-model="filtros.tipo_entrega"
          />
        </div>
      </div>
      <div class="col-6 col-md-2">
        <label>Pagamento</label>
        <b-select
          :options="[
            'Todos',
            'Dinheiro',
            'Dim. + Cart.',
            'Cartão',
            'Online',
            'Só Entregar',
            'Vale',
            'PIX',
          ]"
          v-model="filtros.pagamento"
        />
      </div>
      <div class="col-6 col-md-2">
        <label>Status</label>
        <b-select
          :options="['Todos', 'Pendente', 'Em Rota', 'Concluído']"
          v-model="filtros.status"
        />
        <div>
          <b-btn @click="$bvModal.show('modal-edit-caixa')"
            >Transferir pedidos para caixa</b-btn
          >
        </div>
      </div>
      <div class="col-6 col-md-2">
        <label>Problema Pedido</label>
        <b-select
          :options="['Todos', 'Com Problema', 'Correção']"
          v-model="filtros.pagamento"
        />
      </div>
      <div class="col-6 col-md-4">
        <label>Buscar (senha, cliente, endereço, etc...)</label>
        <b-input placeholder="filtrar" v-model="filtro" debounce="200" />
      </div>
      <div class="col-12">
        <hr />
        <small>
          <b>Total: </b>
          {{
            pedidosFiltrados
              .filter((x) => !x.excluido)
              .reduce((r, v) => {
                r += v.vl_total;
                return r;
              }, 0) | currency
          }}
          |
          <b>Qtd.: </b>{{ pedidosFiltrados.length }}
          <span class="ml-3">
            <b>Dinheiro:</b>
            {{
              pedidosFiltrados
                .filter((x) => !x.excluido)
                .reduce((r, v) => {
                  r += v.vl_dinheiro - v.vl_troco;
                  return r;
                }, 0) | currency
            }}
            | <b>Cartão:</b>
            {{
              pedidosFiltrados
                .filter((x) => !x.excluido)
                .reduce((r, v) => {
                  r += v.vl_cartao;
                  return r;
                }, 0) | currency
            }}
            |
            <span class="text-danger"
              ><b>Cancelados:</b>
              {{
                pedidosFiltrados
                  .filter((x) => x.excluido && x.excluido === 1)
                  .reduce((r, v) => {
                    r += v.vl_total;
                    return r;
                  }, 0) | currency
              }}
            </span>
          </span>
        </small>
        <hr />
      </div>
    </div>

    <table
      class="table table-sm"
      v-if="
        caixa &&
        typeof caixa.salao !== 'undenfined' &&
        caixa.salao &&
        caixa.salao === 1
      "
    >
      <thead>
        <tr>
          <th>Nº Conta</th>
          <th>Mesa</th>
          <th>Horário</th>
          <th>Total</th>
          <th>Pago</th>
          <th>Diferença</th>
          <th>Garçom</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="mesa in pedidosFiltrados">
          <tr class="bg-light" :key="`pedido_${mesa.id}_${Math.random()}`">
            <td>{{ mesa.id }}</td>
            <td>{{ mesa.mesa }}</td>
            <td>
              {{ mesa.dt_conta | moment("HH:mm") }} /
              {{ mesa.dt_conta | moment("HH:mm") }}
            </td>
            <td>
              {{ mesa.vl_subtotal | currency }} +
              {{ mesa.taxa_servico ? mesa.taxa_servico : 0 }}% =
              {{ mesa.vl_total | currencyMask }}
            </td>
            <td>
              {{ mesa.vl_pago | currencyMask }}
            </td>
            <td
              :style="`color: ${
                mesa.vl_total - mesa.vl_pago != 0 ? 'red' : 'blue'
              }`"
            >
              {{ (mesa.vl_total - mesa.vl_pago) | currencyMask }}
            </td>
            <td>{{ mesa.usu }}</td>
          </tr>
          <tr :key="`pedido2_${mesa.id}_${Math.random()}`">
            <td colspan="99" style="font-size: 9px" class="pb-4">
              <div class="row">
                <div class="col-6" style="border-right: 1px solid #ccc">
                  <b>Pedidos </b>
                  <table class="table w-100 table-sm">
                    <thead>
                      <tr>
                        <th>Produto</th>
                        <th class="texst-rigth">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="p in mesa.pedidos"
                        :key="`pedido_${p.cod_pedido}${
                          mesa.id
                        }_${Math.random()}`"
                        :class="p.excluido === 1 ? 'text-danger' : ''"
                      >
                        <td>
                          {{ p.excluido === 1 ? "[C]" : "" }} {{ p.prod }}
                          {{ p.prod2 ? ` + ${p.prod2}` : "" }}
                          {{ p.borda ? ` + Borda ${p.borda}` : "" }}
                        </td>
                        <td class="text-rigth">
                          {{ p.vl_total | currency }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6">
                  <b>Pagamento(s) </b>
                  <table class="table w-100 table-sm">
                    <thead>
                      <tr>
                        <th>Forma</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="pg of mesa.pagamentos"
                        :key="`pgto_${mesa.id}${pg.id}_${Math.random()}`"
                      >
                        <td>{{ pg.forma }}</td>
                        <td class="text-center">
                          {{ pg.valor | currency }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="bg-gray">
                        <th>Total Pago</th>
                        <th class="text-center">
                          <span v-if="mesa.pagamentos">
                            {{
                              mesa.pagamentos.reduce((ret, v) => {
                                ret += v.valor;
                                return ret;
                              }, 0) | currency
                            }}
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <b-table
      striped
      hover
      responsive
      ref="tbResp"
      style="font-size: 12px"
      :fields="[
        { key: 'senha', label: 'Senha', sortable: true },
        { key: 'tipo_entrega', label: 'Entrega', sortable: true },
        { key: 'cliente', label: 'Cliente', sortable: true },
        { key: 'caixa', label: 'Caixa', sortable: true },
        { key: 'vl_total', label: 'Valor', sortable: true },
        { key: 'pagamento', label: 'Pagamento', sortable: true },
        { key: 'pedido_erro', label: 'Problema', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ]"
      selectable
      :items="pedidosFiltrados"
      v-if="
        caixa &&
        (typeof caixa.salao === 'undenfined' ||
          !caixa.salao ||
          caixa.salao !== 1)
      "
      @row-selected="openPed"
    >
      <template #cell(vl_total)="row">
        {{ row.item.vl_total | currency }}
      </template>
      <template #cell(senha)="row">
        <div>
          <b
            >{{ row.item.senha }}
            <small
              ><small># {{ row.item.cod_pedido }}</small></small
            ></b
          ><br />
          {{ row.item.data | moment("HH:mm") }}
          <span v-if="row.item.excluido === 1" class="text-danger">
            <br />
            <b>CANCELADO</b>
          </span>
        </div>
      </template>
      <template #cell(tipo_entrega)="row">
        {{ row.item.tipo_entrega }}<br />
        <small v-if="row.item.moto && row.item.moto != ''">
          <b-icon-bicycle /> {{ row.item.moto }}
        </small>
      </template>

      <template #cell(status)="row">
        {{
          row.item.status == 0 || row.item.status == 1 || row.item.status == 2
            ? "Pendente"
            : row.item.status == 3
            ? "Rota de Entrega"
            : "Concluído"
        }}
      </template>
      <template #cell(cliente)="row">
        {{ row.item.cliente.Nome }} <br />
        Bairro:
        {{ row.item.cliente.Bairro }}<br />
        Telefone:
        {{ row.item.cliente.Telefone }}
      </template>
      <template #cell(caixa)="row">
        <div
          :class="
            !row.item.caixa || row.item.caixa.trim() == '' ? 'text-danger' : ''
          "
        >
          {{
            !row.item.caixa || row.item.caixa.trim() == ""
              ? "ERRO CX!!!!"
              : row.item.caixa
          }}
          / {{ row.item.moto ? row.item.moto + " / " : "" }}
          <small>
            {{ row.item.empresa }}
          </small>
          <small v-if="row.item.observacoes">
            <small>
              <br />
              <b>Observações:</b> {{ row.item.observacoes }}
            </small>
          </small>
        </div>
      </template>
      <template #cell(pagamento)="row">
        <div class="pl-1">
          <div class="text-left">
            <b>Forma: {{ row.item.pagamento }} </b><br />
            Dinheiro: {{ row.item.vl_dinheiro | currency }} <br />
            Troco: {{ row.item.vl_troco | currency }} <br />
            Cartão {{ row.item.vl_cartao | currency }}
            <hr />
            Total: {{ row.item.vl_total | currency }}
          </div>
        </div>
      </template>
      <template #cell(pedido_erro)="row">
        <div class="text-left pl-1">
          <div
            v-if="row.item.pedido_erro == 1"
            class="p-3 bg-danger text-white"
          >
            <i class="fa fa-exclamation-triangle" /> PEDIDO COM ERRO
            <br />
            <small>{{ row.item.motivo_erro }}</small>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
      id="modal-edit-pedi"
      @hidden="pedidoEdit = null"
      size="xl"
      hide-footer
    >
      <pedido
        v-if="pedidoEdit && pedidoEdit.sistema !== 'ONLINE'"
        :PedidoOpen="pedidoEdit"
        @close="closePed"
        :CloseOnSave="closePed"
      />
      <pedido-online
        v-if="pedidoEdit && pedidoEdit.sistema == 'ONLINE'"
        :pedidoOpen="pedidoEdit"
        @closeModal="closePed"
      ></pedido-online>
    </b-modal>
    <b-modal
      id="modal-edit-caixa"
      title="Alterar Caixa dos Pedidos"
      hide-footer
    >
      <v-select
        :options="
          $store.state.caixas.map((caixa) => ({
            text: caixa.nome,
            value: caixa.cod_caixa,
          }))
        "
        label="text"
        v-model="cod_caixa_new"
      />

      <hr />
      <div class="text-right">
        <b-btn variant="success" @click="changeCaixas" class="mr-1"
          ><b-icon-save /> Alterar</b-btn
        >
        <b-btn variant="secondary" @click="$bvModal.hide('modal-edit-caixa')"
          ><b-icon-x-circle /> Cancelar</b-btn
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import Caixas from "../../libs/Caixas";
export default {
  components: {
    Pedido: () => import("../../components/pedido/Pedido.vue"),
    PedidoOnline: () => import("../../components/pedido/PedidoOnline.vue"),
  },
  props: {
    pedidos: Array,
    caixa: Object,
  },
  mounted() {
    this.pedidosRaw = JSON.parse(JSON.stringify(this.pedidos));
    this.$nextTick(() => {
      this.filtrar();
    });
  },
  data() {
    return {
      filtro: "",
      pedidosRaw: [],
      pedidosFiltrados: [],
      filtros: {
        tipo_entrega: "Todos",
        pagamento: "Todos",
        status: "Todos",
      },
      pedidoEdit: null,
      cod_caixa_new: null,
    };
  },
  watch: {
    filtro: function () {
      console.log(123)
      this.filtrar();
    },
    filtros: {
      deep: true,
      handler() {
        this.filtrar();
      },
    },
    pedidos: {
      deep: true,
      handler: function () {
        this.filtrar();
      },
    },
  },
  computed: {},
  methods: {
    closePed() {
      this.$bvModal.hide("modal-edit-pedi");
      this.pedidoEdit = null;
      this.$emit("atualizarPedidos");
      this.$emit("close");
    },
    openPed(ro) {
      if (!this.pedidoEdit && ro && ro.length > 0) {
        this.pedidoEdit = ro[0];
        this.$refs.tbResp.clearSelected();
        this.$bvModal.show("modal-edit-pedi");
      }
    },
    async changeCaixas() {
      try {
        if (!this.pedidos || this.pedidos.length <= 0 || !this.cod_caixa_new) {
          return;
        }
        this.$swal({
          title: "Você tem certeza?",
          text: "Ao transferir os pedidos de caixa não será possível voltar atrás!",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Transferir",
        }).then(async (res) => {
          if (res.isConfirmed) {
            let res = await Caixas.changeCaixaPedidos(
              this.caixa.cod_caixa,
              this.cod_caixa_new.value,
              this.pedidos[0].data,
              this.pedidos[0].cod_empresa
            );
            console.log("res", res);
            if (res) {
              await this.$swal(
                "Concluído!",
                "Pedidos transferidos com sucesso!",
                "success"
              );
              this.$bvModal.hide("modal-edit-caixa");
              this.$emit("close");
            } else {
              this.$swal("Atenção!", "Erro ao transferir!", "error");
            }
          }
        });
      } catch (error) {
        console.log("error");
      }
    },
    async filtrar() {
      let peds = JSON.parse(JSON.stringify(this.pedidosRaw));
      let fil = this.filtro.toUpperCase().trim();
      // console.log("peds", peds);
      //  console.table("buscando", this.filtros, this.filtro, this.caixa);
      if (!this.caixa.salao || (this.caixa.salao && this.caixa.salao !== 1)) {
        peds = peds.filter(
          (x) =>
            x.pagamento &&
            x.pagamento != "" &&
            (this.filtros.tipo_entrega == "Todos" ||
              (this.filtros.tipo_entrega != "Todos" &&
                x.tipo_entrega == this.filtros.tipo_entrega)) &&
            (this.filtros.pagamento == "Todos" ||
              (this.filtros.pagamento != "Todos" &&
                x.pagamento == this.filtros.pagamento)) &&
            (this.filtros.status == "Todos" ||
              (this.filtros.status != "Todos" &&
                (this.filtros.status == "Pendente"
                  ? x.status <= 2
                  : this.filtros.status == "Em Rota"
                  ? x.status == 3
                  : this.filtros.status == "Concluído"
                  ? x.status == 4
                  : true)))
        );
        // console.log("aplicando filtro", this.filtro)
        peds = peds.filter(
          (x) =>
            !this.filtro ||
            this.filtro.trim() == "" ||
            (this.filtro &&
              this.filtro.length > 0 &&
              ((x.cliente &&
                ((x.cliente.Nome &&
                  x.cliente.Nome.toString().toUpperCase().indexOf(fil) >= 0) ||
                  (x.cliente.Telefone &&
                    x.cliente.Telefone.toString().toUpperCase().indexOf(fil) >=
                      0) ||
                  (x.cliente.Bairro &&
                    x.cliente.Bairro.toString().toUpperCase().indexOf(fil) >=
                      0))) ||
                (x.pagamento && x.pagamento.toUpperCase().indexOf(fil) >= 0) ||
                (x.moto && x.moto.toUpperCase().indexOf(fil) >= 0) ||
                (x.senha &&
                  x.senha.toString().toUpperCase().indexOf(fil) >= 0)))
        );
      }
      //   peds = peds.filter(x=>x.cliente.index)
      //  console.log("pedidos ok", peds);

      this.pedidosFiltrados = peds.map((it) => {
        return {
          ...it,
          _rowVariant: it.excluido === 1 ? "danger" : "",
        };
      });

      await this.$nextTick();
      this.$refs.tbResp.refresh();
      // console.log("pedidos ok2", this.pedidosFiltrados);
    },
  },
};
</script>

<style lang="scss" scoped></style>
