<template>
  <div>
    <div v-if="passo == 0">
      <div class="p-2">
        <b-input placeholder="filtrar" v-model="filtro" />
      </div>
      <div
        class="text-center p-5"
        v-if="produtosFiltrados && produtosFiltrados.length === 0"
      >
        Nenhum combo encontrado!
      </div>
      <div
        class="comboOPC comboSelecionavel"
        :class="
          selecionado && combo.id === selecionado.id ? 'comboSelecionado' : ''
        "
        v-for="combo in produtosFiltrados"
        :key="`combo${combo.id}`"
        @click="selecionar(combo)"
      >
        <div class="titulo">
          <span>
            {{ combo.nome }}
          </span>
          <span>
            {{ combo.valor | currencyMask }}
          </span>
        </div>
        <div v-if="combo && combo.produtos && combo.produtos.length > 0">
          <ul>
            <li v-for="p in combo.produtos" :key="`sbPrd${combo.id}_${p.id}`">
              {{ p.produto.nome }}
              <span v-if="p.tipo"> - ({{ p.tipo.tipo }})</span>
            </li>
          </ul>
        </div>
      </div>
      <Transition v-if="selecionado" mode="in-out">
        <div class="mt-2 w-100">
          <b-btn variant="success" block @click="continuar(2)">Continuar</b-btn>
        </div>
      </Transition>
    </div>
    <div v-if="passo == 2">
      <h3>{{ selecionado.nome }}</h3>
      <hr />
      <div
        v-for="prod in selecionado.produtos"
        :key="`prod_${prod.id}`"
        class="comboOPC"
      >
        {{ prod.produto.nome }}
        <small v-if="prod.tipo"> - ({{ prod.tipo.tipo }})</small>
        <b-form-group
          v-slot="{ ariaDescribedby }"
          v-if="!prod.tipo && prod.produto.Tipo && prod.produto.Tipo == 'PIZZA'"
        >
          <b-form-radio-group
            id="btn-radios-2"
            v-model="prod.tipoSelecionado"
            :options="
              $store.state.tipos_preco.map((it) => {
                return { value: it, text: it.tipo };
              })
            "
            :aria-describedby="ariaDescribedby"
            button-variant="outline-secondary"
            :size="'sm'"
            name="radio-btn-outline"
            buttons
            fill
            :stacked="$store.state.isMobile"
          />
        </b-form-group>
        <CardapioPizzaConfig
          v-if="!prod.tipo && prod.produto.Tipo && prod.produto.Tipo == 'PIZZA'"
          :key="`cardConfig${`prod_${prod.id}`}`"
          :pedido="{ itens: [prod.produto], borda: '' }"
          ref="confPizza"
        />
         
      </div>
      <Transition v-if="passo == 2" mode="in-out">
        <div class="w-100 d-flex">
          <b-btn
            variant="danger"
            size=""
            style="width: 50%"
            @click="continuar(0)"
            >Voltar</b-btn
          >
          <b-btn
            variant="success"
            size=""
            style="width: 50%"
            @click="continuar(3)"
            >Continuar</b-btn
          >
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import CardapioPizzaConfig from "./CardapioPizzaConfig.vue";

export default {
  components: { CardapioPizzaConfig },
  props: {
    grupoSelecionado: Object,
    produtos: Array,
    adicionar: Function,
    opc_selecionada: Array,
    selectTipoPreco: Function,
    refBorda: Object,
    preco_tipo_inicial: Object,
    mesa: Object,
    empresaSelecionada: Number,
  },
  mounted() {},
  data() {
    return {
      filtro: "",
      selecionado: null,
      passo: 0,
    };
  },
  watch: {},
  computed: {
    produtosFiltrados: function () {
      return this.filtro && this.filtro != ""
        ? this.produtos.filter(
            (x) =>
              x.nome.toLowerCase().indexOf(this.filtro.toLowerCase()) >= 0 ||
              x.produtos.some(
                (t) =>
                  t.produto.nome
                    .toLowerCase()
                    .indexOf(this.filtro.toLowerCase()) >= 0
              )
          )
        : this.produtos;
    },
  },
  methods: {
    selecionar(combo) {
      this.selecionado = combo;
      this.selecionado.produtos.forEach((p) => {
        if (p.tipo && p.tipo.tipo) {
          p.tipoSelecionado = {
            value: p.tipo,
            text: p.tipo.tipo,
          };
        }
      });
    },
    tamanhoSelecionado(prod, tamanho) {
      prod.tipo = tamanho;
    },
    continuar(passo) {
      this.passo = passo;
    },
  },
};
</script>

<style lang="scss" scoped>
.comboOPC {
  width: 100%;
  min-height: 100px;
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 15px;
  padding-top: 5px;
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  .titulo {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
}
.comboSelecionavel {
  opacity: 0.7;

  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
}
.comboSelecionado {
  border-color: #eee;
  background-color: #eee;
  opacity: 1;
}
</style>
