<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      show-empty
      small
      style="font-size:10px"
    >
      <template #empty>
        <div>
          Nada aqui...
        </div>
      </template>
      <template #table-busy class="text-center">
        <b-spinner />Carregando...
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="editar(row.item.id)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <!-- <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        > -->
      </template>

      <!-- <template #cell(telefone)="row">
        <div
          v-for="tel in row.item.telefone"
          :key="`telefone_${row.item.telefones.indexOf(tel)}`"
          class="d-block text-truncate"
        >
          {{ tel }}
        </div>
      </template> -->

      <template #cell(endereco)="row">
        <div
          v-for="end in row.item.endereco"
          :key="`endereco_${row.item.endereco.indexOf(end)}`"
          class="d-block text-truncate"
        >
          {{ end.cidade }} - {{ end.uf }}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
    <b-modal
      id="modal-fornecedores"
      size="lg"
      no-fade
      content-class="prodModal"
      hide-footer
    >
      <template #modal-title>
        <i class="fas fa-truck-loading"></i> Cadastrar/Editar Fornecedor
      </template>
      <FornecedoresForm :id="id" @close="close" @salvou="carregar()" />
    </b-modal>
  </div>
</template>

<script>
import FornecedoresLib from "../../libs/FornecedoresLib";
import FornecedoresForm from "./FornecedoresForm.vue";
export default {
  components: {
    FornecedoresForm,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      id: null,
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "razao_social", label: "Razão Social", sortable: true },
        // { key: "nome", label: "Nome", sortable: true },
        { key: "fantasia", label: "Fantasia", sortable: true },
        { key: "cnpj", label: "CNPJ", sortable: true },
        { key: "endereco", label: "Cidade", sortable: true },
        { key: "telefone", label: "Telefone", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      this.loading = true;
      try {
        this.lista = await FornecedoresLib.getFornecedores();
      } catch (error) {
        console.warn(error);
        this.$swal.fire({
          title: "Erro ao carregar fornecedores!",
          icon: "error",
          toast: true,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false,
        });
      } finally {
        this.loading = false;
      }
    },
    // onRowSelected(items) {
    //   if (items) {
    //     this.editar(items[0])
    //   }
    //   this.$refs.empresasTable.clearSelected()
    // },

    async novo() {
      this.id = null;

      this.$bvModal.show("modal-fornecedores");
    },
    close() {
      this.$bvModal.hide("modal-fornecedores");
    },
    async editar(id) {
      this.$forceUpdate();
      await this.$nextTick();
      this.id = id;
      this.$bvModal.show("modal-fornecedores");
    },
    endEdit() {
      this.$bvModal.hide("modal-fornecedores");
      this.limpa();
    },
  },
};
</script>

<style></style>
