<template>
  <b-form @submit.prevent.stop="salvar">
    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>Nome</label>
          <b-form-input
            v-model="centroCusto.nome"
            placeholder="Nome"
            :state="!!centroCusto.nome"
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group>
          <select-empresa-caixa
            :hide-caixa="true"
            :empresa="centroCusto.cod_empresa"
            @empresa="centroCusto.cod_empresa = $event"
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Controla Estoque?">
          <b-checkbox switch v-model="centroCusto.controla_estoque"
            >Controlar</b-checkbox
          >
        </b-form-group>
      </div>
      <hr />
      <div class="col-12 pt-2 pb-2">
        <div class="d-flex justify-content-between">
          <v-select
            style="min-width: 250px; width: auto; flex: 1"
            :options="produtos"
            label="nome"
            v-model="produtoToAdd"
          />
          <b-btn variant="success" style="margin-left: 10px" @click="addProd">
            <b-icon-plus />
          </b-btn>
        </div>
        <b-table
          :items="centroCusto.lista_estoque_padrao"
          :fields="[
            { key: 'cod_produto', label: 'Código' },
            { key: 'nome', label: 'Produto' },
            { key: 'act', label: '' },
          ]"
        >
          <template #cell(cod_produto)="row">
            {{ row.item.cod_produto || row.item.cod_ingrediente }}
          </template>
          <template #cell(act)="row">
            <b-btn variant="danger" @click="delProd(row.item)"
              ><i class="fa fa-trash" />
            </b-btn>
          </template>
        </b-table>
      </div>
    </div>
    <div class="bottom-actions">
      <hr />
      <b-btn variant="primary" class="mr-3" type="submit">
        <i class="fas fa-save" /> Salvar
      </b-btn>
      <b-btn variant="danger" @click="$emit('close')">
        <i class="fas fa-ban" /> Cancelar
      </b-btn>
    </div>
  </b-form>
</template>

<script>
import CentrosDeCustoLib from "../../libs/CentrosDeCustoLib";
import SelectEmpresaCaixa from "../common/SelectEmpresaCaixa.vue";
import EstoqueLib from "../../libs/EstoqueLib";

export default {
  components: {
    SelectEmpresaCaixa,
  },
  props: {
    id: { type: [Number, String] },
  },

  data() {
    return {
      produtos: [],
      produtoToAdd: null,
      centroCusto: {
        nome: "",
        cod_empresa: null,
        controla_estoque: false,
        lista_estoque_padrao: [],
      },
    };
  },
  methods: {
    delProd(item) {
      this.centroCusto.lista_estoque_padrao =
        this.centroCusto.lista_estoque_padrao.filter((x) => x != item);
    },
    addProd() {
      if (this.produtoToAdd == null) {
        return;
      }
      if (!this.centroCusto.lista_estoque_padrao) {
        this.centroCusto.lista_estoque_padrao = [];
      }
      if (
        !this.centroCusto.lista_estoque_padrao.find(
          (x) =>
            x.cod_produto == this.produtoToAdd.cod_produto &&
            x.cod_ingrediente == this.produtoToAdd.cod_ingrediente
        )
      ) {
        this.centroCusto.lista_estoque_padrao.push(
          Object.assign({}, { ...this.produtoToAdd })
        );
        this.produtoToAdd = null;
      } else {
        this.$swal({
          title: "Produto já está na lista",
          toast: true,
          showConfirmButton: false,
          timer: 1000,
          icon: "error",
          position: "top-end",
        });
      }
    },
    async carregarProdutos() {
      this.produtos = await EstoqueLib.getProdutos({});
      console.log("produtos", this.produtos);
    },
    async carregar(id) {
      try {
        this.centroCusto = (await CentrosDeCustoLib.get(id))[0];
        if (typeof this.centroCusto.lista_estoque_padrao === "string") {
          this.centroCusto.lista_estoque_padrao = JSON.parse(
            this.centroCusto.lista_estoque_padrao
          );
        }
      } catch (error) {
        this.showToast("error", "Erro ao editar centro de custo!");
      }
    },
    async salvar() {
      if (!this.centroCusto.nome) {
        this.showToast(
          "error",
          "Não é possível criar um centro de custo sem nome!",
          3500
        );
        return;
      }

      try {
        await CentrosDeCustoLib.store(this.centroCusto);
        this.showToast("success", "Centro de Custo salvo com sucesso!");
        this.$emit("salvou");
        this.$emit("close");
      } catch (error) {
        this.showToast("error", "Erro ao salvar centro de custo!");
      }
    },
  },
  async mounted() {
    this.carregarProdutos();
    if (this.id) {
      await this.carregar(this.id);
    }
  },
};
</script>

<style></style>
