<template>
  <div>
    <label v-if="showTitle">
      <i class="fas fa-dollar-sign mr-1" /> Centro de Custo
    </label>
    <div class="d-flex justify-content-around">
      <v-select
        label="nome"
        :style="showManager ? 'min-width: 80%' : 'min-width: 100%'"
        :options="centro_custos"
        :isRequired="true"
        :clearable="true"
        :isClearable="false"
        :multiple="multiple"
        :reduce="
          (v) => {
            return this.returnObject ? v : v.cod_custo;
          }
        "
        v-model="custo_id"
        :disabled="disabled"
      >
      </v-select>
      <b-btn
        size="sm"
        @click="$bvModal.show('modal-centro_custos-select')"
        v-if="showManager"
        ><i class="fa fa-cogs" />
      </b-btn>
      <b-modal
        id="modal-centro_custos-select"
        size="lg"
        @hidden="carregacentro_custos"
        title="Gerenciar Centro de Custo"
        hide-footer
      >
        <CentrosDeCusto />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CentrosDeCustoLib from "../../libs/CentrosDeCustoLib";
import CentrosDeCusto from "../../views/Configuracoes/Cadastros/CentrosDeCusto.vue";

export default {
  components: { CentrosDeCusto },
  props: {
    block: {
      type: Boolean,
      default: false,
    },

    returnObject: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
    showManager: {
      type: Boolean,
      default: false,
    },
    custoId: [String, Number],
    showTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregacentro_custos();
    setTimeout(() => {
      this.custo_id = this.custoId;
    }, 1000);
  },
  data() {
    return {
      custo_id: null,
      centro_custos: [],
    };
  },
  watch: {
    custoId: function () {
      // console.log("recebeu tipoHistoricoID", this.tipoHistoricoID)
      this.custo_id = this.custoId;
    },
    custo_id: function () {
      this.$emit("change", this.custo_id);
    },
  },
  computed: {},
  methods: {
    async carregacentro_custos() {
      this.centro_custos = await CentrosDeCustoLib.get();
      //  console.log("centros", this.centro_custos, this.custoId);
      this.custo_id = this.custoId;

      if (this.user && this.user.tipo == "Gerente") {
        if (
          this.user.configuracoes &&
          this.user.configuracoes.empresas &&
          this.user.configuracoes.length > 0
        ) {
          this.centro_custos = this.centro_custos.filter((x) => {
            return (
              this.user.configuracoes.empresas.filter((y) => y == x.cod_empresa)
                .length > 0
            );
          });
        } else {
          this.centro_custos = this.centro_custos.filter(
            (x) => x.cod_empresa == this.user.cod_empresa
          );
        }
      }

      if (this.block && this.user && this.user.tipo == "Gerente") {
        this.custo_id = this.centro_custos.map((x) =>
          this.returnObject ? x : x.cod_custo
        );
        this.$emit("change", this.custo_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
