import axios from "@/utils/axios";

const getReservas = async (filters) => {
  try {
    let result = (await axios.post('mesasReservas/get', filters)).data;
    if (result) {
      return result;
    }
  } catch (error) {
    console.log("ERROR ON getReservas", error);
  }
  return null;
}

const storeReservas = async (data) => {
  try {
    let result = (await axios.post('mesasReservas/store', data)).data;
    if (result) {
      return result;
    }
  } catch (error) {
    console.log("ERROR ON storeReservas", error);
  }
  return null;
}


const confirmarReservas = async (filters) => {
  try {
    let result = (await axios.post('mesasReservas/confirmar', filters)).data;
    if (result) {
      return result;
    }
  } catch (error) {
    console.log("ERROR ON confirmarReservas", error);
  }
  return null;
}

export {
  getReservas,
  storeReservas,
  confirmarReservas

}