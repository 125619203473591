var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('barra-crud',{attrs:{"botaoNovo":true,"showTitle":!_vm.noTitle,"onNovo":() => {
        _vm.abrirForm(null);
      }}}),(_vm.loading)?_c('div',{staticClass:"text-center p-4"},[_c('b-spinner'),_c('br'),_vm._v(" carregando... ")],1):_vm._e(),_c('table',{staticClass:"table"},_vm._l((_vm.lista),function(gp){return _c('tbody',{key:`pl_gp_${gp.grupo}`},[_c('tr',[_c('th',{staticClass:"text-left bg-primary text-white"},[_vm._v(_vm._s(gp.grupo))])]),_vm._l((gp.sub_grupos),function(sb){return [_c('tr',{key:`pl_sb_${gp.grupo}_${sb.sub_grupo}`},[_c('th',{staticClass:"pl-5 text-left bg-secondary text-white"},[_vm._v(_vm._s(sb.sub_grupo))])]),_c('tr',{key:`pl_sb_${gp.grupo}_${sb.sub_grupo}2`},[_c('td',{staticClass:"p-2"},[_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"small":"","fields":[
                { key: 'id', label: 'Código' },
                { key: 'nome', label: 'Nome', class: 'text-left' },
                { key: 'tipo', label: 'Tipo', class: 'text-left' },
          
                { key: 'opc', label: 'Opções' },
              ],"hover":"","items":sb.lista},scopedSlots:_vm._u([{key:"cell(tipo)",fn:function(row){return [_c('span',{class:row.item.tipo == 0 ? 'text-danger' : 'text-success'},[_c('i',{class:row.item.tipo == 0
                        ? 'fa fa-caret-down'
                        : 'fa fa-caret-up'}),_vm._v(" "+_vm._s(row.item.tipo == 0 ? "Débito" : "Crédito")+" ")])]}},{key:"cell(grupo)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.grupo)+" / "+_vm._s(row.item.sub_grupo)+" ")]}},{key:"cell(opc)",fn:function(row){return [_c('b-btn',{attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){return _vm.abrirForm(row.item)}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Editar")]),_c('b-btn',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.del(row.item.id)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Excluir")])]}}],null,true)})],1)])]})],2)}),0),_c('b-modal',{attrs:{"id":"modal-add-plano","hide-footer":"","title":"Adicionar/Editar Plano de Contas"}},[_c('PlanoContasForm',{attrs:{"editObj":_vm.editObj,"grupos":_vm.lista},on:{"close":_vm.closeForm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }