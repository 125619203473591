<template>
  <div id="app">
    <!-- <div class="globalLoading" v-if="$store.state.loading">
      <b-spinner small></b-spinner> carregando...
    </div> -->
    <div
      v-if="$store.state.loading"
      class="d-flex align-items-center"
      style="
        background-color: #212121;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
      "
    >
      <div class="container">
        <div class="logo-container">
          <img
            src="@/assets/logo s-pedido-redu.fw.png"
            alt="Logo"
            class="logo-loader"
          />
        </div>
      </div>
    </div>

    <router-view />

    <IfoodWidget></IfoodWidget>
  </div>
</template>
<script>
import IfoodWidget from "@/components/ifood/ifood-widget.vue";
export default {
  created() {
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);

    window.screen.orientation.addEventListener("change", function (e) {
      this.changeOrientation(e.matches === true ? true : false);
    });
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.changeOrientation("port");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.changeOrientation("land");
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  components: {
    IfoodWidget,
  },
  mounted() {
    // this.$store.dispatch("auth/atualizarCardapio");
    // this.$msg.onMessage(this.$msg.messaging, (a)=>{
    //   console.log("--------------> msg ", a)
    // })
    // console.log("alerta", process.env.A);
    // this.a = process.env.A
  },
  data() {
    return {
      height: 0,
      // vl:''
    };
  },
  watch: {},
  methods: {
    changeOrientation(portrait) {
      this.$store.state.mobilePortrait = portrait;
    },
    myEventHandler() {
      // this.height = window.innerHeight;
      // document.querySelector(".app").height = this.height;
      if (window.innerWidth > 575 && this.$store.state.menuVisivel) {
        this.$store.state.menuVisivel = false;
      }
      if (window.innerWidth < 575 && !this.$store.state.menuVisivel) {
        // this.$store.state.menuVisivel = true;
      }
      this.$store.state.isMobile = window.innerWidth <= 575;
    },
  },
};
</script>
<style>
.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.logo-loader {
  /* width: 150px; ajuste conforme necessário */
  z-index: 1;
  /* filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5)); */
  animation: pulse 1s infinite ease-out;
  transition: all 0.5s ease;

}

/* Efeito radial */
.logo-container::before,
.logo-container::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  opacity: 1;
  transform: scale(0);
  animation: ripple 3s infinite ease-out;
  z-index: 0;
}

.logo-container::after {
  animation-delay: 2s;
  border-width: 1px;
}

@keyframes pulse {
  0%,
  100% {
    /* filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5)); */
    transform: scale(1.1);
  }
  50% {
    /* filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.7)); */
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
