<template>
  <div>
    <div
      class="ifood-alert"
      id="popover-3"
      :class="{
        show: show,
        ring:
          disputas.length > 0 ||
          pedidosPendentes.length > 0 ||
          cancelados.length > 0,
      }"
    >
      <img
        src="@/assets/img/ifoodLogo.png"
        alt="ifood"
        width="50"
        height="50"
      />
    </div>
    <b-popover
      target="popover-3"
      triggers="hover focus click"
      placement="top"
      style="min-height: 300px"
      offset="-1"
      :show.sync="showPOP"
      custom-class="ifoodPopover"
    >
      <div
        class="w-100 text-center"
        style="min-width: 300px"
      >
        <img
          src="@/assets/img/ifoodLogo.png"
          alt="ifood"
          width="50"
          height="50"
          style="margin: 0 auto; border-radius: 100%"
        />
        <i
          :class="isMuted ? 'fa fa-volume-mute' : 'fa fa-volume-up'"
          @click="isMuted = !isMuted"
          style="
            border-radius: 100%;
            padding: 4px;
            background: white;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 20px;
            cursor: pointer;
            color: #777;
          "
        ></i>
        <i
          class="fa fa-times"
          @click="showPOP = false"
          style="
            border: 1px solid #ccc;
            border-radius: 100%;
            padding: 4px;
            background: white;
            width: 30px;
            height: 30px;
            position: absolute;
            top: -10px;
            right: -10px;
            font-size: 20px;
            cursor: pointer;
            color: #777;
          "
        ></i>
        <div
          v-if="verificando"
          class="text-right"
          style="position: absolute; margin-top: -20px; right: 10px"
        >
          <b-spinner
            size="10"
            small
          ></b-spinner>
        </div>
        <hr />
        <div
          v-for="(loja, idx) in lojas"
          :key="idx"
          class="text-left"
        >
          <div class="d-flex justify-content-between">
            <div>Loja {{ getInfo(loja, "NomeLoja") }}</div>
            <div
              class="badge text-white bg-warnin"
              :class="{
                'bg-success': getInfo(loja, 'Status') == 'Aberta',
                'bg-danger': getInfo(loja, 'Status') == 'Fechada',
              }"
            >
              {{ getInfo(loja, "Status") }}
            </div>
          </div>
          <div
            v-if="
              getInfo(loja, 'Status') == 'Fechada' && getInfo(loja, 'MsgStatus')
            "
            style="font-size: 10px"
            class="text-right"
          >
            {{ getInfo(loja, "MsgStatus") }}
          </div>
          <hr />
        </div>
      </div>

      <div
        v-if="
          (!pedidosPendentes || pedidosPendentes.length <= 0) &&
          (!disputas || disputas.length <= 0) &&
          (!cancelados || cancelados.length <= 0)
        "
        class="text-center py-4"
      >
        <img
          src="@/assets/img/undraw_empty_re_opql.svg"
          alt="nothing"
          width="100"
          height="100"
        />
        <h5>Nenhum evento recente</h5>
      </div>
      <div
        style="
          max-height: 350px !important;
          overflow-y: auto;
          overflow-x: hidden;
          display: block;
          padding-bottom: 90px;
        "
      >
        <div
          class="btALERT disputa mx-1 px-3 my-2"
          v-for="(disputa, idx) in disputas"
          :key="idx"
          @click="openDisputa(disputa)"
        >
          <b><i class="fa fa-exclamation-circle mr-1" />ALERTA DE DISPUTA</b>
          <div style="font-size: 8px">
            {{ disputa.tipo == "CANCELLATION" ? "Cancelamento" : "" }} -
            {{ getTipoDisputa(disputa.motivo) }}
          </div>
        </div>
        <div
          class="btALERT mx-1 px-3 my-2"
          v-for="(ped, idx) in pedidosPendentes"
          :key="idx"
          @click="openPedido(ped)"
        >
          <b>Pedido Pendente</b>
          <div style="font-size: 8px">
            #{{ ped.id_sistema }} - {{ ped.dados_cliente.Nome }}
          </div>
        </div>
        <div
          class="btALERT cancelado mx-1 px-3 my-2"
          v-for="(ped, idx) in cancelados"
          :key="idx"
          @click="openCancelado(ped)"
        >
          <b>PEDIDO CANCELADO. CONFIRME!</b>
          <div style="font-size: 8px">
            #{{ ped.id_sistema }} - {{ ped.dados_cliente.Nome }}
          </div>
        </div>
      </div>
      <div
        class="bot"
        style="
          position: absolute;
          bottom: 0px;
          right: 0px;
          left: 0px;
          border-top: 1px solid #ccc;
          height: 50px;
        "
      >
        <div
          style="
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 0px;
            border-right: 1px solid #ccc;
          "
        >
          Pedidos
        </div>
        <div
          style="
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 30px;
          "
        >
          Disputas
        </div>
      </div>
    </b-popover>
    <b-modal
      id="modal-disputas"
      title="Disputas de Cancelamento"
      size="xl"
      rounded
      class="round-lg"
      hide-footer
      @hidden="closeModalWidget"
    >
      <div class="my-3">
        <b-select
          style="max-width: 200px"
          :options="[
            { value: 'pendente', text: 'Pendente' },
            { value: 'aceito', text: 'Aceito' },
            { value: 'recusado', text: 'Recusado' },
            { value: 'expirado', text: 'Expirado' },
          ]"
          v-model="filterStatus"
        ></b-select>
      </div>
      <b-table
        ref="tbDisputas"
        striped
        hover
        small
        style="font-size: 12px"
        :items="disputas"
        :fields="[
          { key: 'data', label: 'Data' },
          { key: 'pedido', label: 'Pedido', class: 'text-center' },
          { key: 'pedido.cliente', label: 'Cliente', class: 'text-left' },
          { key: 'prazo', label: 'Expira em', class: 'text-center' },
          { key: 'obs', label: 'Motivo' },
          { key: 'status', label: 'Status', class: 'text-center' },
          { key: 'actions', label: 'Ações', class: 'text-center' },
        ]"
      >
        <template #cell(data)="row">
          <span>{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
        </template>
        <template #cell(pedido.cliente)="row">
          <div class="text-left">
            <span>{{ row.item.pedido.dados_cliente.Nome }}</span>
            <div>
              <small>{{ row.item.pedido.dados_cliente.Telefone }}</small>
            </div>
          </div>
        </template>
        <template #cell(pedido)="row">
          <b>{{ row.item.pedido.senha }}</b>
          <div>
            <small>#{{ row.item.pedido.cod_pedido }}</small>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.value == 'pendente'
                ? 'warning'
                : data.value == 'expirado'
                ? 'danger'
                : 'success'
            "
            >{{ data.value }}</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            @click="responderDisputa(data.item)"
            variant="danger"
            >Aceitar</b-button
          >
          <b-button
            size="sm"
            @click="responderDisputa(data.item)"
            variant="primary"
            >Recusar</b-button
          >
          <b-button
            size="sm"
            @click="cancelarDisputa(data.item)"
            variant="danger"
            >Cancelar</b-button
          >
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="modal-pedido-ifd"
      size="lg"
      hide-title
      hide-footer
    >
      <Pedido
        v-if="pedidoOpen"
        :pedido-open="pedidoOpen"
        :CloseOnSave="closePedido"
        @close="closePedido"
      ></Pedido>
    </b-modal>
    <b-modal
      id="modal-disputa-detalhe"
      size="xl"
      hide-footer
      title="Detalhes da Disputa"
    >
      <div v-if="disputaSelecionada">
        <div
          class="text- d-flex justify-content-between my-2 py-1"
          style="font-size: 16px"
        >
          <b
            >Disputa # {{ disputaSelecionada.id }} - Responda em:
            {{ disputaSelecionada.prazo }}</b
          >
          <b-badge
            :variant="
              disputaSelecionada.status == 'pendente'
                ? 'warning'
                : disputaSelecionada.status == 'expirado'
                ? 'danger'
                : 'success'
            "
            >{{ disputaSelecionada.status }}</b-badge
          >
        </div>
        <div class="text-left">
          <div title="">
            <b-card-text>
              <table
                class="table table-sm"
                style="font-size: 12px"
              >
                <tbody>
                  <tr>
                    <td class="bg-light">
                      <b>Order</b>
                    </td>
                    <td>
                      {{ disputaSelecionada.orderId }}
                    </td>
                  </tr>
                  <tr v-if="disputaSelecionada.pedido">
                    <td class="bg-light">
                      <b>Pedido</b>
                    </td>
                    <td class="d-flex justify-content-between">
                      <div>
                        {{ disputaSelecionada.pedido.cod_pedido }} -
                        <b>Senha: </b> {{ disputaSelecionada.pedido.senha }}
                      </div>
                      <div>
                        {{ disputaSelecionada.pedido.tipo_entrega }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="disputaSelecionada.pedido">
                    <td class="bg-light">
                      <b>Data</b>
                    </td>
                    <td>
                      {{
                        disputaSelecionada.pedido.data
                          | moment("DD/MM/YYYY HH:mm")
                      }}
                    </td>
                  </tr>

                  <tr v-if="disputaSelecionada.pedido">
                    <td class="bg-light">
                      <b>Cliente</b>
                    </td>
                    <td>
                      {{ disputaSelecionada.pedido.dados_cliente.Nome }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-light">
                      <b>Tipo</b>
                    </td>
                    <td>
                      {{
                        disputaSelecionada.tipo == "CANCELLATION"
                          ? "Cancelamento"
                          : ""
                      }}
                      -
                      {{ getTipoDisputa(disputaSelecionada.motivo) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-light">
                      <b>Mensagem Cliente</b>
                    </td>
                    <td>
                      {{ disputaSelecionada.obs }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <b>Observações</b>
              <b-textarea v-model="disputaSelecionada.obs_resposta" />
              <div
                class="d-flex justify-space-between mt-2"
                v-if="
                  disputaSelecionada &&
                  disputaSelecionada.obj &&
                  disputaSelecionada.obj
                "
              >
                <b-button
                  size="lg"
                  @click="responderDisputa(disputaSelecionada, true)"
                  variant="danger"
                  class="w-50 mr-1"
                  :disabled="respondendoDisputa"
                >
                  <b-spinner
                    v-if="respondendoDisputa"
                    class="mr-1"
                  />
                  Aceitar</b-button
                >
                <b-button
                  size="lg"
                  class="w-50 ml-1"
                  @click="responderDisputa(disputaSelecionada, false)"
                  variant="primary"
                  :disabled="respondendoDisputa"
                  ><b-spinner
                    v-if="respondendoDisputa"
                    class="mr-1"
                  />
                  Recusar</b-button
                >
              </div>
            </b-card-text>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-cancelado-ifd"
      hide-footer
      title="CONFIRME PEDIDO CANCELADO"
    >
      <div
        v-if="canceladoSelecionado"
        class="text-center"
        py-2
      >
        <h3>
          Senha {{ canceladoSelecionado.senha }} -
          {{ canceladoSelecionado.tipo_entrega }} - Ifood#
          {{ canceladoSelecionado.id_sistema }}
        </h3>
        <div>
          <b>Cliente</b>
          <br />
          {{ canceladoSelecionado.dados_cliente.Nome }}
        </div>
        <div class="text-left px-4">
          Este pedido foi cancelado no iFood. <br />
          <b>Avise todos os envolvidos para que o pedido não prossiga!</b><br />
          Esta mensagem só irá desaparecer quando você confirmar o cancelamento
          do pedido.
        </div>
        <b-btn
          block
          size="lg"
          class="mt-5"
          variant="danger"
          @click="marcarAvisado(canceladoSelecionado)"
        >
          <i class="fa fa-thumbs-up mr-2"></i>
          Já avisei todos
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
var cancelAUDIO = new Audio("https://proxy.s-zap.com/http://soundbible.com/grab.php?id=1599&type=mp3");
import DisputasCancelamentoLib from "../../libs/DisputasCancelamentoLib";
import moment from "moment";
import PedidoLib from "../../libs/Pedido.js";
import Pedido from "../pedido/Pedido.vue";
export default {
  components: {
    Pedido,
  },
  props: {},

  mounted() {
    let script = document.createElement("script");
    script.src = "https://widgets.ifood.com.br/widget.js";
    script.async = true;
    document.body.appendChild(script);

    this.$nextTick(() => {
      this.loadIfoodWidget();
    });

    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },

  data() {
    return {
      showPOP: false,
      disputas: [],
      pedidosPendentes: [],
      tmr: null,
      tmrToResponse: null,
      filterStatus: "pendente",
      pedidoOpen: null,
      verificando: false,
      show: false,
      lojas: [],
      disputaSelecionada: null,
      cancelados: [],
      canceladoSelecionado: null,
      respondendoDisputa: false,
      integrations: [],
      isMuted: false,
    };
  },
  watch: {
    "$store.state.empresa_ativa": {
      deep: true,
      handler() {
        // console.log("loaded");
        this.loadIfoodWidget();
      },
    },
  },
  computed: {},
  methods: {
    async responderDisputa(disputa, resposta) {
      this.$swal({
        title: "Responder Disputa",
        text: `Deseja mesmo ${resposta ? "aceitar" : "rejeitar"} a disputa?`,
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.respondendoDisputa = true;
          let result = await DisputasCancelamentoLib.responderDisputa(
            disputa,
            resposta,
            this.disputaSelecionada.reason,
            this.disputaSelecionada.obs_resposta
          );
          console.log("result", result);
          this.respondendoDisputa = false;
          this.$swal({
            title: "Resposta Enviada",
            text: "Em alguns instantes a situação do pedido será atualizada!",
            icon: "success",
          });
          this.loadDisputas();
          this.disputaSelecionada = null;
          this.$bvModal.hide("modal-disputa-detalhe");
        }
      });
    },
    openCancelado(pedido) {
      this.showPOP = false;
      this.canceladoSelecionado = pedido;
      this.$bvModal.show("modal-cancelado-ifd");
    },

    openDisputa(disputa) {
      this.showPOP = false;

      this.disputaSelecionada = disputa;
      this.$bvModal.show("modal-disputa-detalhe");
    },

    getTipoDisputa(tipo) {
      return (
        DisputasCancelamentoLib.tipos.find((x) => x.type == tipo)?.label || ""
      );
    },
    closePedido() {
      this.$bvModal.hide("modal-pedido-ifd");
      this.loadPedidosPendentes();
    },
    async marcarAvisado(pedido) {
      if (pedido && pedido.cod_pedido) {
        await PedidoLib.marcarCancelamentoIfoodAvisado(pedido.cod_pedido);
        this.$bvModal.hide("modal-cancelado-ifd");
        this.cancelados = this.cancelados.filter(
          (x) => x.cod_pedido != pedido.cod_pedido
        );
        this.loadPedidosCanceladosAConfirmar();
      }
    },
    async openPedido(pedidoOpen) {
      console.log("pedidoOpen", pedidoOpen);
      this.showPOP = false;
      let pedido = await PedidoLib.get({
        cod_pedido: pedidoOpen.cod_pedido,
        cod_empresa: pedidoOpen.cod_empresa,
      });
      if (pedido && pedido.success && pedido.data && pedido.data.length > 0) {
        pedido = pedido.data[0];
      }

      console.log("PEDIDO", pedido);
      this.pedidoOpen = pedido;
      this.$bvModal.show("modal-pedido-ifd");
    },
    closeModalWidget() {
      this.stopTimer();
      this.startTimer();
      this.filterStatus = "pendente";
      this.loadDisputas();
    },
    openDisputas() {
      this.stopTimer();
      this.$bvModal.show("modal-disputas");
    },
    stopTimer() {
      clearInterval(this.tmr);
      this.tmr = null;
    },
    async startTimer() {
      if (
        this.integrations &&
        this.integrations.length > 0 &&
        this.$store.state.auth.user.ifood == 1
      ) {
        if (this.tmr) {
          clearInterval(this.tmr);
          this.tmr = null;
        }
        if (
          this.$store.state.merchantIds &&
          this.$store.state.merchantIds.length > 0
        ) {
          this.tmr = setInterval(async () => {
            this.execTimer();
          }, 10000);
        }
      }
    },
    async execTimer() {
      // console.log("*****TIMER IFOOD*****", this.verificando, new Date());
      if (
        this.integrations &&
        this.integrations.length > 0 &&
        this.$store.state.auth.user.ifood == 1
      ) {
        if (this.verificando) {
          return;
        }
        this.verificando = true;
        try {
          await this.loadPedidosPendentes();
          await this.loadPedidosCanceladosAConfirmar();
          this.loadDisputas();
        } catch (e) {
          console.log("error", e);
        }
      }
      this.verificando = false;
    },
    async loadPedidosCanceladosAConfirmar() {
      if (
        this.integrations &&
        this.integrations.length > 0 &&
        this.$store.state.auth.user.ifood == 1
      ) {
        try {
          this.verificando = true;

          let res = await PedidoLib.getPedidosIfoodCancelados(
            this.$store.state.empresa_ativa.cod_empresa
          );
          // console.log("pedidosCancelados", res);
          if (res.success && res.data.length > 0) {
            this.cancelados = res.data;
          } else {
            this.cancelados = [];
          }
          if (this.cancelados.length > 0 && !this.isMuted) {
            cancelAUDIO.play();
            setTimeout(() => {
              cancelAUDIO.play();
            }, 2500);
            setTimeout(() => {
              cancelAUDIO.play();
            }, 5500);
          }
          // console.log("pedidos", pedidos);
        } catch (e) {
          console.log("error", e);
        }
      }
      this.verificando = false;
    },
    async loadPedidosPendentes() {
      if (
        this.integrations &&
        this.integrations.length > 0 &&
        this.$store.state.auth.user.ifood == 1
      ) {
        try {
          this.verificando = true;
          if (
            this.integrations &&
            this.integrations.length > 0 &&
            this.$store.state.auth.user.ifood == 1
          ) {
            let res = await PedidoLib.getPedidosIfoodPendente(
              null,
              this.integrations.map((x) => x.id)
            );
            // console.log("pedidosPendentes", res);
            if (res.success && res.data.length > 0) {
              this.pedidosPendentes = res.data;
            } else {
              this.pedidosPendentes = [];
            }
            if (this.pedidosPendentes.length > 0 && !this.isMuted) {
              cancelAUDIO.play();
              setTimeout(() => {
                cancelAUDIO.play();
              }, 2500);
              setTimeout(() => {
                cancelAUDIO.play();
              }, 5500);
            }
          }
          // console.log("pedidos", pedidos);
        } catch (e) {
          console.log("error", e);
        }
      }
      this.verificando = false;
    },
    async loadDisputas() {
      if (
        this.integrations &&
        this.integrations.length > 0 &&
        this.$store.state.auth.user.ifood == 1
      ) {
        this.verificando = true;

        // console.log("loadDisputas");
        let disputas = await DisputasCancelamentoLib.getDisputas({
          status: this.filterStatus,
          cod_empresa: this.$store.state.empresa_ativa.cod_empresa,
          integration_id: this.integrations.map((it) => it.id),
        });
        this.disputas = [];
        if (disputas && disputas.success && disputas.data.length > 0) {
          this.disputas = disputas.data;
          if (this.tmrToResponse) {
            clearInterval(this.tmrToResponse);
          }
          this.tmrToResponse = setInterval(() => {
            this.calculatePrazos();
          }, 1000);
        }
        if (this.filterStatus == "pendente" && this.disputas.length > 0 && !this.isMuted) {
          cancelAUDIO.play();
          setTimeout(() => {
            cancelAUDIO.play();
          }, 2500);
          setTimeout(() => {
            cancelAUDIO.play();
          }, 5500);
        }
      }
      this.verificando = false;
      // console.log("disputas", this.disputas);
    },
    calculatePrazos() {
      for (let disputa of this.disputas) {
        let final = moment(disputa.created_at).add(5, "minutes");
        if (
          disputa.obj &&
          disputa.obj.metadata &&
          disputa.obj.metadata.expiresAt
        ) {
          final = moment(disputa.obj.metadata.expiresAt);
        }
        // console.log("final", final.format("HH:mm:ss"));
        //moment diff in minutes:seconds format MM:ss
        if (moment().isAfter(final)) {
          disputa.prazo = "expirado";
        } else {
          disputa.prazo = moment
            .utc(moment(final).diff(moment()))
            .format("mm:ss");
          if (this.$refs.tbDisputas && this.$refs.tbDisputas.refresh) {
            this.$refs.tbDisputas.refresh();
          }
        }
      }
    },
    async loadIfoodWidget() {
      console.log("user", this.$store.state.auth.user.ifood);
      if (
        this.$store.state.empresa_ativa &&
        this.$store.state.auth.user.ifood == 1
      ) {
        this.integrations = this.$store.state.empresa_ativa.integracoes.filter(
          (x) => x.Sistema.toUpperCase() == "IFOOD" && x.Ativo
        );
        let merchantIds = [];
        this.lojas = this.integrations;
        // console.log("integrations", integrations);
        if (this.integrations && this.integrations.length > 0) {
          for (let integration of this.integrations) {
            if (integration.Adicionais) {
              let findId = integration.Adicionais.find(
                (x) => x.chave == "IdLoja"
              );
              if (findId) {
                merchantIds.push(findId.valor);
              }
            }
          }
          if (merchantIds.length > 0) {
            if (
              !this.$store.state.merchantIds ||
              this.$store.state.merchantIds.length <= 0
            ) {
              this.$store.state.merchantIds = merchantIds;

              window.iFoodWidget.init({
                widgetId: "36f0d515-232a-4730-b7a9-7a032cb89aff",
                merchantIds,
              });
            }

            console.log("merchants finded============++>", merchantIds);
            this.loadPedidosPendentes();
            this.loadDisputas();
            this.startTimer();
            this.show = true;
            this.$nextTick();
            this.$forceUpdate();
          }
          // console.log("integrations", merchantIds);
        }
      }
      this.execTimer();
    },
    getInfo(loja, campo) {
      return loja.Adicionais && loja.Adicionais.some((x) => x.chave == campo)
        ? loja.Adicionais.find((x) => x.chave == campo).valor
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.ifood-alert {
  &.show {
    display: block;
  }
  display: none;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: red;
  z-index: 1000;
  bottom: 130px;
  padding-top: 2px;
  // left: 50%;
  // margin-left: -30px;
  right: 13px;
  color: white;
  text-align: center;
  //padding-top: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  &.ring {
    animation: ifood-alert 0.5s infinite;
  }
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
  &:hover {
    animation: none;
    transform: scale(1.2);
    opacity: 1;
  }
}
//make animation of ifood-alert
@keyframes ifood-alert {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.btALERT {
  min-width: 250px;
  min-height: 50px;
  border: 1px solid #ccc;
  padding: 5px;

  color: #555;
  text-align: left;
  font-size: 15px;
  // margin: 10px;
  border-radius: 30px;

  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #f00;
    color: #f9f9f9;
    box-shadow: 0px 0px 10px rgba(155, 155, 155, 0.3);
    transform: scale(1.02);
  }
  &.disputa {
    background-color: #f00;
    color: #f9f9f9;
  }
  &.cancelado {
    background-color: #f00;
    border: 2px solid #f00;
    color: #f9f9f9;
  }
}
.ifoodPopover {
  min-width: 340px;
  min-height: 440px;
  border-radius: 30px;
  z-index: 1000000000;
}
.bot {
  background-color: #f9f9f9;
  color: #555;

  border-top: 1px solid #ccc;
  border-radius: 0 0 30px 30px;
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
    text-align: center;
    font-size: large;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    &:hover {
      background-color: #ddd;
      color: #333;
    }
  }
}
</style>
