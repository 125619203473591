<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>
          {{ obj.Nome }}
        </h1>
      </div>
      <div class="col-12">
        <div class="row my-4 dashCards">
          <div class="col-4">
            <div class="cd">
              Telefone <br />
              <h4>{{ obj?.Telefone }}</h4>
            </div>
          </div>
          <div class="col-4">
            <div class="cd">
              Nascimento <br />
              <h4>{{ obj?.dt_nascimento | moment("DD/MM/YYYY") }}</h4>
            </div>
          </div>
          <div class="col-4">
            <div class="cd">
              Saldo <br />
              <h4>{{ obj?.saldo | currency }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <h4>Últimas Aulas</h4>
        <hr />
        <div class="row">
          <div class="col-12">
            <b-table
              :items="obj.aulas"
              :fields="[
                { key: 'data', label: 'Data' },
                { key: 'aula', label: 'Aula' },
                { key: 'professor', label: 'Professor' },
                { key: 'situacao', label: 'Situação' },
              ]"
            ></b-table>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <h4>Último Acessos</h4>
        <hr />
        <div class="row">
          <div class="col-12">
            <b-table
              :items="obj.aulas"
              :fields="[
                { key: 'data', label: 'Data' },
                { key: 'tipo', label: 'Tipo' },
              ]"
            ></b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.dashCards {
  .cd {
    background-color: #f9f9f9;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
}
</style>
