div
<template>
  <div>
    <div class="text-center p-5" v-if="loading"><b-spinner /> aguarde...</div>

    <div class="row">
      <div class="col-6" v-if="!loading">
        <b-form-group title="Data" label="Data">
          <v-date-picker v-model="obj.data">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Valor">
          <b-input
            v-model="obj.valor"
            v-money="moneyMask"
            class="text-center"
            ref="txtVL"
            pattern="\d*"
          />
        </b-form-group>
      </div>
      <div class="col-12 mt-2" v-if="!carteiraId">
        <select-carteira
          :CarteiraID="obj.carteira_id"
          @change="(ev) => (obj.carteira_id = ev)"
          :showTitle="true"
        />
      </div>
      <div class="col-12 mt-2">
        <select-plano-contas
          :planoId="obj.plano_id"
          @change="(ev) => (obj.plano_id = ev)"
          :showTitle="true"
          :required="false"
          :show-manager="true"
        />
      </div>
      <div class="col-12 mt-2">
        <select-tipo-h-istorico
          :showTitle="true"
          :tipoHistoricoID="obj.tipo_historico_id"
          @change="(ev) => (obj.tipo_historico_id = ev)"
        />
      </div>
      <div class="col-12 mt-2">
        
        <select-centro-custo
          :showTitle="true"
          :showManager="true"
          :custo-id="obj.cod_custo"
          @change="(ev) => (obj.cod_custo = ev)"
        />
      </div>
      <div class="col-12 mt-2">
        <select-fornecedor
          :showTitle="true"
          :showManager="true"
          :fornecedor-id="obj.fornecedor_id"
          @change="(ev) => (obj.fornecedor_id = ev)"
        />
      </div>
      <div class="col-12 mt-2">
        <b-form-group label="Observações">
          <b-textarea v-model="obj.observacao" />
        </b-form-group>
      </div>

      <div class="col-6 mt-2">
        <b-check v-model="obj.consolidado" value="S" unchecked-value="N" switch
          >Consolidado
          <br />
          <small
            >Significa que o registro foi consolidado (efetivado).</small
          ></b-check
        >
      </div>
      <div class="col-5" v-if="obj.consolidado == 'S'">
        <b-form-group label="Data da Conciliação">
          <v-date-picker v-model="obj.dt_consolidado" required>
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 text-center"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </div>

      <div class="col-12 mt-1" v-if="obj.ligacao">
        <hr />
        <b-card body-class="p-2">
          <b
            ><i class="fas fa-exchange-alt mr-1"></i> Transferencia entre contas
          </b>

          <table class="table tbs">
            <tr>
              <th><b-icon-asterisk class="mr-1" /> Código</th>
              <td>{{ obj.ligacao.id }}</td>
            </tr>
            <tr>
              <th><i class="fa fa-calendar mr-1" /> Data</th>
              <td>{{ obj.ligacao.data | moment("DD/MM/YYYY") }}</td>
            </tr>
            <tr>
              <th><i class="fa fa-wallet mr-1" /> Conta / Carteira</th>
              <td>{{ obj.ligacao.Carteira }}</td>
            </tr>
            <tr>
              <th><i class="fa fa-cogs" /> Historico</th>
              <td>{{ obj.ligacao.historico }}</td>
            </tr>
            <tr>
              <th><i class="far fa-comment-alt mr-1"></i> Observações</th>
              <td>{{ obj.ligacao.observacao }}</td>
            </tr>
            <tr>
              <th><i class="fa fa-user mr-1" /> Usuário</th>
              <td>{{ obj.ligacao.User }}</td>
            </tr>
            <tr>
              <th colspan="2">
                <b-btn variant="none" @click="abrirLancamento(obj.ligacao.id)"
                  ><b-icon-arrow-bar-left /> Abrir Lançamento</b-btn
                >
              </th>
            </tr>
          </table>
        </b-card>
        <hr />
      </div>

      <div class="col-12 mt-1" v-if="obj.cx_data" style="font-size: 10px">
        <hr />
        <b-card body-class="p-2">
          <b>Lançamento Vinculado Caixa </b>
          <table class="table tbs">
            <tr>
              <th>Data</th>
              <td>{{ obj.cx_data | moment("DD/MM/YYYY") }}</td>
            </tr>
            <tr>
              <th>Empresa</th>
              <td>
                {{ obj.cx_cod_empresa }}
              </td>
            </tr>
            <tr>
              <th>{{ obj.cx_cod_caixa != "" ? "CAIXA" : "MOTO" }}</th>
              <td>
                {{
                  obj.cx_cod_caixa != "" ? obj.cx_cod_caixa : obj.cx_cod_moto
                }}
              </td>
            </tr>
            <tr>
              <th>Referência</th>
              <td>
                {{ obj.cx_ref }}
              </td>
            </tr>
            <tr>
              <th>Valor Original</th>
              <td>
                {{ obj.cx_valor | currencyMask }}
              </td>
            </tr>
          </table>
        </b-card>
        <hr />
      </div>

      <div class="col-12 mt-2 d-flex justify-content-arround">
        <b-btn
          variant="danger"
          v-if="obj.id > 0"
          class="mr-1"
          @click="excluirMov"
          ><b-icon-trash /> Excluir Lançamento</b-btn
        >
        <div v-if="obj.cx_data && obj.id" class="text-left">
          <b-btn variant="warning" class="" @click="openExtrato"
            ><i class="fa fa-wallet" /> Extrato</b-btn
          >
        </div>
        <hr />
        <b-btn variant="success" @click="salvar" :disabled="salvando">
          <b-spinner small v-if="salvando" />
          <i class="far fa-save" v-if="salvando" /> Salvar</b-btn
        >
        <b-btn variant="danger" class="ml-2" @click="$emit('cancelar')"
          ><b-icon-x-circle /> Cancelar</b-btn
        >
      </div>
    </div>
    <b-modal
      id="modal-extrato"
      hide-footer
      hide-header
      size="xl"
      @hidden="buscarItem"
    >
      <movimentacao-lista
        :carteiraSelecionadaId="obj.carteira_id"
        :anoSelecionado="ano"
        :mesSelecionado="mes"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import TipoHistorico from "../../../libs/BancoTipoHistoricoLib";
import CarteiraLib from "../../../libs/Carteiras";
import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
import SelectCarteira from "./SelectCarteira.vue";
import SelectPlanoContas from "../SelectPlanoContas.vue";
import PlanoContasLib from "../../../libs/PlanoContasLib";
import SelectCentroCusto from "../SelectCentroCusto.vue";
import SelectFornecedor from "../../Fornecedores/SelectFornecedor.vue";
import CaixasLIB from "../../../libs/Caixas";
// import SelectPlanoContas from '../SelectPlanoContas.vue';

export default {
  name: `MovimentoForm${Math.random()}`,
  components: {
    SelectCarteira,
    SelectTipoHIstorico: () => import("./SelectTipoHIstorico.vue"),
    SelectPlanoContas,
    MovimentacaoLista: () => import("./MovimentacaoLista.vue"),
    SelectCentroCusto,
    SelectFornecedor,
  },
  props: {
    carteiraId: Number,
    mes: {
      type: Number,
      default: parseInt(moment().format("MM")),
    },
    ano: {
      type: Number,
      default: parseInt(moment().format("YYYYY")),
    },
    itemEdit: {
      type: Object,
      default: null,
    },
    startObj: {
      type: Object,
      default: null,
    },
    tipo: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.itemEdit && this.itemEdit.id) {
      this.loading = true;
      this.$forceUpdate();
    }
    // await this.carregarTiposHistorico();
    // await this.carregarContas();
    this.buscarItem();
    if (this.startObj) {
      this.obj = Object.assign({ ...this.obj }, { ...this.startObj });
      this.obj.valor = this.formatFloatToReal(this.startObj.valor);
      this.obj.observacao = this.startObj.obs;
      this.obj.tipo_historico_id = this.startObj.historico_id;
      this.obj.carteira_id = this.startObj.carteira_id;
      //this.obj.consolidado = "S";
      if (this.startObj.data) {
        this.obj.data = moment(this.startObj.data).format(
          "YYYY-MM-DD 23:00:00"
        );
        console.log("seting data", this.obj.data);
      }

      delete this.obj.historico_id;
      delete this.obj.obs;
      delete this.startObj.historico_id;
      delete this.startObj.obs;

      console.log("teste", this.obj, this.startObj);
    }
    //this.obj.consolidado = "S";
    this.$nextTick(() => {
      this.buscarConfiguracao();
    });

    this.buscarCentroCusto();
  },
  data() {
    return {
      tiposHistorico: [],
      contasDisponiveis: [],
      loading: false,
      salvando: false,

      obj: {
        tipo_historico_id: 0,
        carteira_id: this.carteiraId,
        data: moment().format("YYYY-MM-DD 00:00:00"),
        consolidado: "N",
        tipo: null,
        valor: 0,
        observacao: "",
        id: null,
        dt_consolidado: null,
        cx_data: null,
        cx_cod_empresa: null,
        cx_cod_caixa: null,
        cx_cod_moto: null,
        cx_ref: null,
        cx_valor: null,
        plano_id: null,
        cod_custo: null,
        fornecedor_id: null,
      },
    };
  },
  watch: {
    "obj.consolidado": function () {
      if (this.obj.consolidado === "S") {
        this.obj.dt_consolidado = moment(this.obj.data)
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00");
      }
    },
    itemEdit: function () {
      this.loading = true;
      this.$forceUpdate();
      this.buscarItem();
      // this.buscarCentroCusto();
    },
    "obj.plano_id": async function () {
      console.log("obj.plano_id", this.obj.plano_id);
      if (this.obj.plano_id) {
        let find = await PlanoContasLib.getPlanoContas({
          id: this.obj.plano_id,
        });
        if (find) {
          if (find.data && find.data.length > 0) {
            this.obj.tipo_historico_id = find.data[0].tipo == 1 ? 1 : 2;
          }
        }
      }
    },
  },
  computed: {},
  methods: {
    async buscarCentroCusto() {
      if (this.startObj && this.startObj.cx_cod_empresa) {
        console.log("finding caixas", this.startObj.cx_cod_empresa);
        let caixas = await CaixasLIB.getAll(this.startObj.cx_cod_empresa);
        console.log("finding caixas", caixas, this.startObj.cx_cod_caixa);

        let has = caixas.find((x) => x.nome == this.startObj.cx_cod_caixa);
        console.log("finding caixas", has);
        if (has && has.cod_custo) {
          this.obj.cod_custo = has.cod_custo;
          this.startObj.cod_custo = has.cod_custo;
          this.itemEdit.cod_custo = has.cod_custo;
          console.log("edited", this.obj, this.startObj, this.itemEdit);
        }
      }
    },
    buscarConfiguracao() {
      if (
        this.startObj &&
        this.startObj.cx_cod_empresa &&
        this.startObj.cx_ref
      ) {
        let chaves = this.getChavesConfig();
        console.log("Buscando configs", chaves);
        let conf = localStorage.getItem("configuracoes_banco_movimento");
        if (conf && conf != "") {
          conf = JSON.parse(conf);
          console.log("Buscando configs 2", conf);

          if (conf && conf.length > 0) {
            let has = conf.find(
              (x) =>
                x.chaves.cx_cod_empresa == this.startObj.cx_cod_empresa &&
                x.chaves.cx_ref == this.startObj.cx_ref &&
                x.chaves.cx_cod_caixa == this.startObj.cx_cod_caixa &&
                x.chaves.cx_cod_moto == this.startObj.cx_cod_moto &&
                x.chaves.tipo == this.tipo
            );
            console.log("Buscando configs 3", has);

            if (has) {
              this.obj.tipo_historico_id =
                has.fields["tipo_historico_id"] || null;
              this.obj.carteira_id = has.fields["carteira_id"] || null;
              this.obj.plano_id = has.fields["plano_id"] || null;
              this.obj.cod_custo =
                has.fields["cod_custo"] || this.obj.cod_custo;
              this.obj.fornecedor_id = has.fields["fornecedor_id"] || null;
            }
          }
        }
      }
    },
    gravarConfiguracao(chaves, fields) {
      if (
        this.startObj &&
        this.startObj.cx_cod_empresa &&
        this.startObj.cx_ref
      ) {
        console.log("gravando configs", chaves, fields);
        let conf = localStorage.getItem("configuracoes_banco_movimento");
        if (conf && conf != "") {
          conf = JSON.parse(conf);
        }
        if (!conf) {
          conf = [
            {
              chaves,
              fields,
            },
          ];
        } else {
          let has = conf.findIndex(
            (x) =>
              x.chaves.cx_cod_empresa == this.startObj.cx_cod_empresa &&
              x.chaves.cx_ref == this.startObj.cx_ref &&
              x.chaves.cx_cod_caixa == this.startObj.cx_cod_caixa &&
              x.chaves.cx_cod_moto == this.startObj.cx_cod_moto &&
              x.chaves.tipo == this.tipo
          );
          if (has >= 0) {
            conf[has].fields = fields;
          } else {
            conf.push({
              chaves,
              fields,
            });
          }
        }
        localStorage.setItem(
          "configuracoes_banco_movimento",
          JSON.stringify(conf)
        );
      }
    },
    getChavesConfig() {
      if (
        this.startObj &&
        this.startObj.cx_cod_empresa &&
        this.startObj.cx_ref
      ) {
        return {
          cx_cod_empresa: this.startObj.cx_cod_empresa,
          cx_ref: this.startObj.cx_ref,
          cx_cod_caixa: this.startObj.cx_cod_caixa,
          cx_cod_moto: this.startObj.cx_cod_moto,
          tipo: this.tipo,
          plano_id: this.startObj.plano_id,
          fornecedor_id: this.startObj.fornecedor_id,
          cod_custo: this.startObj.cod_custo,
        };
      } else {
        return {};
      }
    },
    getFieldsConfig() {
      return {
        tipo_historico_id: this.obj.tipo_historico_id,
        carteira_id: this.obj.carteira_id,
        plano_id: this.obj.plano_id,
        tipo: this.tipo,
        fornecedor_id: this.obj.fornecedor_id,
        cod_custo: this.obj.cod_custo,
      };
    },
    openExtrato() {
      this.$bvModal.show("modal-extrato");
    },
    abrirLancamento(id) {
      this.itemEdit = {
        id,
      };
      this.buscarItem();
    },
    excluirMov() {
      this.$swal({
        title: "Tem certeza?",
        text: "Deseja excluir esta movimentação?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não.",
      }).then(async (res) => {
        if (res.isConfirmed) {
          await BancoMovimentoLib.delMovimento(this.obj.id);
          this.$emit("cancelar");
          //this.$emit("atualizar");
          //this.$root.$emit("atualizaListaDeCarteiras");
          // this.$swal("Excluido com sucesso", "", "success");
        }
      });
    },
    async carregarContas() {
      this.contasDisponiveis = (await CarteiraLib.getCareteiras()).data;
      console.log("cariteras", this.contasDisponiveis);
    },
    async carregarTiposHistorico() {
      this.tiposHistorico = (await TipoHistorico.getTipoHistorico()).data;
    },
    async salvar() {
      let err = [];
      if (!this.obj.tipo_historico_id) {
        err.push("Informe um tipo de histórico!");
      }
      if (!this.obj.valor || this.formatValorReal(this.obj.valor) <= 0) {
        err.push("Informe um valor válido!");
      }
      if (this.obj.consolidado == "S" && !this.obj.dt_consolidado) {
        err.push("Informe a data da conciliação!");
      }
      if (!this.obj.carteira_id) {
        err.push("Informe uma conta/carteira!");
      }

      if (err.length > 0) {
        this.$swal("Atenção", err.join("<br/>"), "error");
      } else {
        this.salvando = true;
        try {
          this.obj.tipo = this.tiposHistorico.find(
            (x) => x.id === this.obj.tipo_historico_id
          )?.tipo;

          let res = await BancoMovimentoLib.storeMovimento(this.obj);
          console.log("rtesd", res);
          if (res && res.success) {
            if (
              this.obj.cx_data &&
              this.obj.cx_cod_empresa &&
              this.obj.cx_ref
            ) {
              let chaves = this.getChavesConfig();
              let fields = this.getFieldsConfig();
              console.log("fields config", fields);
              this.gravarConfiguracao(chaves, fields);
              console.log("gravou config", chaves, fields);
            }
            this.$swal({
              title: "Concluído",
              text: "Movimento salvo com sucesso!",
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 500,
            }).then(() => {});
            this.$emit("cancelar");
          } else {
            this.$swal("Atenção", "Erro ao salvar!", "error");
          }
        } catch (error) {
          console.log("ERROR ON SAVE BANCO MOVIMENTO", error);
        }
        this.salvando = false;
      }
    },
    async buscarItem() {
      this.loading = true;
      try {
        this.obj = {
          tipo_historico_id: 0,
          carteira_id: this.carteiraId,
          data: moment().format("YYYY-MM-DD 10:00:00"),
          consolidado: "N",
          tipo: null,
          valor: "R$ 0,00",
          observacao: "",
          id: null,
          dt_consolidado: null,
          plano_id: null,
        };
        if (this.itemEdit && this.itemEdit.id) {
          console.log("finding", this.itemEdit.id);
          let ob = (
            await BancoMovimentoLib.getMovimento({
              "banco_movimentos.id": this.itemEdit.id,
            })
          ).data[0];
          console.log("finded", ob);
          ob.valor = this.formatFloatToReal(
            ob.tipo == -1 ? ob.valor * -1 : ob.valor
          );
          let vl = Object.assign({}, { ...ob });
          // console.log(ob.valor,this.$refs.txtVL);

          this.obj = ob;
          setTimeout(() => {
            console.log(123, vl);
            this.obj.valor = vl.valor;
          }, 200);
          this.$forceUpdate();
        }
      } catch (error) {
        console.log("Erro on buscarItem", error);
      }
      // this.buscarConfiguracao();

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tbs {
  td,
  th {
    padding: 3px;
  }
}
</style>
