<template>
  <div>
    <label v-if="showTitle"
      ><i class="fa fa-wallet mr-1" /> Carteira / Conta</label
    >
    <div class="d-flex justify-content-around">
      <v-select
        :options="contasDisponiveis"
        v-model="carteira_id"
        label="nome"
        :reduce="
          (vl) => {
            return this.retorno == 'id' ? vl.id : v;
          }
        "
        :style="showManager ? 'min-width: 80%' : 'min-width: 100%'"
        :multiple="multiple"
        :clearable="true"
      />

      <b-btn
        size="sm"
        @click="$bvModal.show('modal-manut-select-carteiras')"
        v-if="showManager"
        ><i class="fa fa-cogs"></i
      ></b-btn>
      <b-modal
        id="modal-manut-select-carteiras"
        size="lg"
        title="Gerenciamento de Carteiras"
        hide-footer
        @hidden="carregarContas"
      >
        <carteiras />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CarteiraLib from "../../../libs/Carteiras";
import Carteiras from "../../../views/Configuracoes/Financeiro/Carteiras.vue";
// import CarteirasForm from '../Carteiras/CarteirasForm.vue';

export default {
  components: { Carteiras },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    showManager: {
      type: Boolean,
      default: false,
    },

    CarteiraID: [String, Number, Object],
    retorno: {
      type: String,
      default: "id",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregarContas();
  },
  data() {
    return {
      contasDisponiveis: [],
      carteira_id: null,
    };
  },
  watch: {
    carteira_id: function () {
      this.$emit("change", this.carteira_id);
    },
    CarteiraID: function () {
      this.carteira_id = this.CarteiraID;
    },
  },
  computed: {},
  methods: {
    async carregarContas() {
      this.contasDisponiveis = (await CarteiraLib.getCareteiras()).data.sort(
        (a, b) => {
          return a.ordem > b.ordem ? 1 : -1;
        }
      );
      // console.log("cariteras", this.contasDisponiveis);
      if (this.CarteiraID) {
        this.carteira_id = parseInt(this.CarteiraID);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
