<template>
  <div :class="{ 'text-center mt-sm-1': !noStyle }">
    <b-button
      variant="secondary"
      :disabled="loading"
      @click="solicitar(mesa ? 'mesas_pedido' : 'pedido')"
      class="mr-1"
      block
    >
      <i class="fa fa-print" v-if="!loading" />
      <b-spinner small variant="primary" v-if="loading" />
      Imprimir Pedido
    </b-button>
    <b-button
      variant="secondary"
      :disabled="loading"
      @click="solicitar('senha')"
      block
    >
      <i class="fa fa-tag" v-if="!loading" />
      <b-spinner small variant="primary" v-if="loading" />
      Imprimir Senha
    </b-button>
  </div>
</template>

<script>
import PedidoLib from "../../libs/Pedido";
export default {
  props: {
    codPedido: {
      type: Number,
      default: 0,
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      default: 0,
    },
    mesa: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async solicitar(tipo, bypass=false) {
      if (this.codPedido > 0) {
        this.loading = true;

        let resu = { isConfirmed: false };
        if (!bypass) {
          resu = await this.$swal.fire({
            title: "ATENÇÃO!",
            html: "Se você alterous os dados do pedido na tela você precisa atualizar o pedido <b>ANTES</b> de solicitar impressão novamente!",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "Apenas Imprimir",
            confirmButtonText: "Atualizar & Imprimir",
          });
        }

        if (bypass || !resu.isConfirmed) {
          const res = await PedidoLib.solicitarImpressao(
            tipo,
            this.codPedido,
            this.empresa,
            this.$store.state.caixa_ativo.cod_caixa
          );
          if (res) {
            this.$swal.fire({
              title: "Impressão solicitada com sucesso",
              icon: "success",
              showConfirmButton: false,
              toast: true,
              position: "top-end",
              timer: 1500,
            });
          } else {
            this.$swal.fire({
              title: "Erro ao solicitar impressão",
              icon: "error",
              showConfirmButton: false,
              toast: true,
              position: "top-end",
              timer: 1500,
            });
          }
        } else {
          this.$emit('updatePrint',tipo);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>