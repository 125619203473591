var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-12 col-sm-12 mt-3",class:{}},[_c('div',{staticClass:"d-sm-flex justify-content-between w-100"},[_c('b-input',{ref:"txtFiltrar",staticClass:"form-control",style:('max-width:300px;'),attrs:{"type":"text","placeholder":"buscar..."},on:{"keypress":_vm.EnterProd,"focus":function($event){return _vm.$refs.txtFiltrar.select()}},model:{value:(_vm.filtrar),callback:function ($$v) {_vm.filtrar=$$v},expression:"filtrar"}}),(_vm.subgrupos && _vm.subgrupos.length > 0)?_c('div',{staticClass:"px-sm-4 py-2 bg-light d-flex scrollPersonalizadoH",staticStyle:{"flex":"1","overflow":"hidden","overflow-x":"auto"}},_vm._l((_vm.subgrupos),function(sg){return _c('b-button',{key:`key_${sg.Tipo}_${Math.random()}`,staticClass:"btn-sm mr-2",staticStyle:{"font-size":"9px"},attrs:{"title":sg.Tipo,"variant":_vm.selectedGroups.indexOf(sg) >= 0 ? 'info' : 'outline-secondary'},on:{"click":function($event){return _vm.selectGroup(sg)}}},[_vm._v(" "+_vm._s(sg.Tipo)+" ")])}),1):_vm._e()],1)]),_c('div',{staticClass:"produtos",class:{ produtosListaMaior: this.grupoSelecionado.Grupo != 'PIZZA' },style:(!_vm.isMobile
        ? `max-height:${
            _vm.grupoSelecionado.Grupo == 'PIZZA'
              ? _vm.isMobile
                ? '60vh'
                : '32vh'
              : '550px; height: auto'
          }`
        : ``)},_vm._l((_vm.produtosShow),function(grp){return _c('div',{key:`key_Grp_${grp.Tipo}_${Math.random()}`,staticClass:"grp"},[_c('div',{staticClass:"grpTitulo"},[_vm._v(_vm._s(grp.Tipo))]),_vm._l((grp.produtos),function(opc){return _c('CardapioItem',{key:opc.cod_produto,ref:`prod_${opc.cod_produto}`,refInFor:true,attrs:{"empresaSelecionada":_vm.empresaSelecionada,"item":opc,"preco_tipo":_vm.preco_tipo,"add":_vm.add,"selecionado":_vm.opc_selecionada.filter((x) => x.cod_produto == opc.cod_produto)
            .length > 0,"withPrice":_vm.grupoSelecionado.Grupo != 'PIZZA',"mesa":_vm.mesa},nativeOn:{"click":function($event){return _vm.add(opc)},"dblclick":function($event){return (()=>{_vm.add(opc); _vm.close()}).apply(null, arguments)}}})})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }