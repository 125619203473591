<template>
  <div>
    <div v-if="enableTitle"><b-icon-upload /> Anexar</div>
    <div
      class="files"
      :class="{ freeStyle: freeStyle }"
    >
      <div
        class="file"
        :class="`file ${freeStyle ? 'freeStyleFile' : ''}`"
        v-for="file in lista"
        :key="`file_${lista.indexOf(file)}`"
      >
        <div
          class="p-4 text-center"
          v-if="file.status < 2"
        >
          <b-spinner /> <br />
          aguarde...
        </div>
        <img
          v-if="file.type == 'img' && file.status == 2 && file.fileURL"
          style="height: 100%"
          :src="file.fileURL"
        />
        <i
          class="fa fa-file-contract"
          v-if="
            file.type == 'doc' && file.name.includes('.pfx') && file.status == 2
          "
        />
        <i
          class="fa fa-file-pdf"
          v-if="
            file.type == 'doc' &&
            !file.name.includes('.pfx') &&
            file.status == 2
          "
        />

        <div
          class="default"
          v-if="file.isDefault"
        >
          <b-icon-check-circle-fill />
        </div>
        <div class="opcs">
          <div
            class="opc"
            v-if="setDefault"
            @click="checkDefault(file)"
          >
            <b-icon-check-circle />
          </div>
          <div
            class="opc"
            @click="view(file.fileURL)"
          >
            <b-icon-eye />
          </div>
          <div
            class="opc"
            @click="remove(file)"
            v-if="enableEdit"
          >
            <b-icon-trash-fill />
          </div>
        </div>
      </div>
      <div
        :class="` ${freeStyle ? 'freeStyleFile' : ''} file plus`"
        @click="openFile"
        v-if="enableEdit && (!lista || lista.length < maxFiles)"
      >
      <b-icon-plus-circle v-if="!photoIcon" />
      <b-icon-camera v-if="photoIcon" />

      </div>
    </div>
    <input
      type="file"
      class="fl"
      :id="refFile"
      @change="changeFile"
      :ref="refFile"
      style="width: 0px; height: 0px; visibility: hidden; diplay: none"
    />
    <b-modal
      id="modal-view"
      hide-footer
      size="lg"
    >
      <b-btn
        class="mb-3"
        variant="info"
        @click="girar(0)"
        ><b-icon-arrow-clockwise /> Girar</b-btn
      >
      <img
        :src="imgView"
        style="width: 100%"
        :style="`transform:${rotateStyle}; height: calc(height+200)`"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import moment from "moment";

export default {
  name: "FileUpload" + Math.random(),
  components: {},
  props: {
    freeStyle: {
      type: Boolean,
      default: false,
    },
    value: Array,
    enableTitle: {
      type: Boolean,
      default: true,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    toBucket: {
      type: Boolean,
      default: false,
    },
    setDefault: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    photoIcon:{
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    // console.log("lista", this.lista);
    this.fillLista();
  },
  data() {
    return {
      lista: [],
      imgView: "",
      rotate: 0,
      rotateStyle: "rotate(0deg)",
      refFile: "FileRef" + parseInt(Math.random() * 1000),
    };
  },
  watch: {
    value: function () {
      this.fillLista();
    },
    lista: function () {
      // console.log("lista", this.lista);
      for (let file of this.lista.filter((x) => x.status == 0)) {
        let formData = new FormData();
        formData.append("file", file.file);
        if (this.toBucket) {
          formData.append("toBucket", true);
        }
        file.status = 1;
        console.log(formData);
        axios
          .post("/uploadFile", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((x) => {
            console.log(x);
            if (x.data) {
              file.fileURL = this.toBucket
                ? x.data
                : `${axios.defaults.baseURL}download/${
                    x.data
                  }?token=${axios.defaults.headers["common"][
                    "Authorization"
                  ].replace("Bearer ", "")}`;
              file.fileThumbUrl =
                file.fileURL.split("/").slice(0, -1).join("/") +
                "/thumb_" +
                file.fileURL.split("/")[file.fileURL.split("/").length - 1];
              file.status = 2;
              file.localFileName = x.data;
              console.log(file);
              this.$emit("input", this.lista);
            }
          });
      }

      this.$emit("input", this.lista);
    },
  },
  computed: {},
  methods: {
    fillLista() {
      this.lista = this.value || [];
    },
    checkDefault(file) {
      this.lista.forEach((it) => {
        if (typeof it == "string") {
          it = {
            fileURL: it,
            type: "img",
            status: 2,
            isDefault: false,
          };
        } else {
          it.isDefault = false;
        }
      });
      if (typeof file == "string") {
        file = {
          fileURL: file,
          type: "img",
          status: 2,
          isDefault: false,
        };
      }
      file.isDefault = true;
      console.log("file", file);
      this.$forceUpdate();
    },
    girar(zero = false) {
      this.rotate += 90;
      if (this.rotate > 180) {
        this.rotate = 0;
      }
      if (zero) this.rotate = 0;
      this.rotateStyle = `rotate(${this.rotate}deg)`;
    },
    view(file) {
      this.girar(true);
      console.log(file);
      if (
        file.toLowerCase().indexOf(".jpg") >= 0 ||
        file.toLowerCase().indexOf(".svg") >= 0 ||
        file.toLowerCase().indexOf(".bmp") >= 0 ||
        file.toLowerCase().indexOf(".jpeg") >= 0 ||
        file.toLowerCase().indexOf(".gif") >= 0 ||
        file.toLowerCase().indexOf(".png") >= 0 ||
        file.toLowerCase().indexOf(".webp") >= 0
      ) {
        this.imgView = file;
        this.$bvModal.show("modal-view");
      } else {
        window.open(file);
      }
    },
    openFile() {
      console.log(this.refFile);
      this.$refs[this.refFile].click();
      // document.querySelector(this.refFile).click();
    },
    changeFile(ev) {
      console.log(ev.target.files);
      if (!this.lista) {
        this.lista = [];
      }
      if (ev.target.files && ev.target.files.length > 0) {
        for (let file of ev.target.files) {
          this.lista.push({
            name: file.name,
            status: 0,
            cod_usuario: this.user.cod_usuario,
            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            type:
              file.name.toLowerCase().indexOf(".jpg") > 0 ||
              file.name.toLowerCase().indexOf(".jpeg") > 0 ||
              file.name.toLowerCase().indexOf(".svg") > 0 ||
              file.name.toLowerCase().indexOf(".png") > 0 ||
              file.name.toLowerCase().indexOf(".gif") > 0 ||
              file.name.toLowerCase().indexOf(".webp") > 0 ||
              file.name.toLowerCase().indexOf(".bmp") > 0
                ? "img"
                : "doc",
            file,
          });
        }
        console.log("lista files", this.lista);
        ev.target.value = null;
      }
    },
    remove(file) {
      this.lista = this.lista.filter((x) => x != file);
      this.$nextTick(() => {
        this.$emit("input", this.lista);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.freeStyle {
  padding: 0px !important;
  margin: 0 auto !important;
  width: 100% !important;
  min-height: 100% !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.files {
  overflow-x: auto;
  padding: 10px;

  // display: flex;
  // flex-direction: row;

  .file {
    margin: 5px;
    border: 1px solid #d8d8d8;
    background-color: #ededed;
    width: 90% !important;
    max-width: 180px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    position: relative;
    .freeStyleFile {
      padding: 0px !important;
      margin: 0 auto !important;
      width: 100% !important;
      float: auto !important;
    }

    .default {
      position: absolute;
      top: -50px;
      left: -40px;
      color: white;
      font-size: 140px;
      opacity: 0.5;
      z-index: 1;
    }

    i {
      top: 50%;
      margin-top: -25px;
      margin-left: -20px;
      position: absolute;
      font-size: 50px;
      color: #797777;
    }

    .opcs {
      z-index: 1;
      position: absolute;
      top: 50%;
      margin-top: -30px;
      width: 100%;
      height: 60px;

      justify-content: space-around;
      display: none;
      &:hover {
        color: #444;
      }
    }
    .opc {
      // display: none;

      // position:absolute;
      top: 30%;
      background-color: #1f1f1f60;
      color: white;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      padding-top: 10px;
      font-size: 20px;
      text-align: center;
      border-radius: 100%;
      // left: 50%;
      // margin-left: -25px;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        background-color: #f2f2f2e9;
        color: #444;
      }
    }
    &:hover {
      .opcs {
        display: flex;
      }
    }
  }
  .plus {
    font-size: 78px;
    cursor: pointer;
    background-color: #f1f1f1;
    opacity: 0.3;
    border: none;
    color: #999;
    padding: 0px;
    text-align: center;
    padding-top: 0px !important;
    &:hover {
      opacity: 1;
      box-shadow: 0px 0px 6px #00000020;
    }
  }
  .fl {
    width: 0px;
    height: 0px;
    visibility: hidden !important;
    margin: 0px;
    padding: 0px;
    font-size: 1px !important;
  }
  .freeStyleFile {
    padding: 0px !important;
    margin: 0 auto !important;
    min-width: 100% !important;
    min-height: 100% !important;
    float: auto !important;
    background: transparent !important;
    border: none !important;
    height: 250px !important;
  }
}
</style>
