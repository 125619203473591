<template>
  <div class="caixas-saidas">
    <div class="row mb-2">
      <div class="col" v-if="withData">
        <b-input type="date" v-model="data" style="max-width:200px" />
      </div>
      <div class="col text-left">
        <h5>Saídas / Retiradas {{ caixaTipo.desc }}</h5>
      </div>
      <div class="col text-right">
        <b-button
          variant="primary"
          @click="addSaida()"
          v-if="user.nivel < 2 || data == data_hoje"
        >
          <i class="fa fa-plus" /> Nova Saída
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table :fields="fields" :items="saidas">
          <template #cell(local)="row">
            <span v-if="row.item.moto">{{ row.item.moto }}</span>
            <span v-if="row.item.caixa">{{ row.item.caixa }}</span>
          </template>
          <template #cell(valor)="row">
            {{ row.item.valor | currency }}
          </template>
          <template #cell(Opções)="row">
            <!-- <button class="btn btn-info btn-sm" @click="del(row.item)">
              <i class="fa fa-print" /> Imprimir
            </button> -->
            <button class="btn btn-warning btn-sm" @click="edit(row.item)">
              <i class="fa fa-edit" /> Editar
            </button>
            <button class="btn btn-danger btn-sm" @click="del(row.item)">
              <i class="fa fa-trash" /> Excluir
            </button>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal id="modal-saida-add" title="Adicionar / Editar  Saída" hide-footer>
      <saida-form
        :model="model"
        @close="closeModal()"
        :data="data"
        :caixaTipo="caixaTipo"
        :CodEmpresa="EmpresaAtiva && EmpresaAtiva.cod_empresa?EmpresaAtiva.cod_empresa:empresa.cod_empresa"
      />
    </b-modal>
  </div>
</template>

<script>
import CaixaLib from "@/libs/Caixas.js";
import moment from "moment";
import SaidaForm from "./SaidaForm.vue";
export default {
  components: {
    SaidaForm
  },
  props: {
    withData: {
      type: Boolean,
      default: true
    },
    caixaTipo: {
      type: Object
    },
    EmpresaAtiva: Object,
    dataInicial: String
  },
  data() {
    return {
      model: {},
      saidas: [],
      user: this.$store.state.auth.user,
      empresa: this.$store.state.empresa_ativa,
      caixa: this.$store.state.caixa_ativo,
      data: moment().format("YYYY-MM-DD"),
      data_hoje: moment().format("YYYY-MM-DD"),
      fields: [
        { key: "local", label: "Caixa" },
        { key: "motivo", label: "Motivo" },
        { key: "valor", label: "Valor" },
        { key: "usu", label: "Usuário" },
        { key: "Opções", label: "Opções", class: "mxw-200" }
      ]
    };
  },
  watch: {
    dataInicial:function(){
      this.data = moment(this.$props.dataInicial.toString()).format(
        "YYYY-MM-DD"
      );
    },
    data: function() {
      this.carregar();
    },
    caixaTipo: {
      deep: true,
      handler() {
        this.fields.find(x=>x.key=='local').class=!this.caixaTipo.cod || this.caixaTipo.cod<=0?'d-block':'d-none';

        this.carregar();
      }
    },
    EmpresaAtiva: {
      deep: true,
      handler() {
        this.empresa_ativa = this.$props.EmpresaAtiva;

        this.carregar();
      }
    }
  },
  mounted() {
    if (this.$props.dataInicial) {
      this.data = moment(this.$props.dataInicial.toString()).format(
        "YYYY-MM-DD"
      );
    }

    if (this.$props.caixaId > 0) {
      this.caixa = this.$store.state.caixas.find(
        x => x.cod_caixa == this.$props.caixaId
      );
    }
    if (this.$props.EmpresaAtiva) {
      this.empresa_ativa = this.$props.EmpresaAtiva;
    }

    this.carregar();
  },
  methods: {
    edit(item) {
      // item.valor = parseFloat(item.valor)
      //   .toFixed(2)
      //   .replace(".", ",");
      this.model = item;
      // if (typeof this.model.valor == "number")
      //   this.model.valor =
      //     "R$ " + this.model.valor.toFixed(2).replace(".", ",");
      // console.log(this.model);
      this.$bvModal.show("modal-saida-add");
    },
    del(item) {
      this.$swal
        .fire({
          title: "Deseja excluir?",
          label:
            "Tem certeza que deseja excluir? Não é possível reverter o processo!",
          icon: "question",
          showConfirmButton: true,
          confirmButtonText: "Sim! Excluir.",
          showDenyButton: true,
          denyButtonText: "Não.",
          showCancelButton: false
        })
        .then(async x => {
          if (x.isConfirmed) {
            // console.log(x, item);
            const res = await CaixaLib.delSaida(item);
            if (res && res.success) {
              this.$swal({
                title: "Conclúido",
                label: "Caixa excluido com sucesso!",
                icon: "success"
              }).then(() => {
                this.carregar();
                this.$emit('updateCaixas')
              });
            }
          }
        });
    },
    addSaida() {
      this.model = {};
      this.$bvModal.show("modal-saida-add");
    },
    async carregar() {
      // console.log('saidas',this.empresa.cod_empresa)
      this.saidas = await CaixaLib.getSaida(
        (this.$props.EmpresaAtiva?this.$props.EmpresaAtiva:this.empresa).cod_empresa,
        this.caixaTipo.tipo,
        this.caixaTipo.cod,
        this.data
      );
      // console.log("=====", this.saidas);
    },
    closeModal() {
      this.$bvModal.hide("modal-saida-add");
      this.carregar();
      this.$emit('updateCaixas')
    }
  }
};
</script>

<style>
.mxw-200 {
  max-width: 200px;
}
</style>

<style lang="scss" scoped>
 @import "@/assets/scss/caixas.scss";
</style>