<template>
  <div>
    <label v-if="showTitle">
      <i class="fas fa-funnel-dollar mr-1" /> Plano de Contas
    </label>
    <div class="d-flex justify-content-around">
      <v-select
        label="nome"
        :style="showManager?'min-width: 80%':'min-width: 100%'"
        :multiple="multiple"
        :options="planos"
        :isRequired="required"
        :clearable="!required"
        :isClearable="!required"
        :reduce="
          (v) => {
            return v.id;
          }
        "
        v-model="plano_id"
      >
        <template v-slot:option="option">
          <h6>
            {{ option.grupo }}
            <small><b-icon-caret-right-fill /> {{ option.sub_grupo }}</small>
          </h6>

          <p>
            {{option.id}} - {{ option.nome }}
            <small :class="option.tipo == 0 ? 'text-danger' : 'text-success'">
              ({{ option.tipo == 0 ? "Débito" : "Crédito" }})
            </small>
          </p>
        </template>
      </v-select>
      <b-btn size="sm"  @click="$bvModal.show('modal-plan-contas-select')" v-if="showManager"
        ><i class="fa fa-cogs" />
      </b-btn>
      <b-modal
        id="modal-plan-contas-select"
        size="lg"
        @hidden="carregaPlanos"
        title="Gerenciar Planos de Conta"
        hide-footer
      >
        <plano-contas :noTitle="true" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import PlanoContasLib from "../../libs/PlanoContasLib";
// import TipoHistorico from '../../../views/Configuracoes/Financeiro/TipoHistorico.vue';
// import PlanoContas from '../../views/Configuracoes/Cadastros/PlanoContas/PlanoContas.vue';
import PlanoContas from "../../views/Configuracoes/Cadastros/PlanoContas/PlanoContas.vue";

export default {
  components: { PlanoContas },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    showManager: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: true,
    },
    planoId: [String, Number],
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregaPlanos();
    this.plano_id = this.planoId;
  },
  data() {
    return {
      plano_id: null,
      planos: [],
    };
  },
  watch: {
    planoId: function () {
      // console.log("recebeu tipoHistoricoID", this.tipoHistoricoID)
      this.plano_id = this.planoId;
    },
    plano_id: function () {
      this.$emit("change", this.plano_id);
    },
  },
  computed: {},
  methods: {
    async carregaPlanos() {
      this.planos = (
        await PlanoContasLib.getPlanoContas({ agrupado: false })
      ).data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
