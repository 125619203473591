<template>
  <div
    :style="!enabled ? 'background-color: #eee; opacity:0.5; padding:10px' : ''"
  >
   
    <div class="row">
      <div class="col-12 col-sm-6">
        <label>Telefone</label>
        <b-input
          v-if="true"
          :type="'text'"
          placeholder="Telefone"
          class="text-center inputMenor"
          ref="txtTel"
          pattern="\d*"
          v-model="cliente.Telefone"
          @blur="verificaCliente()"
          @keyup="cliente.status = 'type'"
          @keypress="verificaEnterTel"
          :disabled="!enabled"
        />
      </div>
      <div class="col-12 col-sm-6">
        <label>Nome</label>
        <b-input
          type="text"
          v-model="cliente.Nome"
          class="inputMenor"
          ref="Nome"
          placeholder="Nome"
          :disabled="!enabled"
        />
      </div>
      <div class="col-12 col-sm-6 pt-2">
        <label>CPF / CNPJ</label>
        <b-input
          type="text"
          v-model="cliente.CGC"
          class="inputMenor"
          ref="CPF"
          placeholder="CPF"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          @blur="validaCPF"
          :state="cpfValido"
          :disabled="!enabled"
        />
      </div>
    </div>
    <div
      class="mt-2"
      v-if="$props.tipo_entrega && $props.tipo_entrega.titulo == 'ENTREGA'"
    >
      <hr />
      <div class="row">
        <div class="col-12 col-md-5">
          <label
            >CEP
            <small v-if="loadingCEP">
              <b-spinner variant="primary" small />
              buscando...
            </small>
          </label>
          <b-input
            type="text"
            pattern="\d*"
            v-model="cliente.CEP"
            v-mask="'#####-###'"
            class="inputMenor"
            placeholder="CEP"
            @blur="buscarCEP()"
            :disabled="!enabled"
          />
        </div>
        <div class="col-12 col-md-7">
          <label>Bairro</label>

          <!-- <b-input
            type="text"
            v-model="cliente.Bairro"
            placeholder="Bairro"
            class="inputMenor"
          /> -->
          <vue-simple-suggest
            v-model="cliente.Bairro"
            placeholder="Bairro"
            class="inputMenor"
            :list="listBairros"
            :styles="autoCompleteStyle"
            :destyled="false"
            :filter-by-query="true"
            :max-suggestions="5"
            @keypress.native="(e) => {}"
            :disabled="!enabled"
          >
          </vue-simple-suggest>
        </div>

        <div class="col-12 col-md-8 pr-md-1">
          <label>Logradouro</label>
          <!-- <b-input
            type="text"
            v-model="cliente.Logradouro"
            placeholder="Logradouro"
            class="inputMenor"
          /> -->
          <vue-simple-suggest
            v-model="cliente.Logradouro"
            placeholder="Logradouro"
            class="inputMenor"
            :list="listLogradouros"
            :styles="autoCompleteStyle"
            :destyled="false"
            :filter-by-query="true"
            :max-suggestions="5"
            @keypress.native="(e) => {}"
            :disabled="!enabled"
          >
          </vue-simple-suggest>
        </div>
        <div class="col-12 col-md-4 pl-md-0">
          <label>Número</label>
          <b-input
            type="number"
            v-model="cliente.Numero"
            placeholder="Número"
            class="inputMenor"
            pattern="\d*"
            :disabled="!enabled"
          />
        </div>

        <div class="col-md-6 d-none">
          <div>
            <label>Cidade / UF</label>
            <b-select
              placeholder="Cidade/UF"
              v-model="cliente.Cidade"
              class="inputMenor"
              :options="options_cidades"
              :disabled="!enabled"
            />
          </div>
        </div>
        <div class="col-12">
          <label>Complemento</label>
          <b-input
            type="text"
            class="inputMenor"
            v-model="cliente.complemento"
            placeholder="Complemento"
            :disabled="!enabled"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right p-1 pr-3">
        <button
          class="btn"
          @click="openHistorico"
          :disabled="!cod_cliente || cod_cliente <= 0"
        >
          <i class="fa fa-history" />
        </button>
        <b-modal
          hide-footer
          size="lg"
          title="Histórico do Cliente"
          id="modal-historico"
        >
          <template #modal-title>
            <i class="fa fa-history" /> Histórico do Cliente {{ cliente.Nome }}
            <small><code>Ordem Decrescente</code></small>
          </template>
          <cliente-historico :cod_cliente="cod_cliente" />
        </b-modal>
      </div>
    </div>

    <div class="mt-2 row" v-if="cliente.status == 'ok'">
      <div
        class="col-12 p-4"
        :class="{ 'bg-danger text-white': cliente.negativado }"
      >
        <b-check
          switch
          v-model="cliente.negativado"
          value="1"
          button-variant="danger"
          @change="updateClient"
          @blur="updateClient"
          :disabled="!enabled"
          >CLIENTE NEGATIVADO</b-check
        >
        <label>Observações</label>
        <b-textarea
          @blur="updateClient"
          v-model="cliente.motivo_negativado"
          label="Observações"
          :disabled="!enabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import cep from "@/utils/cep";
import VueSimpleSuggest from "vue-simple-suggest/lib";
import "vue-simple-suggest/dist/styles.css";
import ClienteHistorico from "./clienteHistorico.vue";
import validador from "../../utils/validador";
export default {
  props: {
    model: Function,
    tipo: String,
    tipo_entrega: Object,
    Cliente: Object,
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueSimpleSuggest,
    ClienteHistorico,
  },
  data() {
    return {
      cliente: {
        status: "none",
        Nome: "",
        Telefone: "",
        negativado: false,
        motivo_negativado: "",
        CGC: "",
        Logradouro: '',
        complemento: '',
        Numero: '',
        Cidade: '',
        Bairro: '',
        CEP:'',
      },
      cpfValido: null,
      cod_cliente: 0,
      temHistorico: false,
      lista_ufs: [],
      lista_cidades: [],
      options_cidades: [],
      loadingCEP: false,
      listBairros: this.$store.state.enderecos.bairros.map((b) => {
        return b.bairro;
      }),
      listLogradouros: this.$store.state.enderecos.logradouros.map((b) => {
        return b.logradouro;
      }),
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control inputMenor",
        suggestItem: "list-group-item",
        suggestions: "list-group",
        maxSuggestions: 2,
      },
    };
  },
  async mounted() {
    // console.log("props", VueMask2);
    // cep.buscarUFs().then((data)=>{
    //   this.lista_ufs = data;
    //   console.log(data);
    // })
    if (this.Cliente) {
      this.cliente = Object.assign({}, { ...this.Cliente });
    }
    this.lista_cidades = [
      {
        cidade: "Franca",
        uf: "SP",
        formatado: "Franca - SP",
      },
    ];
    this.options_cidades = this.lista_cidades.map((item) => {
      return { value: item, text: item.formatado };
    });

    this.cliente.Cidade = this.options_cidades[0].value;
  },
  watch: {
    cliente: {
      deep: true,
      handler() {
        this.$emit("ch", Object.assign({}, { ...this.cliente }));
        if (this.$props.model) {
          console.log("EMIT CHANGE CLIENTE");
          this.$emit("ch", Object.assign({}, { ...this.cliente }));
          this.$forceUpdate();
        }
        if (this.cliente.CGC && this.cliente.CGC != "") {
          if (!validador.string.validarCPF(this.cliente.CGC)) {
            this.cpfValido = false;
          } else {
            this.cpfValido = true;
          }
        }
      },
      lista_cidades: function () {},
    },
    "$props.Cliente": {
      deep: true,
      handler() {
        if (this.cliente.Nome != this.$props.Cliente.Nome) {
          this.cliente = Object.assign(
            { ...this.cliente },
            { ...this.$props.Cliente }
          );
        }
        // console.log("RECEBEUUU CLI", this.cliente);
        // this.$forceUpdate();
      },
    },
  },
  methods: {
    validaCPF() {
      this.cpfValido = null;
      if (this.cliente.CGC && this.cliente.CGC != "") {
        if (
          !validador.string.validarCPF(this.cliente.CGC) &&
          !validador.string.validarCNPJ(this.cliente.CGC)
        ) {
          this.$swal(
            "CPF/CNPJ INVÁLIDO!",
            "CPF informado está incorreto!",
            "error"
          );
          this.cpfValido = false;
        } else {
          this.cpfValido = true;
        }
      }
    },
    openHistorico() {
      this.$bvModal.show("modal-historico");
    },
    clearCliente() {
      this.cliente.Telefone = "";
      this.$forceUpdate();
      this.cliente.status = "none";
      this.cliente.Nome = "";
      this.cliente.CGC = "";
      this.cliente.Bairro = "";
      this.cliente.Logradouro = "";
      this.cliente.Numero = "";
      this.cliente.complemento = "";
      this.cliente.CEP = "";
      this.cliente.Cidade = "";
      // console.log("cliente clear");
      this.$forceUpdate();
    },
    verificaEnterTel(key) {
      if (key.key == "Enter") {
        this.$refs.Nome.select();
        this.$refs.Nome.focus();
      }
    },
    updateClient() {
      axios.post("clientes/update", this.cliente);
    },
    async verificaCliente() {
      this.cod_cliente = 0;
      // console.log("buscou cliente", this.cliente);
      this.cliente.negativado = false;
      this.cliente.motivo_negativado = "";
      if (this.cliente.Telefone != "") {
        this.cliente.status = "loading";
        // console.log("telll", this.cliente.Telefone);
        if (this.cliente.Telefone.length < 8) {
          // console.log("ent");
          this.cliente.status = "error";
        } else {
          try {
            const busca = await axios.post("clientes/buscarPorTel", {
              telefone: this.cliente.Telefone.trim()
                .replace(" ", "")
                .replace("-", "")
                .match(/\d+/g)
                .join(""),
            });

            // console.log("CLIENTE", busca);

            if (busca && busca.data) {
              if (busca.data.data) {
                const cli = busca.data.data;
                this.cod_cliente = busca.data.data.cod_cliente;
                if (cli.negativado && cli.negativado == 1) {
                  this.cliente.negativado = true;
                  this.cliente.motivo_negativado = cli.motivo_negativado;
                  this.$forceUpdate();
                }
                if (cli.Telefone) {
                  cli.Telefone = parseInt(
                    cli.Telefone.replace("-", "")
                      .replace("(", "")
                      .replace(")", "")
                      .match(/\d+/g)
                      .join("")
                  ).toString();
                }
                // console.log(cli);

                this.cliente = { status: this.cliente.status, ...cli };

                if (this.cliente.CEP) {
                  this.cliente.CEP = this.cliente.CEP.match(/\d+/g).join("");
                  this.cliente.CEP =
                    this.cliente.CEP.substr(0, 5) +
                    "-" +
                    this.cliente.CEP.substr(5);
                }
                if (this.cliente.Telefone && !this.$store.state.isMobile) {
                  // if (this.cliente.Telefone.length >= 8) {
                  //   this.cliente.Telefone =
                  //     this.cliente.Telefone.substr(
                  //       0,
                  //       this.cliente.Telefone.length <= 8 ? 4 : 5
                  //     ) +
                  //     "-" +
                  //     this.cliente.Telefone.substr(
                  //       this.cliente.Telefone.length <= 8 ? 4 : 5,
                  //       4
                  //     );
                  // }
                  this.$nextTick();
                } else if (
                  this.cliente.Telefone &&
                  this.$store.state.isMobile
                ) {
                  this.cliente.Telefone = parseInt(
                    this.cliente.Telefone.match(/\d+/g).join("")
                  );
                }
                if (this.cliente.Cidade) {
                  const cid = this.options_cidades.find(
                    (x) =>
                      x.value.cidade.toUpperCase() ==
                      this.cliente.Cidade.toUpperCase()
                  );
                  if (cid) {
                    this.cliente.Cidade = cid.value;
                  } else {
                    this.cliente.Cidade = this.options_cidades[0].value;
                  }
                } else {
                  this.cliente.Cidade = this.options_cidades[0].value;
                }
              }

              this.cliente.status = busca.data.success ? "ok" : "not";
            } else {
              this.cliente.status = "error";
            }
          } catch (e) {
            console.log("buscaerr", e);

            this.cliente.status = "not";
          }
          this.$forceUpdate();
          await this.$nextTick();
          this.$refs.Nome.select();
        }
      } else {
        this.cliente.status = "error";
      }
    },
    async buscarCEP() {
      this.loadingCEP = true;
      try {
        const result = await cep.buscarCEP(this.cliente.CEP);
        if (result && result.street) {
          this.cliente.Bairro = result.neighborhood;
          this.cliente.Logradouro = result.street;

          this.$forceUpdate();
          // console.log(this.cliente);
        }
      } catch (err) {
        console.log("ERROR ON BUSCAR CEP");
      }
      this.loadingCEP = false;

      // console.log(result);
    },
  },
};
</script>

<style>
.inputMenor {
  font-size: 1em !important;
  font-weight: 400;
}
</style>
