var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"motoBox",class:{
      correndo: _vm.motoSelecionada && _vm.motoSelecionada.emRota > 0,
      disponivel:
        _vm.motoSelecionada &&
        (_vm.motoSelecionada.emRota == 0 ||
          _vm.motoSelecionada.emRota == _vm.motoSelecionada.concluidas),
      selecionada: _vm.motoSelecionada
    },on:{"click":_vm.openModal}},[(!_vm.motoSelecionada)?_c('div',[_vm._v(" Selecionar / trocar moto ")]):_c('div',[_c('b',[_vm._v(_vm._s(_vm.motoSelecionada.info && _vm.motoSelecionada.info.nome ? _vm.motoSelecionada.info.nome : _vm.motoSelecionada.nome))]),_vm._v(" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.motoSelecionada.info && _vm.motoSelecionada.info.nome ? `(${_vm.motoSelecionada.info.nome})` : "")+" ")])])]),_c('b-modal',{attrs:{"id":"modal-moto-select","title":"Selecione uma moto","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":"","hide-footer":"","hide-header":""}},[_c('h4',[_vm._v("Selecione uma moto")]),_c('div',{staticClass:"motoSelecionada"},[_c('div',[(_vm.motoSelecionada)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.motoSelecionada.nome))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.motoSelecionada.info && _vm.motoSelecionada.info.nome ? `(${_vm.motoSelecionada.info.nome})` : "")+" ")])]):_vm._e()]),_c('div',[(_vm.motoSelecionada)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.selecionarOK}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Selecionar ")]):_vm._e(),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.cancelar}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Cancelar ")])],1)]),_c('div',{staticClass:"listaMotosSelect"},_vm._l((_vm.controles),function(moto){return _c('div',{key:_vm.controles.indexOf(moto),staticClass:"moto",class:{
          correndo: moto.emRota > 0,
          disponivel: moto.emRota == 0 || moto.emRota == moto.concluidas,
          selecionada:
            _vm.motoSelecionada && _vm.motoSelecionada.cod_moto == moto.cod_moto
        },on:{"click":function($event){return _vm.selecionar(moto)}}},[_c('div',[_c('b',[_vm._v(_vm._s(moto.info && moto.info.nome? moto.info.nome : moto.nome))]),_c('small',[_vm._v(" "+_vm._s(moto.info && moto.info.nome ? `(${moto.nome})` : "")+" ")])]),_c('div',{staticClass:"status"},[_c('div',[_c('b',[_vm._v("Em Rota")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(moto.emRota)+" ")]),_c('div',[_c('b',[_vm._v("Concluídas")]),_c('br'),_vm._v(" "+_vm._s(moto.concluidas)+" ")])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }