const axios = require("axios");
// const url = "https://api.ciapizza.s-zap.com/token/";
 
const url = "https://api.s-zap.com/token/"
let token = "";
let configs = {};
// const url = "https://api.z-api.io/instances/3B15A8A0AE5F80CC0E00EA410DAA9FEE/token/D440244390811D1262927960";

const setConfiguration = async (cod_empresa) => {
  if (token === "") {
    const empresa = await require("./EmpresasLib").default.get();
    //console.log("empresa", empresa);
    if (empresa && empresa.data && empresa.data.length > 0) {

      let emp = cod_empresa? empresa.data.find(x=>x.cod_empresa == cod_empresa) : empresa.data[0];
      if (
        emp &&
        emp.configs &&
        emp.configs.whatsapp &&
        emp.configs.whatsapp.token
      ) {
        token = emp.configs.whatsapp.token;
        configs = emp.configs.whatsapp;
        return emp.configs.whatsapp;
      }
    }else{
        return null;
    }
  }else{
    return configs;
  }
};

const enviarTextoSimples = async (phone, message) => {
  await setConfiguration();

  try {
    phone = "55" + phone.replace(/\D/g, "");
    return (await axios.post(`${url}${token}/send-text`, { phone, message }))
      .data;
  } catch (error) {
    console.log("ERROR ON SEND MESSAGE WHPP!");
    return false;
  }
};

const enviarImagem = async (phone, image, caption) => {
  await setConfiguration();

  try {
    phone = "55" + phone.replace(/\D/g, "");

    return (
      await axios.post(`${url}${token}/send-image`, { phone, image, caption })
    ).data;
  } catch (error) {
    console.log("ERROR ON SEND MESSAGE WHPP!");
    return false;
  }
};

const enviarArquivo = async (phone, document, fileName, extension = "pdf") => {
  await setConfiguration();

  try {
    phone = "55" + phone.replace(/\D/g, "");

    return (
      await axios.post(`${url}${token}/send-document/${extension}`, {
        phone,
        document,
        fileName,
      })
    ).data;
  } catch (error) {
    console.log("ERROR ON SEND MESSAGE WHPP!");
    return false;
  }
};

const setToken = async (tokenChange) => {
  token = tokenChange;
};

const getInfo = async () => {
  try {
    return (await axios.get(`${url}${token}/getInfo`)).data;
  } catch (error) {
    console.log("ERROR ON GETINFO MESSAGE WHPP!");
    return false;
  }
};

export default {
  enviarTextoSimples,
  enviarImagem,
  enviarArquivo,
  setToken,
  getInfo,
  setConfiguration,
};
