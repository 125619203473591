<template>
  <div class="mt-4 mb-4">
    <div class="row">
      <h4><i class="fa fa-check-double" /> Conferências</h4>
      <div class="col-8"></div>
      <div class="col">
        <button
          class="btn btn-primary btn-lg btn-block"
          @click="$bvModal.show('modal-conferencia')"
        >
          <i class="fa fa-check-double" /> Informar Conferencia
        </button>
      </div>
    </div>
    <hr />
    <div class="p-4">
      <b-table :fields="fields" :items="lista">
        <template #cell(data)="row">
          {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(situacao)="row">
          <div
            class="text-white"
            :class="{
              'bg-success': row.item.situacao == 1,
              'bg-warning': row.item.situacao == 2,
              'bg-danger': row.item.situacao == 3,
            }"
          >
            {{
              row.item.situacao == 1
                ? "OK"
                : row.item.situacao == 2
                ? "Pendencia"
                : "Erro"
            }}
          </div>
        </template>
        <template #cell(vl_passando)="row">
          <div class="text-success text-center">
            {{ row.item.vl_passando | currency }}
          </div>
        </template>
           <template #cell(vl_faltando)="row">
          <div class="text-danger text-center">
            {{ row.item.vl_faltando | currency }}
          </div>
        </template>
        <template #cell(acao)="row">
          <button
            class="btn btn-danger btn-sm"
            @click="excluir(row.item.cod_conferencia)"
          >
            <i class="fa fa-trash"></i> Excluir
          </button>
        </template>
      </b-table>
      <div v-if="!lista || lista.length <= 0">
        <i class="fa fa-times" /> Nenhuma conferencia informada
      </div>
    </div>

    <b-modal id="modal-conferencia" title="Conferência de Caixa" hide-footer>
      <div class="row">
        <div class="col-12 text-center">
          <b-button-group v-model="form.situacao">
            <b-button @click="selecionaSit(1)" variant="success">
              <i class="fa fa-check" /> Conferido OK
            </b-button>
            <b-button @click="selecionaSit(2)" variant="warning">
              <i class="far fa-check-square" /> Pendencias
            </b-button>
            <b-button @click="selecionaSit(3)" variant="danger">
              <i class="fa fa-ban" /> Erro
            </b-button>
          </b-button-group>

          <div
            class="block text-center w-100 font-weight-bold pt-3 pb-3"
            v-if="form.situacao"
          >
            Selecionado:
            {{
              form.situacao == 1
                ? "OK"
                : form.situacao == 2
                ? "Pendencias"
                : "Erro"
            }}
          </div>
        </div>
        <div class="col-12 pt-2 pb-2 d-flex justify-content-around">
          <div class="p-3 text-white bg-success radius">
            <b>Valor Passando</b>
            <money
              class="form-control"
              v-bind="moneyMask"
              v-model="form.vl_passando"
            />
          </div>
          <div class="p-3 bg-danger text-white radius">
            <b>Valor Faltando</b>
            <money
              class="form-control"
              v-bind="moneyMask"
              v-model="form.vl_faltando"
            />
          </div>
        </div>
        <div class="col-12">
          <label>Observações</label>
          <b-textarea v-model="form.obs" />
        </div>
        <div class="col-12 text-right">
          <hr />
          <button class="btn btn-primary" @click="salvar">
            <i class="fa fa-save"></i> Salvar
          </button>
          <button class="btn btn-secondary">
            <i class="fa fa-ban" /> Cancelar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import CaixaLib from "../../libs/Caixas";
export default {
  props: {
    codCaixa: Number,
    codEmpresa: Number,
    codMoto: Number,
    dataInicial: [String, Date],
    caixaProp: Object,
  },
  mounted() {
    this.resetForm();
    this.data = moment(this.dataInicial).format("YYYY-MM-DD");
    this.cod_caixa = this.codCaixa;
    this.cod_moto = this.codMoto;
    this.cod_empresa = this.codEmpresa;
    this.carregar();
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.cod_caixa = this.codCaixa;
        this.cod_moto = this.codMoto;
        this.cod_empresa = this.codEmpresa;
        this.data = moment(this.dataInicial).format("YYYY-MM-DD");

        this.carregar();
      },
    },
    // codCaixa: function () {
    //   this.cod_caixa = this.codCaixa;
    //   this.carregar();
    // },
    // codMoto: function () {
    //   this.cod_moto = this.codMoto;
    //   this.carregar();
    // },
    // codEmpresa: function () {
    //   this.cod_empresa = this.codEmpresa;
    //   this.carregar();
    // },
    // dataInicial: function () {
    //   this.data = moment(this.dataInicial).format("YYYY-MM-DD");
    //   this.carregar();
    // },
    hasConferido: function () {
      this.$emit("setConferido", this.hasConferido);
    },
  },

  data() {
    return {
      cod_caixa: this.$store.state.caixa_ativo.cod_caixa,
      cod_empresa: this.$store.state.empresa_ativa.cod_empresa,
      cod_moto: 0,
      data: "",
      lista: [],
      hasConferido: false,
      form: {
        situacao: "",
        obs: "",
        vl_passando: 0,
        vl_faltando: 0,
      },
      fields: [
        { key: "usuario", label: "Usuário" },
        { key: "data", label: "Data" },
        { key: "situacao", label: "Situação" },
        { key: "obs", label: "Observações" },
        { key: "vl_passando", label: "Passando" },
        { key: "vl_faltando", label: "Faltando" },
        { key: "acao" },
      ],
    };
  },
  methods: {
    async excluir(item) {
      this.$swal
        .fire({
          title: "Deseja Excluir?",
          text: "Tem certeza que deseja excluir esta conferencia?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não",
        })
        .then(async (c) => {
          if (c.isConfirmed) {
            await CaixaLib.excluirConferencia(item);
            this.carregar();
            this.$emit("carregar");
          }
        });
    },
    selecionaSit(sit) {
      this.form.situacao = sit;
    },
    resetForm() {
      this.form.situacao = null;
      this.form.obs = "";
      this.form.vl_passando = 0;
      this.form.vl_faltando = 0;
      this.hasConferido = false;
    },
    async carregar() {
      // console.log(
      //   "!!!!!!!!!!!!!!!!!!  -------> buscando...",
      //   moment(this.data).format("YYYY-MM-DD"),
      //   this.cod_empresa,
      //   this.cod_caixa,
      //   this.cod_moto
      // );
      this.lista = [];
      await this.$nextTick();
      this.lista = (
        await CaixaLib.getConferencia(
          moment(this.data).format("YYYY-MM-DD"),
          this.cod_empresa,
          this.cod_caixa,
          this.cod_moto
        )
      ).data;

      this.hasConferido = this.lista.find((x) => x.situacao == 1) != null;

      // console.log("lista", this.lista);
    },
    async addOK() {
      this.form.situacao = 1;
      this.salvar();
    },
    async salvar() {
      if (!this.form.situacao) {
        this.$swal("Atenção", "Informe uma situação válida!", "error");
      } else {
        let obj = {
          ...this.form,
          vl_passando: this.formatValorReal(this.form.vl_passando),
          vl_faltando: this.formatValorReal(this.form.vl_faltando),
          dt_caixa: moment(this.data).format("YYYY-MM-DD"),
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
          cod_empresa: this.cod_empresa,
          cod_caixa: this.cod_caixa,
          cod_moto: this.cod_moto,
          cod_usuario: this.$store.state.auth.user.cod_usuario,
        };
        // console.log("ok", obj);
        let res = await CaixaLib.storeConferencia(obj);
        if (res && res.success) {
          this.$swal("Concluído", "", "success").then(() => {
            this.resetForm();
            this.$bvModal.hide("modal-conferencia");
            this.carregar();
          });
        } else {
          this.$swal(
            "Atenção",
            "Erro ao salvar. Entre com contato com o desenvolvedor",
            "error"
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radius {
  border-radius: 15px !important;
}
</style>
