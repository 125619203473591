<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="Nome">
          <b-input v-model="obj.nome" />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6">
        <b-form-group label="Tipo">
          <b-select
            v-model="obj.tipo"
            :options="['Conta', 'Carteira', 'Poupança', 'Cofre']"
          />
        </b-form-group>
      </div>
      <div class="col-12" v-if="['Conta', 'Poupança'].includes(obj.tipo)">
        <div class="row">
          <div class="col-12">
            <b-form-group label="Banco">
              <b-input v-model="obj.banco" />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <b-form-group label="Agencia">
              <b-input v-model="obj.agencia" />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <b-form-group label="Conta">
              <b-input v-model="obj.conta" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-right">
      <hr />
      <b-btn variant="success" class="mr-1" @click="salvar"
        ><i class="fa fa-save" /> Salvar</b-btn
      >
      <b-btn variant="secondary" @click="$emit('close')"
        ><i class="fa fa-ban" /> Cancelar</b-btn
      >
    </div>
  </div>
</template>

<script>
import validador from "../../../utils/validador";
import CarteiraLib from "../../../libs/Carteiras";
export default {
  components: {},
  props: {
    editID: {
      type: Number,
      default: null
    }
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: {
        nome: "",
        tipo: "",
        banco: "",
        agencia: "",
        conta: "",
        id: null
      }
    };
  },
  watch: {
    editID: function() {
      this.obj = {
        nome: "",
        tipo: "",
        banco: "",
        agencia: "",
        conta: "",
        id: null
      };
      this.carregar();
    }
  },
  computed: {},
  methods: {
    async salvar() {
      let errs = [];
      if (validador.string.isNullOrEmpty(this.obj.nome)) {
        errs.push("Informe o Nome");
      }
      if (validador.string.isNullOrEmpty(this.obj.tipo)) {
        errs.push("Selecione o tipo");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">${errs.join("<br/>")}</div>`,
          icon: "error"
        });
      } else {
        let result = await CarteiraLib.storeCarteiras(this.obj);
        this.$swal(
          result.success ? "Sucesso" : "Erro",
          result.success
            ? "Carteira/Conta cadastrada com sucesso!"
            : "Erro ao cadastrar",
          result.success ? "success" : "error"
        );

        if (result.success) {
          this.$emit("close");
        }
      }
    },
    async carregar() {
      console.log('id',this.$props.editID)
      if (this.$props.editID) {
        let r = await CarteiraLib.getCareteiras({ id: this.editID });
        if (r.success && r.data.length >= 1) {
          let {nome, tipo, banco, agencia, conta, id } = r.data[0];
          this.obj = {nome, tipo, banco, agencia, conta, id };
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>