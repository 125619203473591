<template>
  <div class="containerSal">
    <div v-if="layout" class="salao">
      <div
        v-for="row in layout"
        :style="`width:${Math.floor(Math.abs(100 / layout.length))}%`"
        class="fila"
        :key="`row_${row.id}_${Math.random()}`"
      >
        <div
          class="mesa d-flex flex-column justify-content-center"
          :class="`${
            (somenteDisponiveis && it && it.obj && it.obj.id
              ? ' indisponivel '
              : '') +
            (mesaSelected(it.cod_mesa) ? ' selected ' : '') +
            (selectable ? ' selectable ' : '') +
            (VerificaMesaReunida(it) ? ' mesaJaUnida  ' : '') +
            (it.uniao
              ? 'mesaUnida '
              : VerificaMesaReunida(it)
              ? ' mesaReunida '
              : '')
          }`"
          v-for="it in row.items"
          :key="`mesa_${row.index}_${it.cod_mesa}`"
          @click="selectMesa(it)"
        >
          {{ it.title }}
          <div class="reserva" v-if="verificaReserva(it.cod_mesa)">
            <b-icon-calendar-check />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getReservas } from "../../libs/MesasReservaLib";

export default {
  components: {},
  props: {
    somenteDisponiveis: {
      type: Boolean,
      default: true,
    },
    layout: Array,
    selectable: {
      type: Boolean,
      default: false,
    },
    mesaList: Array,
    showReservas: {
      type: Boolean,
      default: true,
    },
    data: String,
  },
  mounted() {
    // console.log("mesaList", this.mesaList);
    if (this.showReservas) {
      this.carregaReservas();
    }
    7;
  },
  data() {
    return {
      reservas: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    VerificaMesaReunida(item) {
      let has =
        this.layout.filter((r) =>
          r.items.some((x) => x.uniao && x.uniao.cod_mesa == item.cod_mesa)
        ).length > 0;

      return has;
    },
    async carregaReservas() {
      this.reservas = [];
      let res = await getReservas({
        data: this.data || moment().format("YYYY-MM-DD"),
      });
      if (res && res.success) {
        this.reservas = res.data;
      }
      console.log("reservas", res);
    },
    verificaReserva(cod_mesa) {
      if (this.reservas) {
        return this.reservas.find(
          (x) => x.cod_mesa == cod_mesa && !x.dt_cancelado && !x.dt_entrada
        );
      }
    },
    mesaSelected(cod_mesa) {
      return (
        cod_mesa &&
        this.mesaList &&
        this.mesaList.some((x) => x.cod_mesa == cod_mesa && x.selected)
      );
    },
    async selectMesa(mesa) {
      let hasReesvas = this.verificaReserva(mesa.cod_mesa);
      if (hasReesvas) {
        let res = await this.$swal({
          title:
            "Esta mesa já possui reserva ás " +
            hasReesvas.hora,
          text: "Deseja criar outra reserva mesmo assim?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Reservar!",
          cancelButtonText: "Não",
        }).then((x) => x.isConfirmed);
        if (!res) {
          return;
        }
      }
      if (this.selectable) {
        this.$emit("select", mesa);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.containerSal {
  overflow: hidden;
  overflow-x: auto;
}
.salao {
  display: flex;
  flex-direction: row;
  min-width: 1024px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 200px;
  border: 1px solid #ccc;
  padding: 3px;
  .fila {
    padding: 3px;
    height: auto;
    margin: 3px;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    align-items: center;
    .mesa {
      font-size: 12px;
      font-weight: bold;
      background-color: #f3f3f3;
      padding: 10px;
      border-radius: 10px;

      width: 90%;
      min-height: 35px;
      text-align: center;
      margin-top: 5px;
      position: relative;
    }
    .indisponivel {
      opacity: 0.5;
      background-color: lightcoral;
    }
    .selectable {
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }
    .selected {
      background-color: #096621;
      color: white;
    }
    .mesaUnida {
      border-end-end-radius: 0px;
      border-end-start-radius: 0px;
      border-bottom-width: 0px;
    }
    .mesaJaUnida {
      margin-top: 0px;
      border-top-width: 0px;
      border-start-end-radius: 0px;
      border-start-start-radius: 0px;
    }
    .mesaReunida {
      margin-top: 0px;
      border-top-width: 0px;
      border-start-end-radius: 0px;
      border-start-start-radius: 0px;
    }
    .reserva {
      position: absolute;
      font-size: 10px;
      top: -5px;
      left: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(172, 115, 0);
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
</style>
