import axios from '@/utils/axios.js';
import formater from '../utils/formatter';
import ContasPagarLib from './ContasPagarLib';

const getMovimento = async (filter) => {
    let ret = null;
    try {
        ret = (await axios.get('/bancario/movimento', { params: filter })).data;
    } catch (err) {
        console.log('error on getmovimento banco', err);
    }

    return ret;
}

const storeMovimento = async (dt) => {
    let data = Object.assign({}, { ...dt });
    delete data.historico;
    delete data._rowVariant;
    delete data.created_at;
    delete data.deleted_at;
    delete data.updated_at;
    delete data.User;
    let ret = null;
    const moment = require('moment')
    try {
        data.data = moment(data.data).format("YYYY-MM-DD HH:mm:ss");
        if (data.consolidado === "S" && data.dt_consolidado) {
            data.dt_consolidado = moment(data.dt_consolidado).format("YYYYY-MM-DD " + moment().format("HH:mm:ss"))
        } else {
            data.dt_consolidado = null;
        }

        data.valor = formater.formatValorReal(data.valor);

        if (data.tipo === -1) {
            data.valor = data.valor * -1;
        }


        ret = (await axios.post('/bancario/movimento', data)).data;
    } catch (err) {
        console.log('error on store movimento banco', err);
    }

    return ret;
}

const consolidarMovimento = async (dt) => {
    const moment = require('moment')
    let data = Object.assign({}, { ...dt });
    delete data.historico;
    delete data._rowVariant;
    delete data.created_at;
    delete data.deleted_at;
    delete data.updated_at;
    delete data.User;

    data.data = moment(data.data).format("YYYY-MM-DD HH:mm:ss");
    if (data.dt_consolidado) {
        data.dt_consolidado = moment(data.dt_consolidado).format("YYYYY-MM-DD " + moment().format("HH:mm:ss"))
    }
    let ret = null;
    try {
        ret = (await axios.post('/bancario/movimento/consolidar', data)).data;
    } catch (err) {
        console.log('error on consolidar banco', err);
    }

    return ret;
}

const delMovimento = async (id) => {
    let ret = null;
    try {
        await ContasPagarLib.excluirBaixaContasPagar({ movimento_id: id });
        ret = (await axios.post('/bancario/movimento/del', { id })).data;
    } catch (err) {
        console.log('error on getmovimento banco', err);
    }

    return ret;
}

const transferenciaMovimento = async (dt) => {
    let data = Object.assign({}, { ...dt });
    let ret = null;
    try {        
        data.valor = formater.formatValorReal(data.valor);

        ret = (await axios.post('/bancario/movimento/transferencia', data)).data;
    } catch (err) {
        console.log("ERRO AO TRASNFERENCIA", err);
    }
    return ret;
}


const getResumoGeral = async (filters) => {
    let ret = null;
    try {
        ret = (await axios.post('/bancario/relatorios/getResumo', filters)).data;
    } catch (err) {
        console.log('error on getResumo banco', err);
    }

    return ret;
}


const getExtrato = async (filters) => {
    let ret = null;
    try {
        ret = (await axios.post('/bancario/relatorios/getExtrato', filters)).data;
    } catch (err) {
        console.log('error on get extrato banco', err);
    }

    return ret;
}

// const getRelatorio = async (filters) => {
//     let ret = null;
//     try {
//         ret = (await axios.post('/bancario/relatorios/getRelatorio', filters)).data;
//     } catch (err) {
//         console.log('error on get extrato banco', err);
//     }

//     return ret;
// }


export default {
    getMovimento,
    storeMovimento,
    consolidarMovimento,
    delMovimento,
    transferenciaMovimento,
    getResumoGeral,
    getExtrato
}