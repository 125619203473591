<template>
  <div class="container-fluid p-0">
    <div class="conte">
      <header>
        <div class="row m-0">
          <div class="col-6 pr-0 pl-0">
            <empresa-combo
              :selecionar="changeEmpresa"
              :noStyle="false"
              :comTodos="true"
              :disabled="user.nivel!==0"
            />
          </div>
           
          <div class="col-6 pl-0 bg-menu">
            <div class="text-center p-2 pb-3">
              <i class="fa fa-user" /> Operador {{ user.nome }}
            </div>
            <v-date-picker
              v-model="data"
              mode="date"
              :rows="1"
              spellcheck="false"
              is-expanded
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control text-center"
                  :disabled="loading"
                  :value="inputValue"
                  v-on="inputEvents"
                >
              </template>
            </v-date-picker>
          </div>
        </div>
      </header>
      <aside>
        <entregas-menu />
      </aside>
      <main>
        <div class="p-2">
          <transition name="fadeTransition">
            <router-view :empresa="empresa" :data="data"></router-view>
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// import EntregasLib from "../../libs/Entregas.js";
import EntregasMenu from "./EntregasMenu.vue";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import moment from 'moment';
export default {
  components: {
    EntregasMenu,
    EmpresaCombo
  },
  mounted() {
    // this.carregar();
    // console.log(this.user)
  },
  data() {
    return {
      motos: [],
      empresa: this.$store.state.empresa_ativa,
      data: parseInt(moment().format("HH"))<8? moment().add(-1,'d').toDate(): new Date(),
      loading: false,
      user: this.$store.state.auth.user
    };
  },
  methods: {
    changeEmpresa(empresa) {
      this.empresa = empresa;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/entregas.scss";
</style>