var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(" "+_vm._s(_vm.obj.Nome)+" ")])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row my-4 dashCards"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"cd"},[_vm._v(" Telefone "),_c('br'),_c('h4',[_vm._v(_vm._s(_vm.obj?.Telefone))])])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"cd"},[_vm._v(" Nascimento "),_c('br'),_c('h4',[_vm._v(_vm._s(_vm._f("moment")(_vm.obj?.dt_nascimento,"DD/MM/YYYY")))])])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"cd"},[_vm._v(" Saldo "),_c('br'),_c('h4',[_vm._v(_vm._s(_vm._f("currency")(_vm.obj?.saldo)))])])])])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('h4',[_vm._v("Últimas Aulas")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.obj.aulas,"fields":[
              { key: 'data', label: 'Data' },
              { key: 'aula', label: 'Aula' },
              { key: 'professor', label: 'Professor' },
              { key: 'situacao', label: 'Situação' },
            ]}})],1)])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('h4',[_vm._v("Último Acessos")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.obj.aulas,"fields":[
              { key: 'data', label: 'Data' },
              { key: 'tipo', label: 'Tipo' },
            ]}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }