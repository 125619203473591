<template>
  <div>
    <barra-crud
     :nomeCustomizado="'Carteiras / Contas'"
      :botaoNovo="true"
      :onNovo="
        () => {
          this.openCarteira(null);
        }
      "
    />

    <b-table
      :items="lista"
      :fields="[
        { key: 'id', label: 'Código' },
        { key: 'nome', label: 'Nome' },
        { key: 'tipo', label: 'Tipo' },
        { key: 'ordem', label: 'Ordem' },
        { key: 'bot', label: '' }
      ]"
      hover
    >
      <template #cell(bot)="row">
        <b-btn variant="warning" class="mr-1" @click="openCarteira(row.item.id)"
          ><i class="fa fa-edit" /> Editar</b-btn
        >
        <b-btn variant="danger" @click="excluir(row.item.id)"
          ><i class="fa fa-trash" /> Excluir</b-btn
        >
      </template>
      <template #cell(ordem)="row">
        <b-input type="number" v-model="row.item.ordem" @change="salvarOrdem(row.item)"></b-input>
      </template>
    </b-table>

    <b-modal
      title="Adicionar/Editar Carteira/Conta"
      no-enforce-focus
      hide-footer
      id="modal-carteira"
    >
      <carteiras-form @close="fecharCarteira" :editID="editID" />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../../components/common/BarraCrud.vue";
import CarteirasForm from "../../../components/Financeiro/Carteiras/CarteirasForm.vue";
import CarteiraLib from "../../../libs/Carteiras.js";
export default {
  components: {
    BarraCrud,
    CarteirasForm
  },
  props: {},
  mounted() {
    this.carregar();
  },
  data() {
    return {
      lista: [],
      loading: false,
      editID: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    openCarteira(id) {
      this.editID = id;
      console.log("edit id-->", id);
      this.$bvModal.show("modal-carteira");
    },
    fecharCarteira() {
      this.$bvModal.hide("modal-carteira");
      this.editObj = null;
      this.carregar();
    },
    excluir(id) {
      this.$swal({
        title: "Confirmação",
        text: "Tem certeza que deseja excluir esta carteira?",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim. Excluir!"
      }).then(async resp => {
        if (resp.isConfirmed) {
          let res = await CarteiraLib.delCarteiras(id);
          this.$swal(
            res.success ? "Sucesso" : "Erro",
            res.success
              ? "Carteira/Conta excluida com sucesso!"
              : "Erro ao excluir",
            res.success ? "success" : "error"
          );

          if(res.success){
              this.carregar()
          }
        }
      });
    },
    async salvarOrdem(item) {
      console.log("item", item);
      let res = await CarteiraLib.storeCarteiras(item);
      console.log(res);
      this.carregar();
    },
    async carregar() {
      this.loading = true;
      this.lista = (await CarteiraLib.getCareteiras(null)).data;
      console.log("lista", this.lista);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>