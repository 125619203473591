import axios from "@/utils/axios";
// import moment from 'moment';

const getTipoHistorico = async (filters) => {
    try {
        return (await axios.get('bancario/tipo_historico', { params: filters })).data;
    } catch (e) {
        console.log("ERRO AO BUCSAR tipo_historico", e);
        return null;
    }
}
const storeTipoHistorico = async (data) => {
    try {
        return (await axios.post('bancario/tipo_historico', data)).data;
    } catch (err) {
        console.log("ERRO AO STORE tipo_historico", err);
    }
}

const delTipoHistorico = async (id) => {
    try {
        return (await axios.post('bancario/tipo_historico/delete', { id })).data;
    } catch (err) {
        console.log("ERRO AO STORE tipo_historico", err);
    }
}


export default {
    getTipoHistorico,
    storeTipoHistorico,
    delTipoHistorico
}