<template>
  <div>
    <b-form @submit.stop.prevent="salvarEndereco()">
      <b-form-group>
        <div class="row">
          <div class="col-8 col-sm-6">
            <label> CEP</label>
            <b-input
              id="inputCep"
              ref="cep"
              type="text"
              placeholder="CEP"
              v-model="$v.enderecoEdit.cep.$model"
              :state="validateState('cep')"
              v-mask="'#####-###'"
              @focusout.native="verificaCep()"
            />

            <b-form-invalid-feedback
              >Informe um CEP válido
            </b-form-invalid-feedback>
          </div>
          <div class="col-4 d-flex align-self-center mt-4" v-if="loading">
            <strong>Buscando...</strong>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-8">
            <label>Cidade</label>
            <b-input
              ref="cidade"
              type="text"
              placeholder="Cidade"
              v-model="$v.enderecoEdit.cidade.$model"
              :state="validateState('cidade')"
            />
            <b-form-invalid-feedback
              >Informe uma cidade válida
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-4">
            <label>UF</label>
            <b-form-select
              :options="uf"
              v-model="$v.enderecoEdit.uf.$model"
              :state="validateState('uf')"
            ></b-form-select>
            <b-form-invalid-feedback
              >Informe uma UF válida
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-7">
            <label>Bairro</label>
            <b-input
              ref="bairro"
              type="text"
              placeholder="Bairro"
              v-model="$v.enderecoEdit.bairro.$model"
              :state="validateState('bairro')"
            />
            <b-form-invalid-feedback
              >Informe um bairro válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-8">
            <label>Logradouro</label>
            <b-input
              ref="logradouro"
              type="text"
              placeholder="Logradouro"
              v-model="$v.enderecoEdit.logradouro.$model"
              :state="validateState('logradouro')"
            />
            <b-form-invalid-feedback
              >Informe um logradouro válido
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-4">
            <label>Numero</label>
            <b-input
              ref="numero"
              type="text"
              placeholder="Numero"
              v-model="$v.enderecoEdit.numero.$model"
              :state="validateState('numero')"
            />
            <b-form-invalid-feedback
              >Informe um numero válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row mt-3">
          <div class="col-12">
            <label>Complemento</label>
            <b-input
              ref="complemento"
              type="text"
              placeholder="Complemento"
              v-model="enderecoEdit.complemento"
            />
            <b-form-invalid-feedback
              >Informe um complemento válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <div class="bottom-actions" style="position: unset">
        <hr class="mb-2" />
        <b-btn variant="primary" class="mr-3" type="submit" :disabled="loading"
          ><i class="fas fa-save" v-if="!loading"></i> <b-spinner v-if="loading" small></b-spinner> Salvar</b-btn
        >
        <b-btn variant="danger" @click="fecharModal()"
          ><i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
// import EnderecoLib from "@/libs/EnderecosLib";
// import BotoesSalvarCancelar from '@/components/Custom/BotoesSalvarCancelar.vue'
import { buscarCEP } from "../../utils/cep";

export default {
  mixins: [validationMixin],
  components: {
    // BotoesSalvarCancelar
  },
  props: {
    enderecoEdit: { Type: Object, required: true },
  },
  data() {
    return {
      hasErrors: [],
      enderecoCep: {},
      loading: false,
      uf: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
    };
  },
  validations: {
    enderecoEdit: {
      cep: {
        required,
        minLength: minLength(9),
        async validaCep(){
          return await this.verificaCep()
        },
      },
      cidade: {
        required,
      },
      uf: {
        required,
      },
      bairro: {
        required,
      },
      logradouro: {
        required,
      },
      numero: {
        required,
      },
    },
  },
  // mounted() {
  //   this.clearErrors();
  // },
  methods: {
    fecharModal() {
      this.$bvModal.hide("modal-endereco");
    },
    async salvarEndereco() {
      this.$v.enderecoEdit.$touch();
      if (this.$v.enderecoEdit.$anyError) {
        return;
      }
      // console.log("teste");
      this.$emit("salvar");
    },
    validateState(name) {
      // console.log(name);
      const { $dirty, $error } = this.$v.enderecoEdit[name];
      return $dirty ? !$error : null;
    },
    async verificaCep() {
      let validado;
      this.loading = true;
      // console.log(this.enderecoEdit.cep);
      if (this.enderecoEdit.cep) {
        if (this.enderecoEdit.cep.length === 9) {
          await buscarCEP(this.enderecoEdit.cep)
            .then((data) => {
              // console.log(data);
              this.enderecoCep = data;
              validado = true;
              this.preencheEndereco()
            })
            .catch((err) => {
              console.log('Erro ao buscar cep',err);
              validado = false;
            });
        }
      }
      this.loading = false;
      return validado;
    },
    preencheEndereco() {
      this.enderecoEdit.cidade = this.enderecoCep.city
      this.enderecoEdit.uf = this.enderecoCep.state
      this.enderecoEdit.bairro = this.enderecoCep.neighborhood
      this.enderecoEdit.rua = this.enderecoCep.street
    },
    // async buscarCep() {
    //   console.log("buscar cep", this.enderecoEdit.cep.length);
    //   if (this.enderecoEdit.cep.length >= 8) {
    //     await buscarCEP(this.enderecoEdit.cep)
    //       .then((endereco) => {
    //         this.enderecoCep = endereco;
    //       })
    //       .catch((err) => {
    //         console.log("Erro ao buscar cep ", err);
    //       });
    //     console.log(this.enderecoCep);
    //   }
    // },
    // inputFocus() {
    //   for (let i = 0; i < Object.values(this.$refs).length; i++) {
    //     let input = Object.values(this.$refs);
    //     if (input[i].type == "text" && input[i].value == "") {
    //       input[i].focus();

    //       break;
    //     }
    //   }
    // },
    // async buscaCep() {

    //   try {
    //     if (this.enderecoEdit.cep.length >= 8) {
    //       this.loading = true;

    //       this.enderecoCep = await EnderecoLib.getByCep(this.enderecoEdit.cep);
    //       if (!this.enderecoCep.erro) {
    //         // this.limpaEndereco();

    //         this.enderecoEdit.cidade = this.enderecoCep.localidade;
    //         this.enderecoEdit.uf = this.enderecoCep.uf;
    //         this.enderecoEdit.logradouro = this.enderecoCep.logradouro;
    //         this.enderecoEdit.bairro = this.enderecoCep.bairro;
    //         this.enderecoEdit.complemento = this.enderecoCep.complemento;
    //         this.addOrUpdateError("cep", true);
    //         await this.$forceUpdate();
    //         this.inputFocus();
    //       } else {
    //         this.addOrUpdateError("cep", false);
    //       }
    //     }
    //   } catch (error) {
    //     console.warn(error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    // validateState(campo) {
    //   let hasError = this.hasErrors.find((x) => x.campo == campo);
    //   if (!hasError) return null;
    //   else return hasError.state;
    // },
    // addOrUpdateError(campo, state) {
    //   let hasError = this.hasErrors.find((x) => x.campo == campo);
    //   if (!hasError) {
    //     this.hasErrors.push({ campo, state });
    //   } else {
    //     hasError.state = state;
    //   }
    // },
    // clearErrors() {
    //   this.hasErrors.map((error) => {
    //     error.state = null;
    //   });
    // },
    // async validar() {
    //   this.clearErrors();
    //   await this.$nextTick();
    //   this.inputFocus();
    //   this.addOrUpdateError(
    //     "cep",
    //     !Validadores.string.IsNullOrEmpty(this.enderecoEdit.cep)
    //   );
    //   this.addOrUpdateError(
    //     "cidade",
    //     !Validadores.string.IsNullOrEmpty(this.enderecoEdit.cidade)
    //   );
    //   this.addOrUpdateError("uf", this.enderecoEdit.uf != '');
    //   this.addOrUpdateError(
    //     "logradouro",
    //     !Validadores.string.IsNullOrEmpty(this.enderecoEdit.logradouro)
    //   );
    //   this.addOrUpdateError(
    //     "numero",
    //     !Validadores.string.IsNullOrEmpty(this.enderecoEdit.numero)
    //   );
    //   this.addOrUpdateError(
    //     "bairro",
    //     !Validadores.string.IsNullOrEmpty(this.enderecoEdit.bairro)
    //   );
    //   // this.addOrUpdateError(
    //   //   "complemento",
    //   //   !Validadores.string.IsNullOrEmpty(this.enderecoEdit.complemento)
    //   // );

    //   return this.hasErrors.filter((x) => x.state == true).length >= 6;
    // },
  },
};
</script>

<style scoped></style>
