<template>
  <div>

    <div class="row" v-if="!hideFiltros">
      <div class="col-5">
        <b-form-group label="Periodo">
          <div class="row">
            <div class="col-6 pr-1">
              <b-input type="date" v-model="filtros.dt_i" />
            </div>
            <div class="col-6 pl-1">
              <b-input type="date" v-model="filtros.dt_f" />
            </div>
          </div>
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group label="Situação">
          <b-select
            v-model="filtros.situacao"
            :options="[
              'Todos',
              'Ativos',
              'Concluídos',
              'Pago e não vinculado',
              'Expirados',
            ]"
          />
        </b-form-group>
      </div>

      <div class="col-12" v-if="!loading && lista.length > 0">
        <b-form-group label="Filtrar Resultados">
          <b-input
            v-model="filtro"
            placeholder="localize por ID, Cliente ou Valor..."
          />
        </b-form-group>
      </div>

      <div class="col-12" v-if="selectable">
        <small> Clique no pix para selecionar </small>
      </div>
    </div>
    <hr />
    <div id="printDiv">
      <div v-if="loading" class="text-center p-5">
        <b-spinner /><br />
        aguarde...
      </div>
      <div class="row" v-if="!loading">
        <div class="col-12">
          <b-table
            striped
            hover
            :style="`font-size:11px`"
            :items="lista"
            :filter="filtro"
            :selectable="selectable"
            select-mode="single"
            @row-selected="onRowSelected"
            small
            :fields="[
              { key: 'id', label: 'ID' },
              { key: 'data', label: 'Data/Hora' },
              //   {
              //     key: 'clinica.sigla',
              //     label: 'Clínica',
              //     class:
              //       filtros.clinica_id && filtros.clinica_id > 0 ? 'd-none' : '',
              //   },
              { key: 'status', label: 'Status' },
              { key: 'venda', label: 'Venda' },
              { key: 'devedor_nome', label: 'Cliente' },
              { key: 'valor', label: 'Valor' },
              { key: 'pixEndToEndCode', label: 'Cod. Trans' },
            ]"
          >
            <template #cell(id)="row">
              {{ row.item.id }}
              <div style="font-size: 8px">{{ row.item.txId }}</div>
            </template>
            <template #cell(data)="row">
              {{ row.item.createdAt | moment("DD/MM/YYYY HH:mm") }}
            </template>
            <template #cell(valor)="row">
              {{ row.item.valor | currency }}
            </template>
            <template #cell(venda)="row">
              <div>
                <span v-if="row.item.venda && row.item.venda.senha">
                  Pedido nº {{ row.item.venda.senha }}
                </span>
                <span v-if="row.item.venda && row.item.venda.id">
                  Conta nº {{ row.item.venda.id }} {{ row.item.venda.mesa }}
                </span>
                <span v-if="!row.item.venda"> Não vinculado </span>
              </div>
            </template>
          </b-table>
        </div>
        <div class="col-12 mt-1" v-if="!selectable">
          <hr />
          <div class="row text-center">
            <div class="col-3 col-sm-3 pr-1">
              <b-card title="Todos">
                <h3>
                  {{
                    lista.reduce((ret, vl) => {
                      ret += vl.valor;
                      return ret;
                    }, 0) | currency
                  }}
                </h3>
              </b-card>
            </div>
            <div class="col-3 col-sm-3 pl-1 pr-1">
              <b-card title="Concluídos">
                <h3>
                  {{
                    lista.reduce((ret, vl) => {
                      ret += vl.status == "PAGO" && vl.venda ? vl.valor : 0;
                      return ret;
                    }, 0) | currency
                  }}
                </h3>
              </b-card>
            </div>
            <div class="col-3 col-sm-3 pl-1 pr-1">
              <b-card title="Não vinculado">
                <h3>
                  {{
                    lista.reduce((ret, vl) => {
                      ret += vl.status == "PAGO" && !vl.venda ? vl.valor : 0;
                      return ret;
                    }, 0) | currency
                  }}
                </h3>
              </b-card>
            </div>
            <div class="col-3 col-sm-3 pl-1">
              <b-card title="Expirados">
                <h3>
                  {{
                    lista.reduce((ret, vl) => {
                      ret += vl.status == "EXPIRADO" ? vl.valor : 0;
                      return ret;
                    }, 0) | currency
                  }}
                </h3>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PixLib from "../../libs/PixLib";

export default {
  name: "RelatorioPixForm",
  components: {},
  props: {
    clinica: Number,
    selectable: {
      type: Boolean,
      default: false,
    },
    hideFiltros: {
      type: Boolean,
      default: false,
    },
    listaPix: Object,
  },
  mounted() {
    if (this.clinica && this.clinica > 0) {
      this.filtros.clinica_id = this.clinica;
    }
    this.hideFiltros = false;

    if (this.$props.listaPix && this.$props.listaPix.length > 0) {
      this.hideFiltros = true;
      this.lista = this.$props.listaPix;
    }

    this.carregar();
  },
  data() {
    return {
      filtro: "",
      filtros: {
        dt_i: moment().format("YYYY-MM-DD"),
        dt_f: moment().format("YYYY-MM-DD"),
        situacao: "Todos",
        dt_i_desc: moment().format("DD/MM/YYYY"),
        dt_f_desc: moment().format("DD/MM/YYYY"),
      },
      loading: false,
      lista: [],
    };
  },
  watch: {
    "$props.listaPix": {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    filtros: {
      deep: true,
      handler() {
        this.filtros.dt_i_desc = moment(this.filtros.dt_i).format("DD/MM/YYYY");
        this.filtros.dt_f_desc = moment(this.filtros.dt_f).format("DD/MM/YYYY");
        this.carregar();
      },
    },
  },
  computed: {},
  methods: {
    onRowSelected(row) {
      if (
        row &&
        row.length > 0 &&
        ((row[0].status != "ATIVA" && row[0].status != "PAGO") ||
          (row[0].status == "PAGO" && row[0].venda != null))
      ) {
        this.$swal({
          title: "Indisponível!",
          icon: "error",
          text: "O PIX que vocês está tentando selecionar não está disponível para ser utilizado!",
        });
      } else {
        this.$emit("selected", row[0]);
      }
    },
    async carregar() {
      this.filtro = "";
      this.loading = true;
      this.lista = [];

      try {
        let filters = {
          processAt_i: moment(this.filtros.dt_i).format("YYYY-MM-DD"),
          processAt_f: moment(this.filtros.dt_f).format("YYYY-MM-DD"),
        };

        console.log("lista prop", this.$props.listaPix);
        if (this.$props.listaPix && this.$props.listaPix.length > 0) {
          this.lista = this.listaPix;
          console.log("lista1", this.lista);
        } else {
          let result = await PixLib.listagemPix(filters);
          if (result && result.success) {
            this.lista = result.data;
            console.log("lista", this.lista);
          }
        }
        console.log("lista final", this.lista);
      } catch (e) {
        console.log("ERROR get pix list", e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>