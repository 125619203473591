<template>
  <div>
    <div
      :class="{
        'text-left bg-menu p-3 pt-1 pb-1 text-white': !$props.noStyle
      }"
    >
      <label class="" v-if="!$props.noStyle">
        <i class="fa fa-cash-register mr-3"></i>Caixa
      </label>
      <b-select :options="caixas" v-model="cod_caixa" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caixa: Number,
    CaixasLista: Array,
    ComTodos: {
      type: Boolean,
      default: true
    },
    noStyle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      caixas: [],
      cod_caixa: -1
    };
  },
  async mounted() {
    await this.carregar();

    if (this.$props.caixa) {
      this.cod_caixa = this.$props.caixa;
    }
  },
  watch: {
    cod_caixa: function() {
      this.$emit(
        "ch",
        this.caixas.find(x => x.value == this.cod_caixa)
      );
    },
    caixa: function () {
      this.cod_caixa = this.caixa;
    },
    CaixasLista: {
      deep: true,
      handler() {
        this.carregar();
      }
    }
  },
  methods: {
    async carregar() {
      this.caixas = [];
      if (this.ComTodos) {
        this.caixas.push({
          value: -1,
          text: "TODOS"
        });
      }

      this.caixas.push(
        ...(await Object.assign(
          [],
          this.$props.CaixasLista
            ? this.$props.CaixasLista
            : this.$store.state.caixas
        ).map(it => {
          return { value: it.cod_caixa, text: it.nome };
        }))
      );
    }
  }
};
</script>

<style>
</style>