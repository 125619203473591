<template>
  <div>
    
    <b-input-group id="inpt" v-if="(Model.qtd>=0 && !showRemover) || Model.qtd > 0 ">
      
      <b-form-spinbutton
        id="sb-small"
        v-model="Model.qtd"
        size="sm"
        placeholder="--"
        min="-1"
        max="100"
        class="mb-2"
      ></b-form-spinbutton>
    </b-input-group>
    <div v-if="Model.qtd <= 0 && showRemover" id="dvRemov">
      Deseja remover?
      <div>
        <button @click="remover"><i class="fa fa-trash"></i> Sim</button>
        <button @click="move(1)"><i class="fa fa-ban"></i> Não</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Model: Object,
    remover: Function,
    showRemover:{
      type:Boolean,
      default: true
    }
  },
  methods: {
    move(vl) {
      this.Model.qtd += vl;
    }
  }
};
</script>

<style  scoped>
#inpt {
  /* width: 80px !important; */
}
input {
  font-size: 10px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: white !important;
}
#dvRemov {
  font-size: 12px;
  text-align: center;
}
#dvRemov button {
  font-size: 12px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 4px;
  width: 100%;
}
button {
  background-color: white;
  border: none;
  color: red;
  transition: all 0.2s ease;
  font-size: 13px;
}
button:hover {
  background-color: #b01717;
  color: white;
}
button:focus,
button:active {
  outline: none !important;
}
#btRight {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
#btLeft {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
</style>